import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import Translate from 'ui/atoms/translate';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import GeneralInvestmentDetails from './general-information';
import BoxedContent from 'ui/molecules/boxed-content';
import LoadingRing from 'ui/atoms/loading-ring';
import ActionButtons from 'ui/molecules/action-buttons';
import useApiCall from 'hooks/use-api-call';
import { AdminApi } from 'api/apis';
import useTransactionLink from 'hooks/use-transaction-link';
import {
  AdminDocument,
  AdminInvestmentDetails,
  AdminInvestor,
  AdminTokenTransfer,
  InvestmentAcquisitionTypeRecipientEnum,
  LegalPerson,
} from 'api';
import DocumentTable from 'src/libraries/document-table';
import StudioInvestmentPaymentDetails from './payment-details';
import StudioInvestmentTaxData from './tax-data';
import TransactionsTable from './transactions-table';
import { currentTokenHasManageApprovalsPermission } from 'src/apps/issuer/helpers/tokenPermissions';
import KycAndCompliance, { KycAndComplianceType } from 'apps/issuer/shared/kyc-and-compliance-view';
import Link from 'core/routing/link';
import StudioInvestmentInvestorDetails from 'src/apps/issuer/shared/studio-investment-investor-details';
import Grid, { Col } from 'ui/atoms/grid';
import CreateRegistryStatement from './create-registry-statement';
import Section from 'ui/atoms/section';
import { PersonType } from 'ui/types/person';
import Spacer from 'ui/atoms/spacer';
import Header from 'ui/atoms/header';
import {
  hasDistributionManageDataPermission,
  hasDistributionViewDataPermission,
} from 'apps/issuer/helpers/distributionPlatformPermissions';
import { useCurrentUserSelector } from 'core/auth/hooks';

interface IssuerInvestmentDetailsPageProps {
  investmentId: string;
  productId: string;
}

const IssuerInvestmentDetailsPage: FunctionComponent<IssuerInvestmentDetailsPageProps> = ({
  investmentId,
  productId,
}) => {
  const [investor, setInvestor] = useState<AdminInvestor>();
  const [transactions, setTransactions] = useState<AdminTokenTransfer[]>();
  const [investment, setInvestment] = useState<AdminInvestmentDetails>();
  const [documents, setDocuments] = useState<AdminDocument[]>();

  const { currentUser } = useCurrentUserSelector();

  const { withApi, makeAuthenticatedApi } = useApiCall();

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const loadData = useCallback(() => {
    withApi(async () => {
      const investment = await adminApi.adminInvestmentsRetrieve({
        id: investmentId,
      });
      const investor = await adminApi.adminInvestorsRetrieve({
        id: investment.investor.id,
      });

      const transactions = await adminApi.adminInvestmentTransfersList({
        investment: investmentId,
      });

      const documents = await adminApi.adminInvestmentsDocumentsList({
        id: investmentId,
      });

      setInvestment(investment);

      setInvestor(investor);

      setTransactions(transactions);

      setDocuments(documents);
    });
  }, [withApi, setInvestment, setInvestor, investmentId]);

  useEffect(() => {
    loadData();
  }, [loadData, investmentId]);

  const { transactionLink, loading: transactionLinkLoading } = useTransactionLink(
    investment?.tokenTransactionHash,
    investment?.token?.tokenizationInfo?.network,
    investment?.token?.tokenizationInfo?.networkType,
  );

  const onRegistryStatementCreated = useCallback(() => {
    withApi(async () => {
      const investment = await adminApi.adminInvestmentsRetrieve({
        id: investmentId,
      });
      setInvestment(investment);
    });
  }, []);

  if (!investment || !investor || transactionLinkLoading)
    return (
      <WideContent title={<Translate name="dashboardIssuerInvestmentDetails.titleNoContent" />}>
        <LoadingRing />
      </WideContent>
    );

  let {
    acceptedDate,
    paymentDate,
    signedDate,
    investor: minimalInvestor,
    canCancel,
    cancellationImpediments,
    agio,
    disagio,
    accruedInterest,
    cancellationDate,
    cancellationDeadline,
    investmentTotal,
    investmentTotalFulfilled,
    remainingAmount,
    paymentTotal,
    tokenPrice,
    acquisitionType,
    voucher,
    investmentCompliance,
    entryType,
  } = investment;

  const canManageData = hasDistributionManageDataPermission(
    currentUser?.associatedDistributionPlatforms?.filter(
      (platform) => platform.id === investor?.distributionPlatformId,
    ) || null,
  );
  const canManageApprovals = currentTokenHasManageApprovalsPermission(investment?.token);
  const canSeeDocuments = hasDistributionViewDataPermission(
    currentUser?.associatedDistributionPlatforms?.filter(
      (platform) => platform.id === investor?.distributionPlatformId,
    ) || null,
  );

  return (
    <>
      <WideContent title={<Translate name="dashboardIssuerInvestmentDetails.title" args={[investment.token.name]} />}>
        <BoxedContent releaseSpaceWhenTitleIsEmpty={true}>
          <GeneralInvestmentDetails
            investorName={minimalInvestor.companyName || minimalInvestor.name}
            investmentId={investment.id}
            status={investment?.frontendStatus}
            acceptedDate={acceptedDate}
            signedDate={signedDate}
            paymentDate={paymentDate}
            transactionUrl={transactionLink}
            investmentTotalFulfilled={investmentTotalFulfilled}
            investmentTotal={investmentTotal}
            tokenPrice={tokenPrice}
            remainingAmount={remainingAmount}
            acquisitionType={acquisitionType}
            setInvestment={setInvestment}
            canCancelReasons={canCancel}
            cancellationImpediments={cancellationImpediments}
            loadData={loadData}
            cancellationDeadline={cancellationDeadline}
            cancellationDate={cancellationDate}
            hasManageDataPermission={canManageData}
            hasManageApprovalsPermission={canManageApprovals}
            entryType={entryType}
            distributionPlatformId={investor.distributionPlatformId}
          />
        </BoxedContent>
        <Grid>
          <Col width={6}>
            <Header size="small" spacing="medium">
              <Translate name="studioInvestmentTaxData.title" />
            </Header>
          </Col>
          <Col width={6}>
            <Header size="small" spacing="medium">
              <Translate name="studioInvestmentPaymentDetails.title" />
            </Header>
          </Col>
        </Grid>
        <Grid spacing="large">
          <Col width={6}>
            <BoxedContent releaseSpaceWhenTitleIsEmpty={true} fullHeight>
              <StudioInvestmentTaxData taxInformation={investor.person?.taxInformation} />
            </BoxedContent>
          </Col>
          <Col width={6}>
            <BoxedContent releaseSpaceWhenTitleIsEmpty={true} fullHeight>
              <StudioInvestmentPaymentDetails
                agio={agio}
                disagio={disagio}
                accruedInterest={accruedInterest}
                investmentTotalFulfilled={investmentTotalFulfilled}
                canCancelReasons={canCancel}
                token={investment.token}
                investmentId={investmentId}
                loadData={loadData}
                paymentTotal={paymentTotal}
                tokenPrice={tokenPrice}
                cancellationDate={cancellationDate}
                acceptedDate={acceptedDate}
                investmentCompliance={investmentCompliance}
                voucher={voucher}
                hasManageDataPermission={canManageData}
                useUnits={investment.token.useUnits}
              />
            </BoxedContent>
          </Col>
        </Grid>
        {acquisitionType !== InvestmentAcquisitionTypeRecipientEnum.HANDOVER && (
          <Grid spacing="large">
            <Col>
              <BoxedContent title={<Translate name="kycComplianceDetails.title" />} releaseSpaceWhenTitleIsEmpty={true}>
                <KycAndCompliance
                  type={KycAndComplianceType.INVESTMENT}
                  kycStatus={investment.investorKyc}
                  hasCompliance={investment.usesEffecta}
                  complianceExportRequired={investment.complianceExportRequired}
                  comment={investment.investmentCompliance?.comment}
                  complianceStatus={investment.investmentCompliance?.status}
                  complianceDate={investment.investmentCompliance?.complianceDate}
                  hasManageDataPermission={canManageData}
                  canCancelReasons={canCancel}
                  cancellationDate={cancellationDate}
                  investment={investment}
                  investmentId={investmentId}
                  investorId={investor.id}
                  loadData={loadData}
                />
              </BoxedContent>
            </Col>
          </Grid>
        )}
      </WideContent>
      {!!transactions?.length && (
        <Section>
          <WideContent title={<Translate name="dashboard.investorTransactions" />} ignorePageTitle>
            <TransactionsTable transactions={transactions} productId={productId} />
          </WideContent>
        </Section>
      )}
      {canSeeDocuments && (
        <WideContent
          title={<Translate name="dashboard.issuerDocuments" />}
          ignorePageTitle
          headerPanel={
            canManageData &&
            investment.token.ewpg &&
            investment.paymentDate && (
              <CreateRegistryStatement
                investmentId={investment.id}
                onRegistryStatementCreated={onRegistryStatementCreated}
              />
            )
          }
        >
          <DocumentTable documents={documents} />
        </WideContent>
      )}
      <Spacer y={4} />
      <WideContent>
        <BoxedContent
          title={<Translate name="studioInvestmentInvestorDetails.title" />}
          releaseSpaceWhenTitleIsEmpty={true}
        >
          <StudioInvestmentInvestorDetails
            name={minimalInvestor.companyName || minimalInvestor.name}
            email={minimalInvestor.email}
            activeInvestmentsNo={investor.activeInvestmentsNo}
            activeInvestmentsSum={investor.activeInvestmentsSum}
            isLegalPerson={investor.person.personType === PersonType.Legal}
            custodianBank={(investor.person as LegalPerson).custodianBank}
          />
          <ActionButtons>
            <Link variant="secondary" to={`/dashboard/investor/${investor.id}`}>
              <Translate name="studioInvestmentInvestorDetails.goToInvestor" />
            </Link>
          </ActionButtons>
        </BoxedContent>
        <Link icon="arrow-left" to="/dashboard/investments">
          <Translate as="span" name="dashboardIssuerInvestmentDetails.goToInvestments" />
        </Link>
      </WideContent>
    </>
  );
};

export default WithAuthenticatedPage(IssuerInvestmentDetailsPage, AppType.ISSUER);

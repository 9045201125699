export enum Platform {
  CASHLINK = 'cashlink',
  TRADIAS = 'tradias',
  WEBZEICHNUNG = 'webzeichnung',
  ARTTRADE = 'arttrade',
  LAIQON = 'laiqon',
  GERMANREALESTATE = 'germanrealestate',
  ETERNA = 'eterna',
  GRUENWELT = 'gruenwelt',
  KRYPTOFANTEN = 'kryptofanten',
  IQFAIRPROFIT = 'iqfairprofit',
  TOKEN4ENERGY = 'token4energy',
  IMMVESTIFY = 'immvestify',
  ART360X = 'art360x',
  SENSUS = 'sensus',
  RIDGE = 'ridge',
  SIINVEST = 'siinvest',
  AGITAREX = 'agitarex',
  BRIGHTPOINT = 'brightpoint',
  // <PLATFORM_ONBOARDING>{{PLATFORM|upper}}= "{{PLATFORM}}",</PLATFORM_ONBOARDING>
}

export type Platforms = (typeof Platform)[keyof typeof Platform];

export type PlatformType = `${Platform}`;

export const PlatformList = Object.values(Platform);

export interface PlatformConfig {}

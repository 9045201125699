import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { StateValues } from 'react-use-form-state';
import { SalutationEnum, AdminApi, CountryEnum, CurrencyEnum, AdminIssuerCreationRequest, DocumentsApi } from 'api';
import useApiCall from 'hooks/use-api-call';
import Translate from 'ui/atoms/translate';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons, makeModalForm } from 'ui/molecules/modal';
import Grid, { Col } from 'ui/atoms/grid';
import Header from 'src/ui/atoms/header';
import useCountries from 'src/hooks/use-countries';
import useCurrencies from 'src/hooks/use-currencies';

interface AddIssuerModalProps {
  onAddNewIssuer: () => void;
  onClose: () => void;
}

interface AddIssuerFields {
  companyName: string;
  commercialRegister: string;
  commercialRegisterNumber: string;
  street: string;
  zip: string;
  city: string;
  country: CountryEnum;
  iban: string;
  accountHolder: string;
  currency: CurrencyEnum;
  bank: string;
  bic: string;
  bankCountry: CountryEnum;
  salutation: SalutationEnum;
  forename: string;
  surname: string;
  email: string;
  commercialRegisterStatement: Blob | null;
  commercialRegisterStatementId: string;
  listOfShareholders: Blob | null;
  listOfShareholdersId: string;
}

export const AddIssuerForm = makeModalForm<AddIssuerFields>();

const AddIssuerModal: FunctionComponent<AddIssuerModalProps> = ({ onAddNewIssuer, onClose }) => {
  const { makeAuthenticatedApi, withApi, loading: apiLoading } = useApiCall();

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);
  const documentsApi: DocumentsApi = useMemo(() => makeAuthenticatedApi(DocumentsApi), [makeAuthenticatedApi]);

  const onSubmit = useCallback(
    (fieldsValues: StateValues<AddIssuerFields>) => {
      withApi(async () => {
        const issuerCreationBody: AdminIssuerCreationRequest = {
          naturalPerson: {
            salutation: fieldsValues.salutation,
            forename: fieldsValues.forename,
            surname: fieldsValues.surname,
            email: fieldsValues.email,
          },
          legalPerson: {
            companyName: fieldsValues.companyName,
            street: fieldsValues.street,
            city: fieldsValues.city,
            zip: fieldsValues.zip,
            country: fieldsValues.country,
            commercialRegister: fieldsValues.commercialRegister,
            commercialRegisterNumber: fieldsValues.commercialRegisterNumber,
            commercialRegisterStatementId: fieldsValues.commercialRegisterStatementId,
            listOfShareholdersId: fieldsValues.listOfShareholdersId,
          },
          bankAccount: {
            accountHolder: fieldsValues.accountHolder,
            iban: fieldsValues.iban,
            bic: fieldsValues.bic,
            bank: fieldsValues.bank,
            country: fieldsValues.bankCountry,
            currency: fieldsValues.currency,
          },
        };

        if (fieldsValues.commercialRegisterStatement) {
          const commercialRegisterStatementResponse = await documentsApi.adminDocumentsCreate({
            file: fieldsValues.commercialRegisterStatement,
          });
          issuerCreationBody.legalPerson.commercialRegisterStatementId = commercialRegisterStatementResponse.id;
        }
        if (fieldsValues.listOfShareholders) {
          const listOfShareholdersResponse = await documentsApi.adminDocumentsCreate({
            file: fieldsValues.listOfShareholders,
          });
          issuerCreationBody.legalPerson.listOfShareholdersId = listOfShareholdersResponse.id;
        }

        await api.adminIssuersCreate({ adminIssuerCreationRequest: issuerCreationBody });
        onAddNewIssuer();
      });
    },
    [withApi, api],
  );

  const countries = useCountries();
  const currencies = useCurrencies();

  return (
    <Modal onClose={onClose}>
      <AddIssuerForm
        i18nKey="addIssuer"
        onSubmit={(values: StateValues<AddIssuerFields>) => {
          onSubmit(values);
        }}
        initial={{
          currency: 'EUR',
        }}
      >
        <ModalHeader>
          <Translate name="addIssuer.title" />
        </ModalHeader>
        <ModalContent>
          <AddIssuerForm.ValueProvider>
            {(values) => (
              <>
                <Header size="xsmall" spacing="medium">
                  <Translate name="addIssuer.subtitleNameCommercialRegister" />
                </Header>
                <AddIssuerForm.Group name="companyName" required={true}>
                  <AddIssuerForm.Input autoFocus={true} />
                </AddIssuerForm.Group>
                <AddIssuerForm.Group name="commercialRegister" required={true}>
                  <AddIssuerForm.Input />
                </AddIssuerForm.Group>
                <AddIssuerForm.Group name="commercialRegisterNumber" required={true}>
                  <AddIssuerForm.Input />
                </AddIssuerForm.Group>
                <Header size="xsmall" spacing="medium">
                  <Translate name="addIssuer.subtitleAddress" />
                </Header>
                <AddIssuerForm.Group name="street" required={true}>
                  <AddIssuerForm.Input />
                </AddIssuerForm.Group>
                <Grid>
                  <Col width={6} tablet={12} phone={12}>
                    <AddIssuerForm.Group name="zip" required={true}>
                      <AddIssuerForm.Input />
                    </AddIssuerForm.Group>
                  </Col>
                  <Col width={6} tablet={12} phone={12}>
                    <AddIssuerForm.Group name="city" required={true}>
                      <AddIssuerForm.Input />
                    </AddIssuerForm.Group>
                  </Col>
                </Grid>
                <AddIssuerForm.Group name="country" required={true}>
                  <AddIssuerForm.Select options={countries} value={values.country}></AddIssuerForm.Select>
                </AddIssuerForm.Group>
                <Header size="xsmall" spacing="medium">
                  <Translate name="addIssuer.subtitleBank" />
                </Header>
                <AddIssuerForm.Group name="iban" required={true}>
                  <AddIssuerForm.Input />
                  {AddIssuerForm.Validators.IBAN()}
                </AddIssuerForm.Group>
                <AddIssuerForm.Group name="accountHolder" required={true}>
                  <AddIssuerForm.Input />
                </AddIssuerForm.Group>
                <AddIssuerForm.Group name="currency" required={true}>
                  <AddIssuerForm.Select options={currencies} value={values.currency}></AddIssuerForm.Select>
                </AddIssuerForm.Group>
                <AddIssuerForm.Group name="bank" required={true}>
                  <AddIssuerForm.Input />
                </AddIssuerForm.Group>
                <AddIssuerForm.Group name="bic" required={true}>
                  <AddIssuerForm.Input />
                  {AddIssuerForm.Validators.Bic()}
                </AddIssuerForm.Group>
                <AddIssuerForm.Group name="bankCountry" required={true}>
                  <AddIssuerForm.Select options={countries} value={values.country}></AddIssuerForm.Select>
                </AddIssuerForm.Group>
                <Header size="xsmall" spacing="medium">
                  <Translate name="addIssuer.subtitleCeo" />
                </Header>
                <AddIssuerForm.Group name="salutation" required={true}>
                  <AddIssuerForm.GroupToggle>
                    {Object.values(SalutationEnum).map((salutation, index) => (
                      <AddIssuerForm.Radio value={salutation} key={index}>
                        <Translate name={`personalInformation.salutations.${salutation.toLowerCase()}`} />
                      </AddIssuerForm.Radio>
                    ))}
                  </AddIssuerForm.GroupToggle>
                </AddIssuerForm.Group>
                <Grid>
                  <Col width={6} tablet={12} phone={12}>
                    <AddIssuerForm.Group name="forename" required={true}>
                      <AddIssuerForm.Input />
                    </AddIssuerForm.Group>
                  </Col>
                  <Col width={6} tablet={12} phone={12}>
                    <AddIssuerForm.Group name="surname" required={true}>
                      <AddIssuerForm.Input />
                    </AddIssuerForm.Group>
                  </Col>
                </Grid>
                <AddIssuerForm.Group name="email" required={true}>
                  <AddIssuerForm.Input />
                  {AddIssuerForm.Validators.Email()}
                </AddIssuerForm.Group>
                <AddIssuerForm.Group name="commercialRegisterStatement" required={true}>
                  <AddIssuerForm.FileUpload />
                  {AddIssuerForm.Validators.FileSize(5000000)}
                </AddIssuerForm.Group>
                <AddIssuerForm.Group name="listOfShareholders" required={true}>
                  <AddIssuerForm.FileUpload />
                  {AddIssuerForm.Validators.FileSize(5000000)}
                </AddIssuerForm.Group>
              </>
            )}
          </AddIssuerForm.ValueProvider>
        </ModalContent>
        <ModalFooter>
          <ModalFooterButtons
            actionButtons={[
              {
                name: 'cancel',
                content: <Translate name="common.close" />,
                size: 'large',
                onClick: () => onClose(),
              },
              {
                name: 'send',
                content: <Translate name="addInvestor.submit" />,
                variant: 'primary',
                type: 'submit',
                loading: apiLoading,
                size: 'large',
              },
            ]}
          />
        </ModalFooter>
      </AddIssuerForm>
    </Modal>
  );
};

export default AddIssuerModal;

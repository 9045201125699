import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { StateValues } from 'react-use-form-state';
import { AdminApi, AdminProductDistributionPlatformListing, DistributionPlatform } from 'api';
import useApiCall from 'hooks/use-api-call';
import Button from 'ui/atoms/button';
import Translate from 'ui/atoms/translate';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons, makeModalForm } from 'ui/molecules/modal';
import Spacer from 'src/ui/atoms/spacer';
import { useCurrentUserSelector } from 'src/core/auth/hooks';

interface SelectDistributionPlatformProps {
  listings: AdminProductDistributionPlatformListing[];
  productId: string;
  productName: string;
  onListingAdded: () => void;
}

interface SelectDistributionPlatformFields {
  distributionPlatform: string;
}

export const Form = makeModalForm<SelectDistributionPlatformFields>();

const SelectDistributionPlatform: FunctionComponent<SelectDistributionPlatformProps> = ({
  listings,
  productId,
  productName,
  onListingAdded,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState<null | string>(null);

  const { currentUser } = useCurrentUserSelector();

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const filteredListings: DistributionPlatform[] = (currentUser?.allowlistForDistribution || []).filter(
    (allowlistItem) => !listings.some((item) => item.distributionPlatformId === allowlistItem.id),
  );

  const onSubmit = useCallback((fieldsValues: StateValues<SelectDistributionPlatformFields>) => {
    withApi(async () => {
      await api.adminListingsCreate({
        adminProductDistributionPlatformListingCreationRequest: {
          productId,
          distributionPlatformId: fieldsValues.distributionPlatform,
        },
      });
      setModalOpen(false);
      onListingAdded();
    });
  }, []);

  const onClose = useCallback(() => {
    setSelectedPlatform(null);
    setModalOpen(false);
  }, [selectedPlatform]);

  return (
    <>
      <Button variant="primary" onClick={() => setModalOpen(true)}>
        <Translate name="listings.selectDistributionPlatform" />
      </Button>
      {isModalOpen && (
        <Modal onClose={onClose}>
          <Form
            onSubmit={(values: StateValues<SelectDistributionPlatformFields>) => {
              onSubmit(values);
            }}
          >
            <ModalHeader>
              <Translate name="listings.title" />
            </ModalHeader>
            <ModalContent>
              <p>
                <Translate name="listings.description" args={[productName]} />
              </p>
              <Spacer y={2} />
              <Form.Group name="distributionPlatform" required={true}>
                <Form.Select
                  options={filteredListings.map((platform) => ({
                    value: platform.id,
                    label: platform.platformName,
                  }))}
                  onChange={(platform: string) => {
                    setSelectedPlatform(platform);
                  }}
                  portalTarget={document.body}
                ></Form.Select>
              </Form.Group>
            </ModalContent>
            <ModalFooter>
              <ModalFooterButtons
                actionButtons={[
                  {
                    name: 'cancel',
                    content: <Translate name="common.cancel" />,
                    size: 'large',
                    onClick: onClose,
                  },
                  {
                    name: 'save',
                    content: <Translate name="listings.save" />,
                    variant: 'primary',
                    type: 'submit',
                    size: 'large',
                    disabled: selectedPlatform === null,
                  },
                ]}
              />
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default SelectDistributionPlatform;

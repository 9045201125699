import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import Button from 'ui/atoms/button';
import Translate from 'src/ui/atoms/translate';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons } from 'ui/molecules/modal';
import { AdminProductDetail, ProductStatusEnum } from 'api/models';
import useApiCall from 'src/hooks/use-api-call';
import { AdminApi } from 'src/api';

interface ValidateCryptoSecuritiesProps {
  productId: string;
  onUpdateProductStatus: (token: AdminProductDetail) => void;
}

const ValidateCryptoSecurities: FunctionComponent<ValidateCryptoSecuritiesProps> = ({
  productId,
  onUpdateProductStatus,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const { withApi, makeAuthenticatedApi } = useApiCall(true);
  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const onClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const onSubmit = useCallback(() => {
    withApi(async () => {
      const token = await api.adminTokensPartialUpdate({
        id: productId,
        patchedAdminProductDetailRequest: {
          status: ProductStatusEnum.VALIDATION_SUCCESS,
        },
      });

      setModalOpen(false);
      onUpdateProductStatus(token);
    });
  }, [withApi, api]);

  return (
    <>
      <Button variant="primary" onClick={() => setModalOpen(true)}>
        <Translate name="issuerProductDetails.cryptoSecuritiesValidation.validate" />
      </Button>
      {isModalOpen && (
        <Modal onClose={onClose}>
          <ModalHeader>
            <Translate name="issuerProductDetails.cryptoSecuritiesValidation.validate" />
          </ModalHeader>
          <ModalContent>
            <Translate name="issuerProductDetails.cryptoSecuritiesValidation.validationConfirm" />
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              actionButtons={[
                {
                  name: 'cancel',
                  content: <Translate name="common.cancel" />,
                  size: 'large',
                  onClick: onClose,
                },
                {
                  name: 'submit',
                  content: <Translate name="issuerProductDetails.cryptoSecuritiesValidation.validate" />,
                  variant: 'primary',
                  type: 'submit',
                  size: 'large',
                  onClick: onSubmit,
                },
              ]}
            />
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ValidateCryptoSecurities;

import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import DataRecordTable from 'src/libraries/data-record-table';
import Translate from 'ui/atoms/translate';
import Icon from 'ui/atoms/icon';
import { AdminApi, AdminTokensListRequest, AdminProductDetail, AdminTokensListStatusEnum } from 'api';
import { camelCase } from 'change-case';
import Currency from 'ui/atoms/currency';
import { FiltersWrapper } from 'ui/molecules/filtering';
import StatusTag from 'src/ui/atoms/status-tag';
import DataRecordOrdering from 'ui/types/data-ordering';
import WithDataRecord from 'hoc/WithDataRecord';

export interface ProductsTableProps extends AdminTokensListRequest {
  defaultOrdering: DataRecordOrdering;
  defaultLimit: number;
  forceDataLoad?: boolean;
  goToDetails: (productId: string) => void;
  title?: ReactNode;
  emptyView?: ReactNode;
  loading?: boolean;
  filters?: ReactNode;
}

const ProductsTable: FunctionComponent<ProductsTableProps> = WithDataRecord<AdminApi, AdminProductDetail>(AdminApi)(
  ({ data, paginationProps, goToDetails, title, emptyView, loading, filters }) => {
    const getProductStatusVariant = useCallback((status: AdminTokensListStatusEnum) => {
      switch (status) {
        case AdminTokensListStatusEnum.PROCESSING:
        case AdminTokensListStatusEnum.WAITING:
        case AdminTokensListStatusEnum.QUEUED:
        case AdminTokensListStatusEnum.CREATED:
        case AdminTokensListStatusEnum.VALIDATION_PENDING:
          return 'warning';
        case AdminTokensListStatusEnum.SUCCEEDED:
        case AdminTokensListStatusEnum.VALIDATION_SUCCESS:
          return 'success';
        case AdminTokensListStatusEnum.FAILED:
        case AdminTokensListStatusEnum.VALIDATION_REJECTED:
        case AdminTokensListStatusEnum.UNKNOWN:
          return 'danger';
      }
    }, []);

    return (
      <>
        {filters && <FiltersWrapper>{filters}</FiltersWrapper>}

        <DataRecordTable
          data={data}
          title={title}
          loading={loading}
          emptyView={emptyView}
          paginationProps={paginationProps}
          cells={[
            {
              title: <Translate name={`issuerProducts.issuerProductsTable.productName`} />,
              orderingFieldName: 'name',
              render: (product) => (
                <div>
                  <b>{product.name}</b>
                  <div>
                    <Translate name={`productType.${camelCase(product.productType || '')}`} />
                  </div>
                </div>
              ),
            },
            {
              title: <Translate name={`issuerProducts.issuerProductsTable.issuerName`} />,
              orderingFieldName: 'issuer_name',
              render: (product) => product.issuer?.person?.companyName,
            },
            {
              title: <Translate name={`issuerProducts.issuerProductsTable.totalVolume`} />,
              orderingFieldName: 'total_volume',
              alignContent: 'right',
              render: (product) => <Currency>{product.totalVolume}</Currency>,
            },
            {
              title: <Translate name={`issuerProducts.issuerProductsTable.status`} />,
              orderingFieldName: 'status',
              render: (product) => (
                <StatusTag variant={getProductStatusVariant(product.status)} truncate={true}>
                  <Translate name={`issuerProducts.status.${product.status}`} />
                </StatusTag>
              ),
            },
            {
              title: '',
              render: ({ id }) => (
                <Icon name="arrow-right" color="primary" onClick={() => goToDetails(id)} size="large" />
              ),
              alignContent: 'right',
            },
          ]}
        />
      </>
    );
  },
  (api, props, offset, limit) => {
    return api.adminTokensList({
      offset,
      limit,
      ...props,
    });
  },
);

export default ProductsTable;

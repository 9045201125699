import { AdminApi, CountryEnum, DocumentsApi } from 'api';
import useApiCall from 'hooks/use-api-call';
import React, { FunctionComponent, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import { Modal, makeModalForm, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons } from 'ui/molecules/modal';
import { StateValues } from 'react-use-form-state';
import Grid, { Col } from 'ui/atoms/grid';
import useCountries from 'src/hooks/use-countries';

export interface EditIssuerCompanyDataFields {
  id: string;
  companyName: string;
  street: string;
  zip: string;
  city: string;
  country: CountryEnum;
  commercialRegister: string;
  commercialRegisterNumber: string;
  commercialRegisterStatement: Blob | null;
  listOfShareholders: Blob | null;
  commercialRegisterStatementId: string;
  listOfShareholdersId: string;
}

export interface EditCompanyDataModalProps {
  onHideEditCompanyData: () => void;
  fetchIssuerDetails: () => void;
  showSuccessModal: () => void;
  values: EditIssuerCompanyDataFields;
}

const EditIssuerCompanyDataForm = makeModalForm<EditIssuerCompanyDataFields>();

const EditCompanyDataModal: FunctionComponent<EditCompanyDataModalProps> = (props) => {
  const { fetchIssuerDetails, onHideEditCompanyData, showSuccessModal = () => {}, values } = props;

  const [issuerData, setIssuerData] = useState<EditIssuerCompanyDataFields>(values);

  const { makeAuthenticatedApi, withApi, loading } = useApiCall();

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const documentsApi: DocumentsApi = useMemo(() => makeAuthenticatedApi(DocumentsApi), [makeAuthenticatedApi]);

  const editCompanyData = (fieldsValues: StateValues<EditIssuerCompanyDataFields>) => {
    withApi(async () => {
      const patchBody = {
        id: values.id,
        patchedAdminIssuerUpdateRequest: {
          legalPerson: {
            ...fieldsValues,
          },
        },
      };

      if (fieldsValues.commercialRegisterStatement) {
        const commercialRegisterStatementResponse = await documentsApi.adminDocumentsCreate({
          file: fieldsValues.commercialRegisterStatement,
        });
        patchBody.patchedAdminIssuerUpdateRequest.legalPerson.commercialRegisterStatementId =
          commercialRegisterStatementResponse.id;
      } else {
        patchBody.patchedAdminIssuerUpdateRequest.legalPerson.commercialRegisterStatementId =
          values.commercialRegisterStatementId;
      }
      if (fieldsValues.listOfShareholders) {
        const listOfShareholdersResponse = await documentsApi.adminDocumentsCreate({
          file: fieldsValues.listOfShareholders,
        });
        patchBody.patchedAdminIssuerUpdateRequest.legalPerson.listOfShareholdersId = listOfShareholdersResponse.id;
      } else {
        patchBody.patchedAdminIssuerUpdateRequest.legalPerson.listOfShareholdersId = values.listOfShareholdersId;
      }

      await adminApi.adminIssuersPartialUpdate(patchBody);

      showSuccessModal();
      fetchIssuerDetails();
      onHideEditCompanyData();
    });
  };

  return (
    <>
      <Modal onClose={onHideEditCompanyData}>
        <EditIssuerCompanyDataForm
          initial={{
            companyName: issuerData.companyName,
            street: issuerData.street,
            zip: issuerData.zip,
            city: issuerData.city,
            country: issuerData.country,
            commercialRegister: issuerData.commercialRegister,
            commercialRegisterNumber: issuerData.commercialRegisterNumber,
            commercialRegisterStatement: null,
            listOfShareholders: null,
          }}
          onChange={(values: StateValues<EditIssuerCompanyDataFields>) => {
            setIssuerData(values);
          }}
          onSubmit={(values: StateValues<EditIssuerCompanyDataFields>) => {
            return editCompanyData(values);
          }}
          i18nKey="dashboardIssuerInvestorDetails.editCompanyDataForm"
        >
          <ModalHeader>
            <Translate name="dashboardIssuerInvestorDetails.editCompanyDataForm.title" />
          </ModalHeader>
          <ModalContent>
            <EditIssuerCompanyDataForm.Group name="street" required={!!values.street}>
              <EditIssuerCompanyDataForm.Input placeholder="-" />
            </EditIssuerCompanyDataForm.Group>
            <Grid>
              <Col width={4} spacing="small">
                <EditIssuerCompanyDataForm.Group name="zip" required={!!values.zip}>
                  <EditIssuerCompanyDataForm.Input placeholder="-" />
                </EditIssuerCompanyDataForm.Group>
              </Col>
              <Col width={8} spacing="small">
                <EditIssuerCompanyDataForm.Group name="city" required={!!values.city}>
                  <EditIssuerCompanyDataForm.Input value={issuerData.city} placeholder="-" />
                </EditIssuerCompanyDataForm.Group>
              </Col>
            </Grid>
            <EditIssuerCompanyDataForm.Group name="country" required={!!values.country}>
              <EditIssuerCompanyDataForm.Select
                options={useCountries()}
                value={issuerData.country}
                onChange={(country: CountryEnum) => {
                  setIssuerData({ ...issuerData, country });
                }}
              ></EditIssuerCompanyDataForm.Select>
            </EditIssuerCompanyDataForm.Group>
            <EditIssuerCompanyDataForm.Group name="commercialRegister" required={!!values.commercialRegister}>
              <EditIssuerCompanyDataForm.Input />
            </EditIssuerCompanyDataForm.Group>
            <EditIssuerCompanyDataForm.Group
              name="commercialRegisterNumber"
              required={!!values.commercialRegisterNumber}
            >
              <EditIssuerCompanyDataForm.Input />
            </EditIssuerCompanyDataForm.Group>
            <EditIssuerCompanyDataForm.Group name="commercialRegisterStatement" required={true}>
              <EditIssuerCompanyDataForm.FileUpload />
              {EditIssuerCompanyDataForm.Validators.FileSize(5000000)}
            </EditIssuerCompanyDataForm.Group>
            <EditIssuerCompanyDataForm.Group name="listOfShareholders" required={true}>
              <EditIssuerCompanyDataForm.FileUpload />
              {EditIssuerCompanyDataForm.Validators.FileSize(5000000)}
            </EditIssuerCompanyDataForm.Group>
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              actionButtons={[
                {
                  name: 'cancel',
                  content: <Translate name="common.close" />,
                  size: 'large',
                  onClick: onHideEditCompanyData,
                },
                {
                  name: 'send',
                  content: <Translate name="dashboardIssuerInvestorDetails.editCompanyDataForm.button" />,
                  variant: 'primary',
                  size: 'large',
                  type: 'submit',
                  loading,
                },
              ]}
            />
          </ModalFooter>
        </EditIssuerCompanyDataForm>
      </Modal>
    </>
  );
};

export default EditCompanyDataModal;

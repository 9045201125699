import React, { FunctionComponent, ReactNode } from 'react';
import Translate from 'ui/atoms/translate';
import Icon from 'ui/atoms/icon';
import DataRecordTable from 'src/libraries/data-record-table';
import { AdminIssuerList } from 'api';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import Number from 'ui/atoms/number';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import { PaginationProps } from 'ui/molecules/pagination';

export interface StudioIssuersTableProps {
  ordering?: DataRecordOrdering;
  onOrderBy?: (fieldName: string) => void;
  issuers?: AdminIssuerList[];
  goToDetails: (investorId: string) => void;
  emptyView?: ReactNode;
  loading?: boolean;
  paginationProps?: PaginationProps;
}

const StudioIssuersTable: FunctionComponent<StudioIssuersTableProps> = (props) => {
  const { ordering, onOrderBy, issuers, goToDetails, emptyView, loading, paginationProps } = props;

  return (
    <DataRecordTable
      ordering={ordering}
      onOrderBy={onOrderBy}
      data={issuers}
      loading={loading}
      emptyView={emptyView}
      paginationProps={paginationProps}
      cells={[
        {
          title: <Translate name={`studioIssuers.studioIssuersTable.issuer`} />,
          render: ({ companyName }) => <PlaceholderFallback>{companyName}</PlaceholderFallback>,
        },
        {
          title: <Translate name={`studioIssuers.studioIssuersTable.productsNumber`} />,
          alignContent: 'right',
          render: ({ numberOfProducts }) => (
            <PlaceholderFallback>
              {numberOfProducts !== undefined && numberOfProducts !== null && <Number>{numberOfProducts}</Number>}
            </PlaceholderFallback>
          ),
        },
        {
          title: '',
          render: ({ id }) => <Icon name="arrow-right" color="primary" onClick={() => goToDetails(id)} size="large" />,
          alignContent: 'right',
        },
      ]}
    />
  );
};

export default StudioIssuersTable;

import styled from 'styled-components';
import { resolveColor } from 'ui/styles/utils/colors';
import { query } from 'ui/styles/queries';
import Icon from 'src/ui/atoms/icon';

export const ProductInfo = styled.div`
  margin: 0 auto;
  border-radius: 1rem;
  padding: 1rem;
  background-color: ${({ theme }) => resolveColor(theme, 'grayLight')};
  margin-bottom: 1rem;

  ${query.phone`
    margin: 1rem;
    margin-top: 0;
  `};
`;

export const SameRowWithTooltip = styled.div`
  display: flex;
  align-items: center;
  margin-right: -12px;
`;

export const LabelTooltip = styled.h4`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
`;

export const SameRowNoTooltip = styled.div`
  margin-right: 34px;
`;

export const TooltipIcon = styled(Icon)`
  padding: 0;
`;

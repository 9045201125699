import React, { ComponentType, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import first from 'lodash/first';
import omit from 'lodash/omit';
import { useApiCall } from 'hooks/use-api-call';
import { PaginationProps } from 'ui/molecules/pagination';
import useObservedDelta from '../hooks/use-observed-delta';
import FilterProvider from 'ui/molecules/filtering';
import type { FilterValues } from 'ui/molecules/filtering/types';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import { BaseAPI } from 'api';
import useIsMounted from 'hooks/use-is-mounted';

type Class<T> = new (...args: any[]) => T;

export interface WithDataRecordProps<Data> {
  data?: Data[];
  ordering?: DataRecordOrdering;
  onOrderBy: (fieldName: string) => void;
  loadData: () => void;
  loading: boolean;
  count?: number;
  paginationProps?: PaginationProps;
}

interface DataRecordApiResponse<Data> {
  count?: number;
  results?: Data[];
}

export interface WithDataRecordComponentProps {
  availableLimits?: number[];
  defaultLimit?: number;
  defaultOrdering: DataRecordOrdering;
  forceDataLoad?: boolean;
  singleLabelMultipleOptions?: { value: any[]; label: string; name: string }[];
  singleLabelMultipleOptionsFilterName?: string;
}

// todo: major need of a refactor, too much magic, can't modify anything without breaking a million other things
const WithDataRecord =
  <Api extends BaseAPI, Data>(api: Class<Api>) =>
  <P extends object>(
    Component: ComponentType<P & WithDataRecordProps<Data>>,
    apiFetch: (
      api: Api,
      props: P,
      offset: number,
      limit: number,
      ordering?: string,
    ) => Promise<DataRecordApiResponse<Data>>,
  ) => {
    const WithDataRecordComponent: FunctionComponent<WithDataRecordComponentProps & P> = (props) => {
      const {
        availableLimits = [10, 50, 100],
        defaultLimit = 10,
        defaultOrdering,
        forceDataLoad,
        singleLabelMultipleOptions,
        singleLabelMultipleOptionsFilterName,
      } = props;

      const isMounted = useIsMounted();
      const [memoizedProps, setMemoizedProps] = useState<Partial<P>>(omit(props, ['filters']));
      const [filters, setFilters] = useState({
        offset: 0,
        limit: defaultLimit || first(availableLimits) || 10,
        ordering: undefined as DataRecordOrdering | undefined,
        userFilters: {} as FilterValues,
      });

      const [responseData, setResponseData] = useState<DataRecordApiResponse<Data>>({});

      const [loading, setLoading] = useState(true);

      const { makeAuthenticatedApi, withApi } = useApiCall(true);

      useObservedDelta(omit(props, ['filters']), () => {
        setMemoizedProps(omit(props, ['filters']));
      });

      const authenticatedApi: Api = useMemo(() => makeAuthenticatedApi(api), [makeAuthenticatedApi]);

      const filter = useCallback((props: FilterValues) => {
        setFilters((prev) => ({ ...prev, offset: 0, userFilters: props }));
      }, []);

      const load = useCallback(() => {
        withApi(async ({ takeLatest }) => {
          try {
            setLoading(true);
            const defaultOrderingCondition = defaultOrdering
              ? `${{ desc: '-', asc: '' }[defaultOrdering.direction as 'asc' | 'desc']}${defaultOrdering.fieldName}`
              : undefined;

            const apiOrdering = filters.ordering
              ? `${{ desc: '-', asc: '' }[filters.ordering.direction as 'asc' | 'desc']}${filters.ordering.fieldName}`
              : defaultOrderingCondition;

            const responseData = await apiFetch(
              authenticatedApi,
              {
                ...(memoizedProps as P),
                ...filters.userFilters,
              },
              filters.offset,
              filters.limit,
              apiOrdering,
            );

            takeLatest(() => {
              if (!isMounted()) return;

              setResponseData(responseData);
              setLoading(false);
            });
          } catch (e) {
            console.error(e);
          }
        });
      }, [
        filters,
        forceDataLoad,
        defaultOrdering?.direction,
        defaultOrdering?.fieldName,
        authenticatedApi,
        withApi,
        memoizedProps,
      ]);

      useEffect(load, [load]);

      const onOrderBy = useCallback((fieldName: string) => {
        const orderingPriorities: ('asc' | 'desc' | 'unsorted')[] = ['desc', 'asc', 'unsorted'];

        setFilters((prevFilter) => {
          const prevOrderingDirection =
            (prevFilter.ordering?.fieldName === fieldName && prevFilter.ordering?.direction) || 'unsorted';

          const orderIndex = orderingPriorities.findIndex((dir) => dir?.includes(prevOrderingDirection));

          const newOrderingDirection = orderingPriorities[(orderIndex + 1) % orderingPriorities.length];

          if (newOrderingDirection === 'unsorted')
            return {
              ...prevFilter,
              ordering: undefined,
            };

          return {
            ...prevFilter,
            ordering: {
              fieldName: fieldName,
              direction: newOrderingDirection,
            },
          };
        });
      }, []);

      return (
        <>
          <FilterProvider
            filter={filter}
            entries={responseData.count}
            activeFilters={filters.userFilters}
            loading={loading}
            singleLabelMultipleOptions={singleLabelMultipleOptions}
            singleLabelMultipleOptionsFilterName={singleLabelMultipleOptionsFilterName}
          >
            <Component
              {...props}
              data={responseData.results}
              ordering={filters.ordering}
              count={responseData.count || 0}
              onOrderBy={onOrderBy}
              loadData={load}
              loading={loading}
              setFilters={setFilters}
              paginationProps={{
                limit: filters.limit,
                offset: filters.offset || 0,
                count: responseData.count || 0,
                availableLimits: availableLimits,
                onLimitChanged: (newLimit: number) => {
                  setFilters((prevFilter) => ({
                    ...prevFilter,
                    limit: newLimit,
                    offset:
                      newLimit < prevFilter.limit
                        ? Math.ceil(prevFilter.offset / newLimit) * newLimit
                        : Math.floor(prevFilter.offset / newLimit) * newLimit,
                  }));
                },
                onOffsetChanged: (offset: number) => {
                  setFilters((prevFilter) => ({ ...prevFilter, offset }));
                },
              }}
            />
          </FilterProvider>
        </>
      );
    };

    return WithDataRecordComponent;
  };

export default WithDataRecord;

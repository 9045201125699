import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { AdminApi, ChangeTypeEnum, DocumentsApi } from 'api';
import Translate from 'ui/atoms/translate';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons, makeModalForm } from 'ui/molecules/modal';
import ServerError from 'ui/types/server-error';
import useApiCall from 'src/hooks/use-api-call';
import { StateValues } from 'react-use-form-state';

interface EditIssuanceDocumentModalProps {
  onUploadSuccess: () => void;
  onClose: () => void;
  error?: ServerError;
  productId: string;
}

export interface EditIssuanceDocumentFields {
  documentId: string;
  changeType: ChangeTypeEnum;
  issuanceDocument?: Blob | null;
}

export const Form = makeModalForm<EditIssuanceDocumentFields>();

const EditIssuanceDocumentModal: FunctionComponent<EditIssuanceDocumentModalProps> = (props) => {
  const { onClose, error, productId, onUploadSuccess } = props;

  const { withApi, makeAuthenticatedApi, loading } = useApiCall();

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);
  const documentsApi: DocumentsApi = useMemo(() => makeAuthenticatedApi(DocumentsApi), [makeAuthenticatedApi]);

  const onSubmit = useCallback(
    (values: StateValues<EditIssuanceDocumentFields>) => {
      withApi(async () => {
        if (values.issuanceDocument) {
          const docResponse = await documentsApi.adminDocumentsCreate({
            file: values.issuanceDocument,
          });
          await adminApi.adminTokensIssuanceDocumentCreate({
            id: productId,
            adminVersionedIssuanceDocumentRequest: {
              documentId: docResponse.id,
              changeType: values.changeType,
            },
          });
          onUploadSuccess();
          onClose();
        }
      });
    },
    [withApi, adminApi],
  );

  return (
    <Modal onClose={onClose}>
      <Form
        i18nKey="editIssuanceDocumentForm"
        error={error}
        onSubmit={(values: StateValues<EditIssuanceDocumentFields>) => {
          onSubmit(values);
        }}
      >
        <ModalHeader>
          <Translate name="dashboardIssuerProductDetails.editIssuanceDocument" />
        </ModalHeader>
        <ModalContent>
          <Form.Group name="issuanceDocument" required={true}>
            <Form.FileUpload />
            {Form.Validators.FileSize(5000000)}
          </Form.Group>
          <Form.Group name="changeType" required={true}>
            <Form.Select
              portalTarget={document.body}
              options={[
                {
                  value: ChangeTypeEnum.MAJOR,
                  label: <Translate name={'dashboardIssuerProductDetails.majorChange'} />,
                },
                {
                  value: ChangeTypeEnum.MINOR,
                  label: <Translate name={'dashboardIssuerProductDetails.minorChange'} />,
                },
              ]}
            ></Form.Select>
          </Form.Group>
        </ModalContent>
        <ModalFooter>
          <ModalFooterButtons
            actionButtons={[
              {
                name: 'cancel',
                content: <Translate name="common.close" />,
                size: 'large',
                onClick: onClose,
              },
              {
                name: 'submit',
                content: <Translate name="common.submit" />,
                loading: loading,
                variant: 'primary',
                type: 'submit',
                size: 'large',
              },
            ]}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditIssuanceDocumentModal;

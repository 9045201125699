import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import CreateCampaignModal, { CreateCampaignFields } from './create-campaign-modal';
import Button from 'ui/atoms/button';
import useApiCall from 'hooks/use-api-call';
import { AdminApi } from 'api/apis';
import { toMoney, toNumber } from 'ui/helper/money';
import { handleError } from 'ui/helper/error-handling';
import useClearAfterTimeout from 'ui/hooks/use-clear-after-timeout';
import useTranslate from 'ui/hooks/use-translate';
import { AdminProductDetail, Campaign, IssuerInvestmentRules, Money } from 'api/models';
import Tooltip from 'ui/atoms/tooltip';
import Translate from 'ui/atoms/translate';
import { currentIssuerHasManageListingsPermission } from 'src/apps/issuer/helpers/issuerPermissions';
import { ProductValidationStatusEnum } from 'src/api/models/ProductValidationStatusEnum';

interface IssuerCreateCampaignProps {
  hasListings: boolean;
  onCloseAfterSuccess?: () => void;
  product: AdminProductDetail;
}

interface CampaignCalculations {
  remainingTokens: number | undefined;
  investmentTotalMin: Money | undefined;
  investmentTotalMax: Money | undefined;
}

const IssuerCreateCampaign: FunctionComponent<IssuerCreateCampaignProps> = (props) => {
  const { onCloseAfterSuccess = () => {}, children, product, hasListings } = props;

  const [isCreateCampaignOpen, setIsCreateCampaignOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingCalculation, setIsLoadingCalculation] = useState(false);

  const { withApi, makeAuthenticatedApi, error } = useApiCall(true);

  const { withApi: withCalcApi, makeAuthenticatedApi: makeAuthenticatedCalcApi, error: calcError } = useApiCall(false);

  const {
    withApi: withCampaignsApi,
    loading: loadingCampaignsApi,
    makeAuthenticatedApi: makeAuthenticatedCampaignsApi,
    error: createCampaignError,
  } = useApiCall(false);

  const [values, setValues] = useState<CreateCampaignFields>();

  const [campaign, setCampaign] = useState<Campaign>();

  const [calculations, setCalculations] = useState<CampaignCalculations>({
    remainingTokens: undefined,
    investmentTotalMin: undefined,
    investmentTotalMax: undefined,
  });

  const [ruleSets, setRuleSets] = useState<IssuerInvestmentRules[]>();

  const hasManageListingsPermission = useMemo(
    () => currentIssuerHasManageListingsPermission(product.issuer.currentUserPermissions),
    [product.issuer.currentUserPermissions],
  );

  const tokensCalcApi: AdminApi = useMemo(() => makeAuthenticatedCalcApi(AdminApi), [makeAuthenticatedCalcApi]);

  const invitationRulesApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const campaignsApi: AdminApi = useMemo(
    () => makeAuthenticatedCampaignsApi(AdminApi),
    [makeAuthenticatedCampaignsApi],
  );

  const { getRemainingError } = handleError({
    error: useClearAfterTimeout(error),
    translate: useTranslate(),
  });

  const answerErrors = getRemainingError();

  const getInitialCalculations = useCallback(() => {
    withCalcApi(async () => {
      try {
        const { remainingTokens } = await tokensCalcApi.adminTokensCalculationsRetrieve({
          id: product.id,
          requestedTokens: 0,
        });
        setIsLoadingCalculation(true);
        setCalculations({ ...calculations, remainingTokens: remainingTokens });
        setIsLoadingCalculation(false);
      } catch (error) {
        setIsLoadingCalculation(true);
        setCalculations({ ...calculations, remainingTokens: undefined });
        setIsLoadingCalculation(false);
      }
    });
  }, [withCalcApi, tokensCalcApi, product.id]);

  const getInvestmentTotalMin = useCallback(() => {
    if (!values?.minNumberOfTokens) return undefined;

    withCalcApi(async () => {
      try {
        const { investmentTotal: investmentTotalMin } = await tokensCalcApi.adminTokensCalculationsRetrieve({
          id: product.id,
          ...(values &&
            values.minNumberOfTokens && {
              requestedTokens: toNumber(values.minNumberOfTokens),
            }),
          ...(values &&
            values.pricePerToken && {
              pricePerToken: toNumber(values.pricePerToken),
            }),
        });
        setIsLoadingCalculation(true);
        setCalculations((prevCalculations) => ({ ...prevCalculations, investmentTotalMin: investmentTotalMin }));
        setIsLoadingCalculation(false);
      } catch (error) {
        setIsLoadingCalculation(true);
        setCalculations((prevCalculations) => ({ ...prevCalculations, investmentTotalMin: undefined }));
        setIsLoadingCalculation(false);
      }
    });
  }, [withCalcApi, tokensCalcApi, product.id, values?.minNumberOfTokens, values?.pricePerToken]);

  const getInvestmentTotalMax = useCallback(() => {
    if (!values?.maxNumberOfTokens) return undefined;

    withCalcApi(async () => {
      try {
        const { investmentTotal: investmentTotalMax } = await tokensCalcApi.adminTokensCalculationsRetrieve({
          id: product.id,
          ...(values &&
            values.maxNumberOfTokens && {
              requestedTokens: toNumber(values.maxNumberOfTokens),
            }),
          ...(values &&
            values.pricePerToken && {
              pricePerToken: toNumber(values.pricePerToken),
            }),
        });
        setIsLoadingCalculation(true);
        setCalculations((prevCalculations) => ({ ...prevCalculations, investmentTotalMax: investmentTotalMax }));
        setIsLoadingCalculation(false);
      } catch (error) {
        setIsLoadingCalculation(true);
        setCalculations((prevCalculations) => ({ ...prevCalculations, investmentTotalMax: undefined }));
        setIsLoadingCalculation(false);
      }
    });
  }, [withCalcApi, tokensCalcApi, product.id, values?.maxNumberOfTokens, values?.pricePerToken]);

  const createCampaign = useCallback(
    (values: CreateCampaignFields) => {
      withCampaignsApi(async () => {
        if (!product.id) return;
        const campaign = await campaignsApi.adminTokensCampaignsCreate({
          id: product.id,
          campaignRequest: {
            distributionPlatform: values.distributionPlatform,

            allocation: toNumber(values.allocation),

            // TODO: add currency after corresponding backend changes in campaignsCreate api
            // currency: values.currency

            // TODO: default currency "EUR" correct?
            pricePerToken: toMoney(values.pricePerToken.replace(',', '.')),

            minNumberOfTokens: toNumber(values.minNumberOfTokens),
            maxNumberOfTokens: toNumber(values.maxNumberOfTokens),

            // TODO: use Type Country/CampaignCountryEnum instead of any
            country: values.country as any,

            // TODO: use Type CampaignRuleType/CampaignRuleTypeEnum instead of any
            ruleType: values.ruleType as any,

            name: values.name,

            legalPersonsAllowed: values.legalPersonsAllowed ? values.legalPersonsAllowed === 'true' : undefined,

            naturalPersonsAllowed: values.naturalPersonsAllowed ? values.naturalPersonsAllowed === 'true' : undefined,

            active: true,
          },
        });
        setCampaign(campaign);
      });
    },
    [withCampaignsApi, campaignsApi, product.id],
  );

  const loadData = useCallback(() => {
    withApi(async () => {
      if (!product.id) return;
      const rules = await invitationRulesApi.adminTokensInvitationRulesList({
        id: product.id,
      });
      setRuleSets(rules);
    });
  }, [withApi, product.id, invitationRulesApi, setRuleSets]);

  const openCampaignCreation = async () => {
    setCampaign(undefined);
    setValues(undefined);
    setIsCreateCampaignOpen(true);
    loadData();
  };

  useEffect(() => {
    if (!product.id) return;
    getInitialCalculations();
  }, [product.id]);

  useEffect(() => {
    if (!product.id) return;
    getInvestmentTotalMin();
  }, [product.id, values?.minNumberOfTokens, values?.pricePerToken]);

  useEffect(() => {
    if (!product.id) return;
    getInvestmentTotalMax();
  }, [product.id, values?.maxNumberOfTokens, values?.pricePerToken]);

  useEffect(() => {
    if (answerErrors) setIsLoading(false);
  }, [answerErrors]);

  useEffect(() => {
    setIsLoading(isCreateCampaignOpen && (!product.id || !calculations));
  }, [product.id, calculations, isCreateCampaignOpen]);

  return (
    <>
      {isCreateCampaignOpen && product.id && (
        <CreateCampaignModal
          loading={loadingCampaignsApi || isLoadingCalculation}
          open={true}
          onSubmit={createCampaign}
          onCloseAfterSuccess={() => {
            onCloseAfterSuccess();
            setIsCreateCampaignOpen(false);
          }}
          onCancelTriggered={() => setIsCreateCampaignOpen(false)}
          onChange={setValues}
          currencies={product.currencies}
          allocationUpperLimit={calculations.remainingTokens}
          backToBackMinPrice={product.backToBackMinPrice}
          investmentTotalMin={calculations && calculations.investmentTotalMin}
          investmentTotalMax={calculations && calculations.investmentTotalMax}
          distributionPlatforms={product.distributionPlatforms}
          ruleSets={ruleSets}
          calculationError={calcError}
          createCampaignError={createCampaignError}
          campaignLink={campaign?.campaignLink}
        />
      )}
      {!product ||
        !product.id ||
        (product.remainingAmount > 0 && (
          <Button
            variant="primary"
            loading={isLoading}
            disabled={
              !product ||
              !product.id ||
              (hasManageListingsPermission && !hasListings) ||
              (product.ewpg === true &&
                (product.publicationValid === false ||
                  !product.issuanceDocument?.publicationDate ||
                  product.validationStatus !== ProductValidationStatusEnum.VALIDATION_SUCCESS))
            }
            onClick={openCampaignCreation}
            error={answerErrors}
          >
            {children}
          </Button>
        ))}
      {product && product.remainingAmount <= 0 && (
        <Tooltip maxWidth={188} content={<Translate name="campaignInvitation.noUnitsAllocation" />}>
          <Button variant="primary" disabled={true}>
            {children}
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default IssuerCreateCampaign;

import { findIndex, sortBy } from 'lodash';
import React, { PropsWithChildren, ReactElement, ReactNode, useMemo } from 'react';
import Pagination, { PaginationProps } from 'ui/molecules/pagination';
import Table from 'ui/molecules/table';
import { HorizontalAlign } from 'ui/types/align';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import * as Styled from './styled';

type DataType = {
  id?: string;
  [key: string]: any;
};
interface DataRecordTableCell<Data> {
  render: (data: Data) => ReactNode;
  orderingFieldName?: string;
  title?: ReactNode;
  alignContent?: HorizontalAlign;
}

export interface DataRecordTableProps<Data extends DataType> {
  /** Primary content. */
  children?: ReactNode;
  title?: ReactNode;
  ordering?: DataRecordOrdering;
  onOrderBy?: (fieldName: string) => void;
  data?: Data[];
  cells?: Array<DataRecordTableCell<Data> | boolean>;
  emptyView?: ReactNode;
  loading?: boolean;
  responsive?: boolean;
  paginationProps?: PaginationProps;
}

const DataRecordTable = <Data extends DataType>(
  props: PropsWithChildren<DataRecordTableProps<Data>>,
): ReactElement | null => {
  const {
    children,
    data,
    onOrderBy,
    ordering,
    title,
    cells,
    emptyView,
    loading,
    responsive,
    paginationProps,
    ...restProps
  } = props;

  const renderedCells = (cells?.filter(Boolean) || []) as Array<DataRecordTableCell<Data>>;

  const filterLimits = useMemo(() => {
    if (!paginationProps) return [];
    const sortedAvailableLimits = sortBy(paginationProps.availableLimits);
    const index = findIndex(sortedAvailableLimits, (limit) => paginationProps.count <= limit);
    return sortedAvailableLimits.slice(0, index === -1 ? sortedAvailableLimits.length : index + 1);
  }, [paginationProps?.availableLimits, paginationProps?.count]);

  return (
    <Styled.DataRecordTable {...restProps}>
      {data && data.length > 0 && (
        <Table title={title}>
          <Table.Head>
            <Table.Row>
              {renderedCells.map(({ orderingFieldName, alignContent, title }, i) => {
                return (
                  <Table.HeadCell
                    key={i}
                    alignContent={alignContent}
                    onOrderBy={
                      orderingFieldName && onOrderBy && data && data.length > 1
                        ? () => orderingFieldName && onOrderBy(orderingFieldName)
                        : undefined
                    }
                    orderingDirection={ordering?.fieldName === orderingFieldName ? ordering?.direction : undefined}
                  >
                    <div>{title}</div>
                  </Table.HeadCell>
                );
              })}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.map((row, i) => (
              <Table.Row key={row.id || i} disabled={row.disabled}>
                {renderedCells.map(({ alignContent, render }, i) => {
                  return (
                    <Table.Cell key={i} alignContent={alignContent}>
                      {render(row)}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            ))}
          </Table.Body>
          {paginationProps && filterLimits.length > 1 && (
            <Table.Foot>
              <Table.Row key={'pagination'}>
                <Table.Cell key={'pagination'} colSpan={12}>
                  <Pagination {...paginationProps} availableLimits={filterLimits} />
                </Table.Cell>
              </Table.Row>
            </Table.Foot>
          )}
        </Table>
      )}
      {!loading && !data?.length && <>{emptyView}</>}
    </Styled.DataRecordTable>
  );
};

export default DataRecordTable;

import { AdminApi, SalutationEnum } from 'api';
import useApiCall from 'hooks/use-api-call';
import React, { FunctionComponent, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import { Modal, makeModalForm, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import { StateValues } from 'react-use-form-state';
import Grid, { Col } from 'ui/atoms/grid';
import { Salutation } from 'ui/types/person';

export interface EditCeoFields {
  id: string;
  salutation: SalutationEnum;
  forename: string;
  surname: string;
  email: string;
}

export interface EditCeoModalProps {
  onHideEditCeo: () => void;
  fetchIssuerDetails: () => void;
  showSuccessModal: () => void;
  className?: string;
  values: EditCeoFields;
}

const EditCeoForm = makeModalForm<EditCeoFields>();

const EditCeoModal: FunctionComponent<EditCeoModalProps> = (props) => {
  const { onHideEditCeo, fetchIssuerDetails, showSuccessModal = () => {}, values } = props;

  const [issuerData, setIssuerData] = useState<EditCeoFields>(values);

  const { makeAuthenticatedApi, withApi, loading } = useApiCall();

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const editCeo = (fieldsValues: StateValues<EditCeoFields>) => {
    withApi(async () => {
      const patchBody = {
        id: values.id,
        patchedAdminIssuerUpdateRequest: {
          naturalPerson: {
            ...fieldsValues,
          },
        },
      };
      await adminApi.adminIssuersPartialUpdate(patchBody);
      showSuccessModal();
      fetchIssuerDetails();
      onHideEditCeo();
    });
  };

  return (
    <>
      <Modal onClose={onHideEditCeo}>
        <EditCeoForm
          initial={{
            salutation: issuerData.salutation,
            forename: issuerData.forename,
            surname: issuerData.surname,
            email: issuerData.email,
          }}
          onChange={(values: StateValues<EditCeoFields>) => {
            setIssuerData(values);
          }}
          onSubmit={(values: StateValues<EditCeoFields>) => {
            return editCeo(values);
          }}
          i18nKey="dashboardIssuerInvestorDetails.addIdentificationForm"
        >
          <ModalHeader>
            <Translate name="dashboardIssuerIssuerDetails.editCeoForm.title" />
          </ModalHeader>
          <ModalContent>
            <EditCeoForm.Group name="salutation" required={true}>
              <EditCeoForm.GroupToggle>
                {Object.values(Salutation).map((salutation, index) => (
                  <EditCeoForm.Radio value={salutation} key={index}>
                    <Translate name={`personalInformation.salutations.${salutation.toLowerCase()}`} />
                  </EditCeoForm.Radio>
                ))}
              </EditCeoForm.GroupToggle>
            </EditCeoForm.Group>
            <Grid>
              <Col width={4} spacing="small">
                <EditCeoForm.Group name="forename" required={!!values.forename}>
                  <EditCeoForm.Input value={issuerData.forename} placeholder="-" />
                </EditCeoForm.Group>
              </Col>
              <Col width={8} spacing="small">
                <EditCeoForm.Group name="surname" required={!!values.surname}>
                  <EditCeoForm.Input value={issuerData.surname} placeholder="-" />
                </EditCeoForm.Group>
              </Col>
            </Grid>
            <EditCeoForm.Group name="email" required={!!values.email}>
              <EditCeoForm.Input value={issuerData.email} placeholder="-" />
            </EditCeoForm.Group>
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              actionButtons={[
                {
                  name: 'cancel',
                  content: <Translate name="common.close" />,
                  size: 'large',
                  onClick: onHideEditCeo,
                },
                {
                  name: 'send',
                  content: <Translate name="dashboardIssuerIssuerDetails.editCeoForm.button" />,
                  variant: 'primary',
                  size: 'large',
                  type: 'submit',
                  loading,
                },
              ]}
            />
          </ModalFooter>
        </EditCeoForm>
      </Modal>
    </>
  );
};

export default EditCeoModal;

import React, { FunctionComponent, useCallback, useContext, useEffect, useState, useMemo } from 'react';
import LoadingRing from 'ui/atoms/loading-ring';
import WizardContext from 'libraries/wizard/wizard-context';
import Translate from 'ui/atoms/translate';
import InvestmentVibConfirmationForm from 'subapps/investment/pages/investment/wizard-steps/vib/vib-confirmation-form';
import { InvitationsApi } from 'api';
import useApiCall from 'hooks/use-api-call';
import { useServerConfigSelector } from 'core/config/hooks';
import moment from 'moment';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import {
  VibInfoInputFields,
  VibInfoPrefill,
} from 'subapps/investment/pages/investment/wizard-steps/vib/vib-confirmation-form';
import useInvestorMe from 'hooks/use-investor-me';
import { InvitationToNonPreviewInvitation } from 'helper/cast';
import { PersonType } from 'ui/types/person';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';

const VibStep: FunctionComponent<{}> = () => {
  const {
    resourceId: invitationId,
    finalize,
    loading: investmentLoading,
    invitation,
    loading: invitationLoading,
    token,
  } = useContext(WizardContext);

  const { investor: me, loading: investorLoading } = useInvestorMe();

  const investmentInvitation = InvitationToNonPreviewInvitation(invitation);
  const initialVibInfo = investmentInvitation?.vibInfo;

  const translate = useTranslateWithStringArgs();

  const [prefill, setPrefill] = useState<VibInfoPrefill | undefined>(undefined);

  const { loading: apiLoading, error: apiError, withApi, makeAuthenticatedApi } = useApiCall();

  const {
    config: { allowVibCopy },
  } = useServerConfigSelector();

  const api = useMemo(() => makeAuthenticatedApi(InvitationsApi), [makeAuthenticatedApi]);

  useEffect(() => {
    (async () => {
      await withApi(async () => {
        setPrefill(
          (await api.invitationsPrefilledVibInfoRetrieve({
            id: invitationId,
          })) as VibInfoPrefill,
        );
      });
    })();
  }, [invitationId, withApi, api]);

  const onSubmit = useCallback(
    async (values: VibInfoInputFields) => {
      await withApi(async () => {
        await api.invitationsVibInfoCreate({
          id: invitationId,
          invitationSetVibInfoRequest: {
            vibInfo: {
              ...values,
              // TODO(mara-cashlink): instead of converting dates here, introduce date input component which can handle dates
              birthDate: moment.utc(values.birthDate, translate('date.dateInputFormat')).toDate(),
              signatureDate: moment.utc(values.signatureDate, translate('date.dateInputFormat')).toDate(),
              legalEstablishedAt:
                (me?.person.personType as any) === PersonType.Legal && values.legalEstablishedAt
                  ? moment.utc(values.legalEstablishedAt, translate('date.dateInputFormat')).toDate()
                  : undefined,
            },
          },
        });

        finalize();
      });
    },
    [api, invitationId, withApi, finalize, translate, me?.person.personType],
  );

  if (!token || investmentLoading || invitationLoading || investorLoading || !me) return <LoadingRing />;

  if (!token.vib) {
    throw new Error('invalid state detected: should only show VIB step if token has a VIB');
  }

  return (
    <>
      <WizardHeader />
      <Header spacing="xlarge">
        <Translate name="vib.title" />
      </Header>
      <InvestmentVibConfirmationForm
        personType={me.person.personType as any}
        vibDoc={token.vib}
        loading={apiLoading}
        error={apiError}
        onSubmit={onSubmit}
        prefill={{
          ...prefill,
          // TODO(mara-cashlink): instead of converting the date here, introduce date input component which can handle dates
          birthDate: prefill?.birthDate && moment.utc(prefill.birthDate).format(translate('date.dateInputFormat')),
        }}
        allowCopyPrefillFields={allowVibCopy}
        initial={{
          ...initialVibInfo,
          birthDate:
            initialVibInfo?.birthDate && moment.utc(initialVibInfo.birthDate).format(translate('date.dateInputFormat')),
          signatureDate:
            initialVibInfo?.signatureDate &&
            moment.utc(initialVibInfo.signatureDate).format(translate('date.dateInputFormat')),
          legalEstablishedAt:
            initialVibInfo?.legalEstablishedAt &&
            moment.utc(initialVibInfo.legalEstablishedAt).format(translate('date.dateInputFormat')),
        }}
      />
    </>
  );
};

export default VibStep;

import { Product } from 'api';
import { useCallback, useEffect, useState } from 'react';
import { ProductsOptions } from '.';
import { VoucherFields } from '..';

export const useProductValidity = (
  products: Product[] | null,
  maxProductsShown: number,
  values: VoucherFields,
  selectedValidityProducts: string[] | undefined,
  onChangeValidity: (products: Product[] | string) => void,
) => {
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [firstColumnProducts, setFirstColumnProducts] = useState<Product[]>([]);
  const [secondColumnProducts, setSecondColumnProducts] = useState<Product[]>([]);

  const splitProducts = () => {
    if (!products || products?.length === 0) return;

    let half = Math.ceil(products.length / 2);
    if (half > maxProductsShown / 2 && half % 2 === 0) half--;

    let firstColumn = products.slice(0, half);
    let secondColumn = products.slice(half, products.length);
    if (!showAllProducts) {
      firstColumn = firstColumn.slice(0, maxProductsShown / 2);
      secondColumn = secondColumn.slice(0, maxProductsShown / 2);
    }

    setFirstColumnProducts(firstColumn);
    setSecondColumnProducts(secondColumn);
  };

  useEffect(() => {
    splitProducts();
  }, [products, showAllProducts]);

  useEffect(() => {
    onChangeValidity(
      values.validityProducts === ProductsOptions.ALL_PRODUCTS ? values.validityProducts : selectedProducts,
    );
  }, [values.validityProducts, selectedProducts]);

  useEffect(() => {
    products?.forEach((product) => {
      if (selectedValidityProducts?.includes(product.name)) {
        onChangeProduct(product, true);
      }
    });
  }, [selectedValidityProducts]);

  const onChangeProduct = useCallback(
    (product: Product, isChecked: boolean) => {
      isChecked ? selectedProducts.push(product) : selectedProducts.splice(selectedProducts.indexOf(product), 1);
      setSelectedProducts([...selectedProducts]);
    },
    [selectedProducts],
  );

  const onProductsValidityChange = useCallback(() => {
    setSelectedProducts([]);
  }, []);

  return {
    selectedProducts,
    firstColumnProducts,
    secondColumnProducts,
    showAllProducts,
    setShowAllProducts,
    onChangeProduct,
    onProductsValidityChange,
  };
};

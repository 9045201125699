import React, { FunctionComponent, useMemo } from 'react';
import { EmptyViewType } from 'src/libraries/dashboard-empty-view';
import usePlatformImage from 'ui/hooks/use-platform-image';
import useGoTo from 'hooks/use-go-to';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import {
  hasManageApprovalsPermission,
  hasManageDistributionPermission,
  hasManageIncomingPaymentsPermission,
} from 'src/apps/issuer/helpers/tokenPermissions';
import { useProducts } from 'apps/issuer/hooks';
import EmptyView, { EmptyViewActionMap, EmptyViewAssetMap } from 'core/components/empty-views/empty-view';
import { ButtonVariants } from 'ui/styles/themes/buttons';
import useClipboard from 'ui/hooks/use-clipboard';

const mapTypeAssetName: EmptyViewAssetMap = {
  [EmptyViewType.ISSUER_DOCUMENTS]: 'emptyViewDocuments',
  [EmptyViewType.ISSUER_INVESTMENTS]: 'emptyViewIssuerInvestments',
  [EmptyViewType.ISSUER_CAMPAIGN_DETAILS_INVESTMENTS]: 'emptyViewIssuerCampaignDetailsInvestments',
  [EmptyViewType.ISSUER_INVITATION_DETAILS_INVESTMENTS]: 'emptyViewIssuerCampaignDetailsInvestments',
  [EmptyViewType.ISSUER_INVESTORS]: 'emptyViewIssuerInvestors',
  [EmptyViewType.ISSUER_APPROVALS]: 'emptyViewIssuerApprovals',
  [EmptyViewType.ISSUER_PAYMENTS]: 'emptyViewIssuerPayments',
  [EmptyViewType.ISSUER_DIGITAL_SECURITIES]: 'emptyViewIssuerSecurities',
  [EmptyViewType.ISSUER_CAMPAIGNS]: 'emptyViewIssuerCampaigns',
  [EmptyViewType.ISSUER_PERSONAL_INVITATIONS]: 'emptyViewIssuerInvitations',
  [EmptyViewType.ISSUER_VOUCHERS]: 'emptyViewIssuerInvestments',
};

export interface IssuerEmptyViewProps {
  type: EmptyViewType;
  issuerCanManage?: boolean;
  openIssuerApprovals?: boolean;
  openIssuerPaymentConfirmations?: boolean;
  overviewLatestInvestments?: boolean;
  campaignLink?: string;
}

// todo: refactor this further, split it for each type
const IssuerEmptyView: FunctionComponent<IssuerEmptyViewProps> = (props) => {
  const {
    type,
    openIssuerApprovals,
    openIssuerPaymentConfirmations,
    children,
    overviewLatestInvestments,
    campaignLink,
  } = props;

  const emptyViewImage = usePlatformImage(mapTypeAssetName[type] || 'emptyViewIssuerInvestments');

  const goToApprovals = useGoTo(ISSUER_ROUTES.approvals);
  const goToIncomingPayments = useGoTo(ISSUER_ROUTES.incomingPayments);
  const goToPersonalInvitations = useGoTo(ISSUER_ROUTES.investmentInvitations);
  const { copied, copy } = useClipboard();

  const goToCampaigns = useGoTo(ISSUER_ROUTES.campaigns);

  const translate = useTranslateWithStringArgs();

  const { tokens } = useProducts();
  const canManage = hasManageDistributionPermission(tokens);
  const canApprove = hasManageApprovalsPermission(tokens);
  const canManageIncomingPayments = hasManageIncomingPaymentsPermission(tokens);

  const mapEmptyViewActions: EmptyViewActionMap = useMemo(() => {
    return {
      [EmptyViewType.ISSUER_DOCUMENTS]: undefined,
      [EmptyViewType.ISSUER_INVESTMENTS]: undefined,
      [EmptyViewType.ISSUER_INVESTORS]: undefined,
      [EmptyViewType.ISSUER_APPROVALS]: undefined,
      [EmptyViewType.ISSUER_PAYMENTS]:
        openIssuerApprovals && canApprove
          ? [
              {
                i18nKey: 'viewApprovals',
                onAction: goToApprovals,
              },
            ]
          : undefined,
      [EmptyViewType.ISSUER_DIGITAL_SECURITIES]:
        (openIssuerPaymentConfirmations && canManageIncomingPayments) || (openIssuerApprovals && canApprove)
          ? [
              ...(openIssuerPaymentConfirmations && canManageIncomingPayments
                ? [
                    {
                      i18nKey: 'viewPayments',
                      onAction: goToIncomingPayments,
                    },
                  ]
                : []),
              ...(openIssuerApprovals && canApprove
                ? [
                    {
                      i18nKey: 'viewApprovals',
                      onAction: goToApprovals,
                      buttonVariant: (openIssuerPaymentConfirmations ? 'secondary' : 'primary') as ButtonVariants,
                    },
                  ]
                : []),
            ]
          : undefined,
      [EmptyViewType.ISSUER_CAMPAIGNS]: [
        canManage
          ? {
              i18nKey: 'viewPersonalInvitations',
              buttonVariant: 'secondary',
              onAction: goToPersonalInvitations,
            }
          : {
              i18nKey: 'contactPlatform',
              href: `mailto:${translate('platform.supportEmail')}`,
            },
      ],
      [EmptyViewType.ISSUER_INVITATION_DETAILS_INVESTMENTS]: [
        campaignLink
          ? {
              i18nKey: copied ? 'linkCopied' : 'copyCampaignLink',
              buttonVariant: 'primary',
              onAction: () => copy(campaignLink),
              disabled: !!copied,
            }
          : {
              i18nKey: 'contactPlatform',
              href: `mailto:${translate('platform.supportEmail')}`,
            },
      ],
      [EmptyViewType.ISSUER_CAMPAIGN_DETAILS_INVESTMENTS]: [
        campaignLink
          ? {
              i18nKey: copied ? 'linkCopied' : 'copyCampaignLink',
              buttonVariant: 'primary',
              onAction: () => copy(campaignLink),
              disabled: !!copied,
            }
          : {
              i18nKey: 'contactPlatform',
              href: `mailto:${translate('platform.supportEmail')}`,
            },
      ],
      [EmptyViewType.ISSUER_PERSONAL_INVITATIONS]: [
        canManage
          ? {
              i18nKey: 'viewCampaigns',
              buttonVariant: 'secondary',
              onAction: goToCampaigns,
            }
          : {
              i18nKey: 'contactPlatform',
              href: `mailto:${translate('platform.supportEmail')}`,
            },
      ],
    };
  }, [
    openIssuerApprovals,
    openIssuerPaymentConfirmations,
    goToIncomingPayments,
    goToApprovals,
    goToCampaigns,
    canManage,
    canApprove,
    canManageIncomingPayments,
    copied,
    campaignLink,
  ]);

  const i18nKeyExtension: 'IssuerCanManage' | 'OpenApprovals' | 'OpenActions' | 'Latest' | undefined = useMemo(() => {
    if (canManage && [EmptyViewType.ISSUER_CAMPAIGNS, EmptyViewType.ISSUER_PERSONAL_INVITATIONS].includes(type)) {
      return 'IssuerCanManage';
    }
    if (openIssuerApprovals && type === EmptyViewType.ISSUER_PAYMENTS) {
      return 'OpenApprovals';
    }
    if (
      ((openIssuerApprovals && canApprove) || (openIssuerPaymentConfirmations && canManageIncomingPayments)) &&
      type === EmptyViewType.ISSUER_DIGITAL_SECURITIES
    ) {
      return 'OpenActions';
    }
    if (overviewLatestInvestments) {
      return 'Latest';
    }
    return undefined;
  }, [openIssuerApprovals, openIssuerPaymentConfirmations, type, canManage, canApprove, canManageIncomingPayments]);

  return (
    <EmptyView
      type={type}
      image={emptyViewImage}
      i18nKeyExtension={i18nKeyExtension}
      emptyViewActions={mapEmptyViewActions[type]}
    >
      {children}
    </EmptyView>
  );
};

export default IssuerEmptyView;

import React, { useState, FunctionComponent, useEffect, ReactNode } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import InputIcon, { InputIconProps } from 'ui/molecules/input-icon';

export interface DatePickerProps extends Omit<Omit<ReactDatePickerProps, 'onChange'>, 'onSelect'> {
  initial?: Date | string | null | undefined;
  sendEventAsValue?: boolean;
  minDate?: Date;
  maxDate?: Date;
  label?: string | ReactNode;
  value?: string;
  placeholder?: string;
  onChange?: (event: Date | null, target: EventTarget) => void;
}

const InputWrapper = React.forwardRef<
  Omit<Omit<InputIconProps, 'onChange'>, 'onSelect'>,
  Omit<Omit<DatePickerProps, 'onChange'>, 'onSelect'>
>((props, ref) => <InputIcon icon="calendar" inputRef={ref} iconSize="large" onClickIcon={() => {}} {...props} />);

export const DatePicker: FunctionComponent<DatePickerProps> = (props) => {
  const {
    onChange = () => {},
    customInput,
    className,
    value,
    placeholder,
    sendEventAsValue,
    label,
    ...restProps
  } = props;
  const [startDate, setStartDate] = useState<Date | null | undefined>(props.initial ? new Date(props.initial) : null);

  useEffect(() => {
    if (!startDate) setStartDate(value ? new Date(value) : null);
  }, [value, startDate]);

  const handleChange = (v: Date | null, event: any) => {
    // a bit of hack to force JS to parse the correct date regardless of timezone.
    // if you have a date set to 00:00:00 in your time zone, parseISOString will return it with one day early
    if (v) {
      v.setHours((-1 * v.getTimezoneOffset()) / 60);
    }
    setStartDate(v);

    // hack for syncing onChange function params between react-use-form-state and react-datepicker
    // TODO remove hack when react-datepicker library is removed
    onChange(sendEventAsValue ? { ...event, target: { value: v, validity: { valid: true } } } : v, event);
  };

  return (
    <ReactDatePicker
      value={value || ''}
      selected={startDate}
      onChange={handleChange}
      className={`cl-date-picker ${className}`}
      customInput={<InputWrapper label={label} value={value} />}
      dateFormat="dd.MM.yyyy"
      placeholderText={placeholder}
      {...restProps}
    />
  );
};

export default DatePicker;

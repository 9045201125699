import React, { FunctionComponent, useCallback } from 'react';
import FormGroup from 'ui/molecules/form-group';
import { useFilterOption } from 'ui/molecules/filtering/hooks';
import type { FilterOptionSelectionProps } from 'ui/molecules/filtering/types';
import { StyledMultiSelect } from './styled';

export interface MultiSelectItem {
  value: string;
  label: string;
  name: string;
}

export const FilterMultiSelect: FunctionComponent<FilterOptionSelectionProps> = (props) => {
  const { label, options, placeholder, onSearch } = props;
  const { value: selection, setValue: setSelection } = useFilterOption(props, []);

  const onSelect = useCallback(
    (selectedList: MultiSelectItem[]) => {
      setSelection(selectedList.map((item) => item.value));
    },
    [selection],
  );

  const selectedValues = options.filter(({ value }) =>
    Array.isArray(selection) ? selection.includes(value) : selection === value,
  );

  return (
    <FormGroup label={label}>
      <StyledMultiSelect
        options={options}
        displayValue="label"
        selectedValues={selectedValues}
        placeholder={placeholder || ''}
        showArrow={true}
        onSelect={(selectedList: MultiSelectItem[]) => onSelect(selectedList)}
        onRemove={(selectedList: MultiSelectItem[]) => onSelect(selectedList)}
        onSearch={onSearch ? (value: any) => onSearch(value) : undefined}
      />
    </FormGroup>
  );
};

export default FilterMultiSelect;

import type { PaginatedAdminProductDetailList, PaginatedAdminIssuerListList } from 'api';

export enum IssuerActions {
  LOAD_APP = 'ISSUER/LOAD_APP',
  TOKENS_FETCH = 'ISSUER/TOKENS_FETCH',
  TOKENS_FETCH_SUCCESS = 'ISSUER/TOKENS_FETCH_SUCCESS',
  TOKENS_FETCH_FAILURE = 'ISSUER/TOKENS_FETCH_FAILURE',
  ISSUERS_FETCH = 'ISSUER/ISSUERS_FETCH',
  ISSUERS_FETCH_SUCCESS = 'ISSUER/ISSUERS_FETCH_SUCCESS',
  ISSUERS_FETCH_FAILURE = 'ISSUER/ISSUERS_FETCH_FAILURE',
}

export type LoadIssuerApp = { type: IssuerActions.LOAD_APP };
export type TokensFetch = { type: IssuerActions.TOKENS_FETCH };
export type TokensFetchSuccess = {
  type: IssuerActions.TOKENS_FETCH_SUCCESS;
  data: PaginatedAdminProductDetailList;
};
export type TokensFetchFailure = {
  type: IssuerActions.TOKENS_FETCH_FAILURE;
  error: Error;
};
export type IssuersFetch = { type: IssuerActions.ISSUERS_FETCH };
export type IssuersFetchSuccess = {
  type: IssuerActions.ISSUERS_FETCH_SUCCESS;
  data: PaginatedAdminIssuerListList;
};
export type IssuersFetchFailure = {
  type: IssuerActions.ISSUERS_FETCH_FAILURE;
  error: Error;
};

export type IssuerActionTypes =
  | LoadIssuerApp
  | TokensFetch
  | TokensFetchSuccess
  | TokensFetchFailure
  | IssuersFetch
  | IssuersFetchSuccess
  | IssuersFetchFailure;

export function loadIssuerApp(): LoadIssuerApp {
  return {
    type: IssuerActions.LOAD_APP,
  };
}

export function tokensFetch(): TokensFetch {
  return {
    type: IssuerActions.TOKENS_FETCH,
  };
}

export function tokensFetchSuccess(data: PaginatedAdminProductDetailList): TokensFetchSuccess {
  return {
    type: IssuerActions.TOKENS_FETCH_SUCCESS,
    data,
  };
}

export function tokensFetchFailure(error: Error): TokensFetchFailure {
  return {
    type: IssuerActions.TOKENS_FETCH_FAILURE,
    error,
  };
}

export function issuersFetch(): IssuersFetch {
  return {
    type: IssuerActions.ISSUERS_FETCH,
  };
}

export function issuersFetchSuccess(data: PaginatedAdminIssuerListList): IssuersFetchSuccess {
  return {
    type: IssuerActions.ISSUERS_FETCH_SUCCESS,
    data,
  };
}

export function issuersFetchFailure(error: Error): IssuersFetchFailure {
  return {
    type: IssuerActions.ISSUERS_FETCH_FAILURE,
    error,
  };
}

import { AdminVoucherCreationRequest, Product, PatchedAdminVoucherUpdateRequest } from 'api';
import { CurrencyEnum } from 'api';
import { VoucherFields } from '.';
import { Step } from './voucher-steps';
import { VoucherUsesByInvestorEnum } from './voucher-uses-investor';
import { VoucherUsesTotalEnum } from './voucher-uses-total';

export const buildRequestParameters = (
  values: VoucherFields,
  noOfUsesTotalAmount: number,
  noOfUsesByInvestorAmount: number,
  productsValidity: string | Product[],
) => {
  const requestParams: AdminVoucherCreationRequest | PatchedAdminVoucherUpdateRequest = {
    distributionPlatformId: values.distributionPlatformId,
    name: values.voucherName,
    code: values.voucherCode,
    numberOfUses: values.numberOfUsesTotal === VoucherUsesTotalEnum.UNLIMITED ? 0 : noOfUsesTotalAmount,
    firstTimeOnly: true,
  };

  if (values.numberOfUsesByInvestor === VoucherUsesByInvestorEnum.LIMITED_TO) {
    requestParams.numberOfUsesInvestor = noOfUsesByInvestorAmount;
    requestParams.firstTimeOnly = false;
  } else if (values.numberOfUsesByInvestor === VoucherUsesByInvestorEnum.UNLIMITED) {
    requestParams.numberOfUsesInvestor = 0;
    requestParams.firstTimeOnly = false;
  } else {
    requestParams.firstTimeOnly = true;
  }

  if (typeof productsValidity === 'string') {
    requestParams.allProducts = true;
    requestParams.products = [];
  } else {
    requestParams.products = productsValidity.map((item: Product) => item.id);
  }
  return requestParams;
};

export const validate = (values: AdminVoucherCreationRequest | PatchedAdminVoucherUpdateRequest, steps: Step[]) => {
  let error = '';

  if (values.numberOfUses! < 0) {
    error = 'noUsesInvalid';
  } else if (!values.firstTimeOnly && values.numberOfUsesInvestor && values.numberOfUsesInvestor < 0) {
    error = 'noUsesInvestorInvalid';
  } else if (checkStepsErrors(steps)) {
    error = checkStepsErrors(steps);
  } else if (!values.allProducts && values.products && values.products.length === 0) {
    error = 'productsMissing';
  }
  return error.length ? `createVoucherForm.error.${error}` : null;
};

const checkStepsErrors = (steps: Step[]) => {
  let error = '';
  steps.forEach((step) => {
    if (!step.minInvestment || !step.discount || Number(step.minInvestment) < 0 || Number(step.discount) < 0) {
      error = 'stepsMissing';
      return;
    }
    if (steps.filter((el) => el.id !== step.id).some((el) => el.minInvestment === step.minInvestment)) {
      error = 'stepExists';
      return;
    }
    if (step.discountSelection === CurrencyEnum.EUR && Number(step.minInvestment) < Number(step.discount)) {
      error = 'discountValue';
      return;
    }
    if (step.discountSelection === '%' && Number(step.discount) > 100) {
      error = 'discountPercentage';
      return;
    }
  });
  return error;
};

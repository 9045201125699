import { NaturalPerson, LegalPerson, Identification, BenefitingPerson, AdminInvestor } from 'api';
import { camelCase } from 'change-case';
import Date from 'ui/atoms/date';
import PersonName from 'ui/atoms/person-name';
import { compact } from 'lodash';
import { hideCentralCharacters } from 'ui/helper/hideCharacters';
import Currency from 'ui/atoms/currency';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import DocumentModal from 'src/libraries/document-modal';
import React from 'react';
import Translate from 'ui/atoms/translate';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';

interface UserDataProps {
  investor?: AdminInvestor;
  rootPerson?: LegalPerson | NaturalPerson | null;
  naturalPerson?: NaturalPerson | null;
  legalPerson?: LegalPerson | null;
  benefitingPersons?: BenefitingPerson[];
  identification?: Identification;
  canReidentify?: boolean;
  canAddIdentification?: boolean;
  canManage?: boolean;
  restartIdentification: () => void;
  setEditRegistrationAddressModal: (val: boolean) => void;
  setEditCompanyDataModal: (val: boolean) => void;
  setEditBankAccountModal: (val: boolean) => void;
  setEditDepositAccountModal: (val: boolean) => void;
  setEditTaxInformationModal: (val: boolean) => void;
  setAddIdentificationModal: (val: boolean) => void;
  setEditBenefitingPersonsModal: (val: boolean) => void;
}

const useUserData = (props: UserDataProps) => {
  const {
    investor,
    rootPerson,
    naturalPerson,
    legalPerson,
    benefitingPersons,
    identification,
    canReidentify,
    canAddIdentification,
    canManage,
    restartIdentification,
    setEditRegistrationAddressModal,
    setEditCompanyDataModal,
    setEditBankAccountModal,
    setEditDepositAccountModal,
    setEditTaxInformationModal,
    setEditBenefitingPersonsModal,
    setAddIdentificationModal,
  } = props;

  const translate = useTranslateWithStringArgs();

  const userData = {
    personalData: {
      key: 'personalData',
      fields: {
        salutation: {
          label: <Translate name={'dashboardInvestorSettings.personalData.salutation'} />,
          value: naturalPerson?.salutation && (
            <Translate name={`personalInformation.salutations.${camelCase(naturalPerson?.salutation)}`} />
          ),
        },
        name: {
          label: <Translate name={'dashboardInvestorSettings.personalData.name'} />,
          value: (
            <PersonName
              person={{
                forename: naturalPerson?.forename,
                surname: naturalPerson?.surname,
                companyName: legalPerson?.companyName,
              }}
            />
          ),
        },
        birthDate: {
          label: <Translate name={'dashboardInvestorSettings.personalData.birthDate'} />,
          value: naturalPerson?.birthDate && <Date>{naturalPerson?.birthDate}</Date>,
        },
        birthPlace: {
          label: <Translate name={'dashboardInvestorSettings.personalData.birthPlace'} />,
          value: naturalPerson?.birthPlace,
        },
        nationality: {
          label: <Translate name={'dashboardInvestorSettings.personalData.nationality'} />,
          value: naturalPerson?.nationality,
        },
      },
      editButtons: [
        {
          display: false,
          text: 'common.edit',
          onClick: () => {},
        },
      ],
    },
    registrationAddress: {
      key: 'registrationAddress',
      fields: {
        street: {
          label: <Translate name={'dashboardInvestorSettings.registrationAddress.street'} />,
          value: naturalPerson?.street,
        },
        city: {
          label: <Translate name={'dashboardInvestorSettings.registrationAddress.city'} />,
          value: compact([naturalPerson?.zip, naturalPerson?.city]).join(' '),
        },
        country: {
          label: <Translate name={'dashboardInvestorSettings.registrationAddress.country'} />,
          value: naturalPerson?.country,
        },
      },
      editButtons: [
        {
          display: canManage,
          text: 'common.edit',
          onClick: () => setEditRegistrationAddressModal(true),
        },
      ],
    },
    taxData: {
      key: 'taxData',
      fields: {
        taxIdentificationNumber: {
          label: <Translate name={'dashboardInvestorSettings.taxData.taxIdentificationNumber'} />,
          value:
            rootPerson?.taxInformation?.taxIdentificationNumber &&
            hideCentralCharacters(rootPerson.taxInformation.taxIdentificationNumber, 2, -2),
        },
        exemptionOrderSum: {
          label: <Translate name={'dashboardInvestorSettings.taxData.exemptionOrderSum'} />,
          value: (
            <PlaceholderFallback>
              {rootPerson?.taxInformation?.exemptionOrderSum && (
                <Currency>{rootPerson.taxInformation.exemptionOrderSum}</Currency>
              )}
            </PlaceholderFallback>
          ),
        },
        exemptionOrderDate: {
          label: <Translate name={'dashboardInvestorSettings.taxData.exemptionOrderDate'} />,
          value: (
            <PlaceholderFallback>
              {rootPerson?.taxInformation?.exemptionOrderDate && (
                <Date>{rootPerson?.taxInformation?.exemptionOrderDate}</Date>
              )}
            </PlaceholderFallback>
          ),
        },
        nonAssessmentCertificate: {
          label: <Translate name={'dashboardInvestorSettings.taxData.nonAssessmentCertificate'} />,
          value: rootPerson?.taxInformation?.nonAssessmentCertificate && (
            <Translate
              name="common.viewDocument"
              args={[
                (text: string, key) => {
                  if (!rootPerson?.taxInformation?.nonAssessmentCertificate) return;
                  return (
                    <DocumentModal key={key} {...rootPerson.taxInformation.nonAssessmentCertificateDoc}>
                      {text}
                    </DocumentModal>
                  );
                },
              ]}
            />
          ),
        },
      },
      editButtons: [
        {
          display: canManage,
          text: 'common.edit',
          onClick: () => setEditTaxInformationModal(true),
        },
      ],
    },
    identification: {
      key: 'identification',
      fields: {
        verifiedAt: {
          label: <Translate name={'dashboardInvestorSettings.identification.verifiedAt'} />,
          value: <Date>{identification?.verifiedAt}</Date>,
        },
        legitimationProtocol: {
          label: <Translate name={'dashboardInvestorSettings.identification.legitimationProtocol'} />,
          value: identification?.legitimationProtocol && (
            <Translate
              name="common.viewDocument"
              args={[
                (text: string, key) => {
                  if (!identification?.legitimationProtocol?.id) return;
                  return (
                    <DocumentModal key={key} {...identification.legitimationProtocol}>
                      {text}
                    </DocumentModal>
                  );
                },
              ]}
            />
          ),
        },
        documentId: {
          label: <Translate name={'dashboardInvestorSettings.identification.documentId'} />,
          value: identification?.documentId,
        },
        documentType: {
          label: <Translate name={'dashboardInvestorSettings.identification.documentType'} />,
          value: translate(
            `identificationDocumentType.${snakeToCamelCaseWithNumbers(
              identification?.documentType?.toLowerCase() || '',
            )}`,
          ),
        },
        validationInterval: {
          label: <Translate name={'dashboardInvestorSettings.identification.validationInterval'} />,
          value: (
            <>
              {' '}
              <Date>{identification?.documentValidFrom}</Date> - <Date>{identification?.documentValidTo}</Date>{' '}
            </>
          ),
        },
        issuingOffice: {
          label: <Translate name={'dashboardInvestorSettings.identification.issuingOffice'} />,
          value: identification?.documentIssuer,
        },
      },
      editButtons: [
        {
          display: canReidentify,
          text: 'dashboardInvestorSettings.identification.restartButton',
          onClick: () => restartIdentification(),
        },
        {
          display: canAddIdentification,
          text: 'dashboardInvestorSettings.identification.addButton',
          onClick: () => setAddIdentificationModal(true),
        },
      ],
    },
    walletsData: {
      key: 'walletsData',
      fields: {},
      editButtons: [
        {
          display: false,
          text: '',
          onClick: () => {},
        },
      ],
    },
    bankData: {
      key: 'bankData',
      fields: {
        bankAccountHolder: {
          label: <Translate name={'dashboardInvestorSettings.bankData.bankAccountHolder'} />,
          value: rootPerson?.bankAccount?.accountHolder,
        },
        ibanBank: {
          label: <Translate name={'dashboardInvestorSettings.bankData.ibanBank'} />,
          value: rootPerson?.bankAccount?.iban && hideCentralCharacters(rootPerson.bankAccount.iban, 4, -2),
        },
        target2Bic: {
          label: <Translate name={'dashboardInvestorSettings.bankData.target2Bic'} />,
          value: investor?.target2Bic,
        },
      },
      editButtons: [
        {
          display: canManage,
          text: 'common.edit',
          onClick: () => setEditBankAccountModal(true),
        },
      ],
    },
    depositData: {
      key: 'depositData',
      fields: {
        securitiesAccountHolder: {
          label: <Translate name={'dashboardInvestorSettings.depositData.securitiesAccountHolder'} />,
          value: rootPerson?.securitiesDepositAccount?.accountHolder,
        },
        securitiesAccountNumber: {
          label: <Translate name={'dashboardInvestorSettings.depositData.securitiesAccountNumber'} />,
          value:
            rootPerson?.securitiesDepositAccount?.accountNumber &&
            hideCentralCharacters(rootPerson?.securitiesDepositAccount?.accountNumber, 2, -2),
        },
        bicSecurities: {
          label: <Translate name={'dashboardInvestorSettings.depositData.bicSecurities'} />,
          value: rootPerson?.securitiesDepositAccount?.bic,
        },
      },
      editButtons: [
        {
          display: canManage,
          text: 'common.edit',
          onClick: () => setEditDepositAccountModal(true),
        },
      ],
    },
    companyData: {
      key: 'companyData',
      fields: {
        companyName: {
          label: <Translate name={'dashboardInvestorSettings.companyData.companyName'} />,
          value: (
            <PersonName
              person={{
                companyName: legalPerson?.companyName,
              }}
            />
          ),
        },
        legalEntityType: {
          label: <Translate name={'dashboardInvestorSettings.companyData.legalEntityType'} />,
          value: legalPerson?.legalEntityType,
        },
        companyStreet: {
          label: <Translate name={'dashboardInvestorSettings.companyData.companyStreet'} />,
          value: legalPerson?.street,
        },
        companyCity: {
          label: <Translate name={'dashboardInvestorSettings.companyData.companyCity'} />,
          value: legalPerson?.city,
        },
        companyCountry: {
          label: <Translate name={'dashboardInvestorSettings.companyData.companyCountry'} />,
          value: legalPerson?.country && <Translate name={`countries.${legalPerson?.country}`} />,
        },
        commercialRegister: {
          label: <Translate name={'dashboardInvestorSettings.companyData.commercialRegister'} />,
          value: compact([legalPerson?.commercialRegister, legalPerson?.commercialRegisterNumber]).join(': '),
        },
        commercialRegisterStatement: {
          label: <Translate name={'dashboardInvestorSettings.companyData.commercialRegisterStatement'} />,
          value: legalPerson?.commercialRegisterStatement && (
            <Translate
              name="common.viewDocument"
              args={[
                (text: string, key) => {
                  if (!legalPerson?.commercialRegisterStatement) return;
                  return (
                    <DocumentModal key={key} {...legalPerson.commercialRegisterStatement}>
                      {text}
                    </DocumentModal>
                  );
                },
              ]}
            />
          ),
        },
        listOfShareholders: {
          label: <Translate name={'dashboardInvestorSettings.companyData.listOfShareholders'} />,
          value: legalPerson?.listOfShareholders && (
            <Translate
              name="common.viewDocument"
              args={[
                (text: string, key) => {
                  if (!legalPerson?.listOfShareholders) return;
                  return (
                    <DocumentModal key={key} {...legalPerson?.listOfShareholders}>
                      {text}
                    </DocumentModal>
                  );
                },
              ]}
            />
          ),
        },
        transparencyRegister: {
          label: <Translate name={'dashboardInvestorSettings.companyData.transparencyRegister'} />,
          value: legalPerson?.transparencyRegister && (
            <Translate
              name="common.viewDocument"
              args={[
                (text: string, key) => {
                  if (!legalPerson?.transparencyRegister) return;
                  return (
                    <DocumentModal key={key} {...legalPerson?.transparencyRegister}>
                      {text}
                    </DocumentModal>
                  );
                },
              ]}
            />
          ),
        },
      },
      editButtons: [
        {
          display: canManage,
          text: 'common.edit',
          onClick: () => setEditCompanyDataModal(true),
        },
      ],
    },
    beneficialOwners: benefitingPersons?.length
      ? {
          key: 'beneficialOwners',
          fields: benefitingPersons?.reduce(
            (obj, person, index) => ({
              ...obj,
              [`person${index}`]: {
                label: <Translate name="dashboardInvestorSettings.beneficialOwners.person" args={[index + 1]} />,
                value: `${person.forename} ${person.surname}`,
              },
            }),
            {},
          ),
          editButtons: [
            {
              display: canManage,
              text: 'common.edit',
              onClick: () => setEditBenefitingPersonsModal(true),
            },
          ],
        }
      : {
          key: 'beneficialOwners',
          fields: [
            {
              label: <Translate name={'dashboardInvestorSettings.beneficialOwners.noPerson'} />,
            },
          ],
          editButtons: [
            {
              display: canManage,
              text: 'dashboardInvestorSettings.beneficialOwners.addButton',
              onClick: () => setEditBenefitingPersonsModal(true),
            },
          ],
        },
  };

  const userSections = [
    userData.personalData,
    userData.registrationAddress,
    legalPerson ? userData.companyData : null,
    legalPerson ? userData.beneficialOwners : null,
    userData.identification,
    userData.walletsData,
    userData.bankData,
    userData.depositData,
    userData.taxData,
  ];

  return userSections;
};

export default useUserData;

import styled from 'styled-components';
import { query } from 'ui/styles/queries';

export const GroupToggle = styled.div`
  display: flex;

  ${query.phone`
    overflow: auto;
  `};
`;

import React, { createContext, FunctionComponent, ReactNode, useContext, useMemo, useState } from 'react';
import useHubspotChatbot from 'hooks/use-hubspot-chatbot';
import Imprint from 'core/components/imprint';
import * as Styled from './styled';
import { useServerConfigSelector } from 'core/config/hooks';
import NavBar from 'core/nav/navbar/wizard-navbar';
import { useCurrentUserSelector, useIsLoggedInSelector } from 'core/auth/hooks';
import { isInvestor } from 'core/auth/helper';
import WizardContext from 'libraries/wizard/wizard-context';

export interface WizardStep {
  id: number;
  title: ReactNode;
  tooltip?: ReactNode;
  current?: boolean;
  active?: boolean;
  done?: boolean;
  onClick?: () => void;
}

interface WizardLayoutProps {
  registerOnlyFlow?: boolean;
  children?: ReactNode;
}

export const WizardLayoutContext = createContext({
  setWizardSteps: (steps?: WizardStep[]) => {},
});

const WizardLayout: FunctionComponent<WizardLayoutProps> = (props) => {
  const { registerOnlyFlow, children } = props;
  const [wizardSteps, setWizardSteps] = useState<WizardStep[]>();
  const { config } = useServerConfigSelector();
  const { invitation } = useContext(WizardContext);

  const isLoggedIn = useIsLoggedInSelector();
  const { currentUser } = useCurrentUserSelector();

  const isEcsp = useMemo(() => {
    return (registerOnlyFlow && config.features.ecsp) || (!registerOnlyFlow && invitation?.isEcsp);
  }, [config.features.ecsp, invitation?.isEcsp]);

  const steps = useMemo(
    () =>
      (wizardSteps || []).map(({ id, title, tooltip, current, done, active, onClick }) => ({
        id,
        title,
        tooltip,
        current,
        done,
        active,
        onClick,
      })),
    [wizardSteps],
  );

  useHubspotChatbot();

  return (
    <WizardLayoutContext.Provider
      value={{
        setWizardSteps,
      }}
    >
      <Styled.WizardLayout>
        <Styled.Header>
          <NavBar
            progressSteps={steps}
            showInvestorOverview={isLoggedIn && currentUser?.confirmed && isInvestor(currentUser)}
          />
        </Styled.Header>
        <Styled.WizardContent>
          <Styled.Main>{children}</Styled.Main>
        </Styled.WizardContent>
        <Styled.Footer>
          <Imprint
            color="white"
            iconColor="white"
            url={isEcsp ? 'https://www.effecta-gmbh.de/impressum/' : undefined}
          />
        </Styled.Footer>
      </Styled.WizardLayout>
    </WizardLayoutContext.Provider>
  );
};

export default WizardLayout;

import { useCallback, useEffect, useMemo, useState } from 'react';
import useApiCall from 'src/hooks/use-api-call';
import { InvestorStepEnum, InvestorWizardStep } from 'src/api/models';
import { InvestorsApi } from 'src/api/apis';
import { AnyServerError } from 'src/hooks/use-api-error';
import { useIsLoggedInSelector, useCurrentUserSelector } from 'core/auth/hooks';
import applicationConfig from 'src/core/config/local';

const useRegister = (): {
  step: InvestorStepEnum | undefined;
  steps?: InvestorWizardStep[];
  loading: boolean;
  error?: AnyServerError;
  reload: () => void;
  setStep: (step: InvestorStepEnum | undefined) => void;
  setSteps: (steps: InvestorWizardStep[] | undefined) => void;
} => {
  const { withApi, makeAuthenticatedApi, error, loading } = useApiCall(true);

  const [step, setStep] = useState<InvestorStepEnum>();

  const [steps, setSteps] = useState<InvestorWizardStep[]>();

  const investorApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  const isLoggedIn = useIsLoggedInSelector();
  const { currentUser } = useCurrentUserSelector();

  const loadRegisterData = useCallback(() => {
    (async () => {
      await withApi(async () => {
        let wizard = null;
        if (isLoggedIn && !currentUser?.investor) {
          return;
        }

        if (currentUser?.investor) {
          wizard = await investorApi.investorsWizardRetrieve({ id: currentUser.investor });
        } else {
          wizard = await investorApi.investorsDefaultWizardRetrieve({
            distributionPlatformName: applicationConfig.platform,
          });
        }

        setStep(wizard.step);
        setSteps(wizard.steps);
      });
    })();
  }, [withApi, currentUser?.investor]);

  useEffect(() => {
    loadRegisterData();
  }, [loadRegisterData]);

  return useMemo(
    () => ({
      step,
      steps,
      loading,
      error,
      reload: loadRegisterData,
      setStep,
      setSteps,
    }),
    [step, steps, loading, error, loadRegisterData, setStep, setSteps],
  );
};

export default useRegister;

import Grid, { Col } from 'ui/atoms/grid';
import Header from 'ui/atoms/header';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { query } from 'ui/styles/queries';

interface InfoGridProps {
  $truncate?: boolean;
}

interface InfoGridFieldProps {
  $truncate?: boolean;
  $wordBreak?: boolean;
}

function getTruncation({ $truncate }: ThemedStyledProps<InfoGridFieldProps, DefaultTheme>) {
  if (!$truncate) return null;

  return css`
    width: 100%;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 140%;
  `;
}

export const TruncatedHeader = styled(Header)<InfoGridFieldProps>`
  ${getTruncation};

  font-size: 12px !important;
  ${query.phone`
    font-size: 11px !important;
  `};
`;

export const TruncatedRow = styled(Grid)<InfoGridFieldProps>`
  ${getTruncation};
  color: ${({ theme }) => theme.colors.black};
  ${({ $wordBreak }) =>
    $wordBreak &&
    css`
      word-break: break-all;
      padding-right: 1rem;
    `};
`;

export const InfoGridRow = styled(Grid)`
  margin-bottom: 2rem;
  white-space: break-spaces;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const InfoGridCell = styled(Col)<InfoGridProps>`
  ${({ $truncate }) =>
    css`
      padding: 0;
      ${$truncate && 'min-width: 0'};
    `};
`;

export const InfoGridFieldRow = styled(Grid)<InfoGridFieldProps>`
  margin-bottom: 1rem;
  white-space: break-spaces;
  ${({ $wordBreak }) =>
    $wordBreak &&
    css`
      word-break: break-all;
      padding-right: 1rem;
    `};
`;

import React, { FunctionComponent, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import { AdminProductDetail } from 'api';
import Translate from 'ui/atoms/translate';
import ProductInvitationsTable from './invitations-table';
import IssuerCreateInvestmentInvitation from './create-investment-invitation';
import useGoTo from 'hooks/use-go-to';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import { currentTokenHasManageDistributionPermission } from 'src/apps/issuer/helpers/tokenPermissions';

interface ProductInvitationProps {
  hasListings: boolean;
  productId: string;
  product: AdminProductDetail;
}

const ProductCampaigns: FunctionComponent<ProductInvitationProps> = ({ hasListings, product, productId }) => {
  const [refreshData, setRefreshData] = useState(false);

  const goToInvitationDetails = useGoTo(ISSUER_ROUTES.investmentInvitationDetails);

  let canManage;
  if (product) {
    canManage = currentTokenHasManageDistributionPermission(product);
  }

  return (
    <WideContent
      title={<Translate name="dashboard.issuerInvestmentInvitationsTitle" />}
      ignorePageTitle
      titleSpacing="small"
      headerPanel={
        canManage ? (
          <IssuerCreateInvestmentInvitation
            hasListings={hasListings}
            onCloseAfterSuccess={() => setRefreshData(!refreshData)}
            product={product}
          >
            <Translate name="investmentInvitation.create.short" />
          </IssuerCreateInvestmentInvitation>
        ) : null
      }
    >
      <ProductInvitationsTable
        productId={productId}
        goToDetails={(invitationId: string) => goToInvitationDetails({ invitationId })}
        defaultOrdering={{ direction: 'desc', fieldName: 'created_at' }}
        defaultLimit={10}
      />
    </WideContent>
  );
};

export default ProductCampaigns;

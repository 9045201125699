import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import FilterContext from 'ui/molecules/filtering/filter-context/index';
import {
  checkIfStringIsDate,
  convertStringToDate,
  filterOutEmptyAndFormat,
  fromStringToValue,
  fromValueToString,
} from 'ui/molecules/filtering/helpers';
import type { FilterState, FiltersProps, FilterValues, InputValuesType } from 'ui/molecules/filtering/types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import { isDeepEqual } from 'src/helper/is-deep-equal';

export const FilterProvider: FunctionComponent<FiltersProps> = ({
  children,
  filter,
  entries,
  activeFilters,
  loading,
  singleLabelMultipleOptions,
  singleLabelMultipleOptionsFilterName,
}) => {
  const dispatch = useDispatch();

  const [userFilters, setUserFilters] = useState<FilterState>({});

  const [applyFilters, setApplyFilters] = useState<boolean>(false);

  const [filterNames, setFilterNames] = useState<string[]>([]);

  const filterNumber = filterNames.length || null;

  const addFilterNames = useCallback(
    (filteredValues?: FilterValues) => {
      if (!filteredValues || !Object.keys(filteredValues).length) {
        setFilterNames([]);
        return;
      }

      const filterNames: string[] = [];
      const filtersArray = filteredValues ? Object.entries(filteredValues) : [];
      if (filtersArray.length > 0) {
        filtersArray.forEach((filter) => {
          // date filter
          const filterKey = filter[0];
          const filterVal = filter[1] instanceof Date ? moment(filter[1]).format('DD-MM-YYYY') : filter[1];
          if (Array.isArray(filterVal)) {
            // multi-values filter
            filterVal.forEach((val) => {
              // logic for filter values where multiple fields sent to the backend represent only one field shown to the user
              if (filterKey === singleLabelMultipleOptionsFilterName && singleLabelMultipleOptions?.length) {
                let filterNameSet = false;
                singleLabelMultipleOptions.forEach((option: any) => {
                  if (option.value.includes(val)) {
                    filterNameSet = true;
                    if (filterNames.includes(option.name)) {
                      filterNameSet = true;
                      return;
                    }
                    filterNames.push(option.name);
                  }
                });
                if (!filterNameSet) {
                  filterNames.push(fromValueToString(val));
                }
              } else {
                filterNames.push(fromValueToString(val));
              }
            });
          } else {
            // one-value filter
            filterNames.push(fromValueToString(filterVal));
          }
          setFilterNames(filterNames);
        });
      }
    },
    [fromValueToString, setFilterNames, singleLabelMultipleOptionsFilterName, singleLabelMultipleOptions],
  );

  const formatFilters = useCallback(
    (values: FilterState) => {
      // filter out empty properties
      const filteredValues = filterOutEmptyAndFormat(values);

      if (isDeepEqual(filteredValues, activeFilters)) return;

      return filteredValues;
    },
    [JSON.stringify(activeFilters)],
  );

  const getFiltersFromUrl = useCallback(
    (url: URL) => {
      const filters: FilterState = {};

      url.searchParams.forEach((paramVal: string, paramKey: string) => {
        // for treating multiple-values filters
        const splittedFilterValue = paramVal.split(',');

        let formattedFilterValue: InputValuesType = splittedFilterValue;

        if (splittedFilterValue.length <= 1 && checkIfStringIsDate(splittedFilterValue[0])) {
          // date filter
          const dateFormattedValue = convertStringToDate(splittedFilterValue[0]);

          // a bit of hack to force JS to parse the correct date regardless of timezone.
          // if you have a date set to 00:00:00 in your time zone, parseISOString will return it with one day early
          dateFormattedValue.setHours((-1 * dateFormattedValue.getTimezoneOffset()) / 60);
          formattedFilterValue = dateFormattedValue;
        } else if (splittedFilterValue.length === 1) {
          // one-value filter
          formattedFilterValue = fromStringToValue(splittedFilterValue[0]);
        } else {
          // multi-values filter
          formattedFilterValue = formattedFilterValue.map((val) => fromStringToValue(val));
        }

        filters[paramKey] = {
          fieldName: paramKey,
          value: formattedFilterValue,
        };
      });

      return filters;
    },
    [checkIfStringIsDate, convertStringToDate, fromStringToValue],
  );

  const addFiltersToURL = useCallback(
    (filteredValues?: FilterValues) => {
      const url = new URL(window.location.href);
      url.search = '';
      const filtersArray = filteredValues ? Object.entries(filteredValues) : [];
      if (filtersArray.length > 0) {
        filtersArray.forEach((filter) => {
          const filterKey = filter[0];
          // // date filter
          const filterVal = filter[1] instanceof Date ? moment(filter[1]).format('DD-MM-YYYY') : filter[1];
          if (Array.isArray(filterVal)) {
            // multi-values filter
            url.searchParams.set(filterKey, filterVal.map((val) => fromValueToString(val)).toString());
          } else {
            // one-value filter
            url.searchParams.set(filterKey, fromValueToString(filterVal));
          }
        });
      }
      dispatch(push(url.pathname + url.search));
    },
    [dispatch, new URL(window.location.href)],
  );

  useEffect(() => {
    const extractedFilters = getFiltersFromUrl(new URL(window.location.href));

    setUserFilters(extractedFilters);

    const formattedValsToFilter = formatFilters(extractedFilters);

    if (!formattedValsToFilter) return;

    filter(formattedValsToFilter);
    addFilterNames(formattedValsToFilter);
  }, [new URL(window.location.href).searchParams.toString()]);

  useEffect(() => {
    if (applyFilters) {
      const newValuesToFilter = formatFilters(userFilters);
      newValuesToFilter && addFiltersToURL(newValuesToFilter);
      setApplyFilters(false);
    }
  }, [applyFilters, JSON.stringify(userFilters)]);

  return (
    <FilterContext.Provider
      value={{
        userFilters,
        setUserFilters,
        filterNumber,
        activeFilters,
        entries,
        loading,
        applyFilters,
        setApplyFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;

import StatusTag from 'ui/atoms/status-tag';
import styled from 'styled-components';

export const TitleContainer = styled.span`
  display: flex;
  align-items: center;
`;

export const PublicationStatusTag = styled(StatusTag)`
  font-weight: normal;
`

import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import Translate from 'ui/atoms/translate';
import SelectDistributionPlatform from './select-distribution-platform';
import { currentIssuerHasManageListingsPermission } from 'src/apps/issuer/helpers/issuerPermissions';
import { AdminProductDetail, AdminProductDistributionPlatformListing } from 'src/api';
import ListingsTable from './listings-table';

interface ProductListingsProps {
  product: AdminProductDetail;
  listings: AdminProductDistributionPlatformListing[];
  onPlatformAdded: () => void;
}

const ProductListings: FunctionComponent<ProductListingsProps> = ({ listings, product, onPlatformAdded }) => {
  const [refreshData, setRefreshData] = useState(false);

  const canManage = useMemo(
    () => currentIssuerHasManageListingsPermission(product.issuer.currentUserPermissions),
    [product.issuer.currentUserPermissions],
  );

  const onListingAdded = useCallback(() => {
    setRefreshData(!refreshData);
    onPlatformAdded();
  }, [refreshData]);

  if (!canManage) return null;

  return (
    <WideContent
      title={<Translate name="dashboard.issuerListings" />}
      ignorePageTitle
      titleSpacing="small"
      headerPanel={
        <SelectDistributionPlatform
          productId={product.id}
          listings={listings}
          productName={product.name}
          onListingAdded={onListingAdded}
        ></SelectDistributionPlatform>
      }
    >
      <ListingsTable productId={product.id} defaultLimit={10} forceDataLoad={refreshData} />
    </WideContent>
  );
};

export default ProductListings;

import React, { FunctionComponent, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import { AdminProductDetail, AdminTokenOverview, ProductStatusEnum, ProductValidationStatusEnum } from 'api';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import Grid, { Col } from 'ui/atoms/grid';
import * as Styled from '../styled';
import CopyButton from 'ui/molecules/copy-button';
import Spacer from 'ui/atoms/spacer';
import Accordion from 'ui/atoms/accordion';
import Button from 'ui/atoms/button';
import ActionButtons from 'ui/molecules/action-buttons';
import EditProduct from 'apps/issuer/pages/products/edit-product';
import DocumentModal from 'libraries/document-modal';
import Currency from 'ui/atoms/currency';
import { currentIssuerHasEditIssuerPermission } from 'apps/issuer/helpers/issuerPermissions';
import { toMoney } from 'ui/helper/money';
import EditIssuanceDocumentModal from '../edit-issuance-document-modal';
import Number from 'src/ui/atoms/number';

export interface ProductAccordionProps {
  data: AdminTokenOverview & AdminProductDetail;
  refreshProductData: () => void;
  hasEditIssuanceDocumentsPermission: boolean;
}

const ProductAccordion: FunctionComponent<ProductAccordionProps> = (props) => {
  const { data, refreshProductData, hasEditIssuanceDocumentsPermission } = props;

  const [isEditProductModalOpen, setEditProductModalOpen] = useState(false);

  const [isUploadIssuanceDocumentOpen, setUploadIssuanceDocumentOpen] = useState(false);

  const [isAccordionExpanded, setAccordionExpanded] = useState(false);

  const translateWithStringArgs = useTranslateWithStringArgs();

  const productData = useMemo(() => {
    return {
      paperContractHash: data.paperContractHash,
      isin: data.isin,
      code: data.code?.toString(),
      calculationFactor: data.calculationFactor?.toString(),
      backupWallet: data.backupWallet,
      lockInTime: data.lockInTime ? data.lockInTime?.toString() : null,
      minUnitPrice: data.nominalAmount && <Currency>{data.nominalAmount}</Currency>,
      agioPercent: data.agioPercent && (
        <>
          <Number minimumFractionDigits={2}>{data.agioPercent}</Number>%
        </>
      ),
      stepSize: data.stepSize && <Currency>{toMoney(data.stepSize)}</Currency>,
      maxUnitsNumber: data.maxIssuableAmount?.toString(),
      paymentDeadline: data.paymentDeadline?.toString(),
      cancellationDeadline: data.cancellationDeadline && data.cancellationDeadline.toString(),
      issuanceDocument: (
        <Translate
          name="common.viewDocument"
          args={[
            (text: string, key) => {
              return (
                <DocumentModal key={key} {...data.issuanceDocument?.document}>
                  {text}
                </DocumentModal>
              );
            },
          ]}
        />
      ),
      issuanceDocumentVersion: data.issuanceDocument?.version,
    };
  }, [JSON.stringify(data)]);

  return (
    <>
      <Accordion
        label={translateWithStringArgs('dashboardIssuerProductDetails.moreInformation')}
        expanded={isAccordionExpanded}
        onChange={() => setAccordionExpanded(!isAccordionExpanded)}
      >
        <Grid>
          <Col width={4}>
            <Translate name={`dashboardIssuerProductDetails.smartContract`} />
            <Spacer y={2} />
          </Col>
          <Styled.Column width={8} spacing="large">
            <Styled.TruncateContractInfo>
              <PlaceholderFallback>{data.smartContractAddress}</PlaceholderFallback>
            </Styled.TruncateContractInfo>
            {data.smartContractAddress && (
              <>
                <Spacer x={1} />
                <CopyButton valueToCopy={data.smartContractAddress} />
              </>
            )}
          </Styled.Column>
        </Grid>
        {Object.entries(productData).map((value: any, key: number) => {
          return (
            <Grid key={key}>
              <Col width={4}>
                <Translate name={`dashboardIssuerProductDetails.${value[0]}`} />
                <Spacer y={2} />
              </Col>
              <Col width={8}>
                <PlaceholderFallback>{value[1]}</PlaceholderFallback>
              </Col>
            </Grid>
          );
        })}
        {data.status === ProductStatusEnum.CREATED &&
          currentIssuerHasEditIssuerPermission(data.issuer.currentUserPermissions) && (
            <ActionButtons>
              <Button variant="secondary" onClick={() => setEditProductModalOpen(true)}>
                <Translate name="common.edit" />
              </Button>
            </ActionButtons>
          )}
        {((data.ewpg === true && data.validationStatus === ProductValidationStatusEnum.VALIDATION_SUCCESS) ||
          data.ewpg === false) &&
          hasEditIssuanceDocumentsPermission &&
          data.publicationValid && (
            <ActionButtons>
              <Button variant="secondary" onClick={() => setUploadIssuanceDocumentOpen(true)}>
                <Translate name="dashboardIssuerProductDetails.editIssuanceDocument" />
              </Button>
            </ActionButtons>
          )}
        {isEditProductModalOpen && (
          <EditProduct
            onClose={() => setEditProductModalOpen(false)}
            data={data}
            onEditProductSuccess={refreshProductData}
          />
        )}
        {isUploadIssuanceDocumentOpen && (
          <EditIssuanceDocumentModal
            onClose={() => setUploadIssuanceDocumentOpen(false)}
            onUploadSuccess={refreshProductData}
            productId={data.id}
          />
        )}
      </Accordion>
    </>
  );
};

export default ProductAccordion;

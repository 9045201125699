import { ThemeColor } from 'ui/styles/types';

export interface ColorVariantsTheme {
  primary: ThemeColor;
  secondary: ThemeColor;
  background: ThemeColor;
  hover: ThemeColor;
  link: ThemeColor;
  danger: ThemeColor;
  info: ThemeColor;
  success: ThemeColor;
  warning: ThemeColor;
  dark: ThemeColor;
  light: ThemeColor;
  marker: ThemeColor;
  cta: ThemeColor;
}

export type ColorVariants = keyof ColorVariantsTheme;

export default {
  link: ({ theme }) => theme.colors.primaryMain,
  success: ({ theme }) => theme.colors.greenMain,
  warning: ({ theme }) => theme.colors.yellowMain,
  danger: ({ theme }) => theme.colors.redMain,
  info: ({ theme }) => theme.colors.grayLight,
  light: ({ theme }) => theme.colors.white,
  dark: ({ theme }) => theme.colors.black,
  primary: ({ theme }) => theme.colors.primaryMain,
  secondary: '#41484D',
  background: '#41484D',
  hover: ({ theme }) => theme.colors.primaryHover,
  marker: ({ theme }) => theme.colors.primaryMain,
  cta: ({ theme }) => theme.colors.primaryMain,
} as ColorVariantsTheme;

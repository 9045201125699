import React, { FunctionComponent, useCallback, useState, useMemo } from 'react';
import { StateValues } from 'react-use-form-state';
import { AdminApi, AdminProductCreationRequest, DocumentsApi } from 'api';
import useApiCall from 'hooks/use-api-call';
import Translate from 'ui/atoms/translate';
import { ActionButton } from 'src/ui/molecules/action-buttons';
import { toMoney } from 'src/ui/helper/money';
import AddProductModal, { AddProductFields } from '../add-product-modal';

interface AddProductProps {
  onAddNewProduct: () => void;
  onClose: () => void;
}

const AddProduct: FunctionComponent<AddProductProps> = ({ onAddNewProduct, onClose }) => {
  const [isInfoView, setInfoView] = useState(true);
  const [isExitingView, setExitingView] = useState(false);

  const { makeAuthenticatedApi, withApi, loading: apiLoading, error } = useApiCall();

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);
  const documentsApi: DocumentsApi = useMemo(() => makeAuthenticatedApi(DocumentsApi), [makeAuthenticatedApi]);

  const onCloseModal = useCallback(() => {
    if (isInfoView) onClose();
    if (!isInfoView && !isExitingView) setExitingView(true);
    if (isExitingView) setExitingView(false);
  }, [isInfoView, isExitingView]);

  const modalHeader = useMemo(() => {
    return isInfoView ? (
      <Translate name="addProduct.info.title" />
    ) : isExitingView ? (
      <Translate name="addProduct.exit.title" />
    ) : (
      <Translate name="addProduct.add" />
    );
  }, [isInfoView, isExitingView]);

  const actionButtons = useMemo((): ActionButton[] => {
    if (isInfoView) {
      return [
        {
          name: 'start',
          content: <Translate name="addProduct.info.start" />,
          size: 'large',
          variant: 'primary',
          onClick: () => onStart(),
        },
      ];
    } else if (isExitingView) {
      return [
        {
          name: 'continue',
          content: <Translate name="addProduct.exit.continue" />,
          size: 'large',
          onClick: () => setExitingView(false),
        },
        {
          name: 'exit',
          content: <Translate name="addProduct.exit.button" />,
          variant: 'primary',
          onClick: () => onClose(),
          size: 'large',
        },
      ];
    } else {
      return [
        {
          name: 'cancel',
          content: <Translate name="common.close" />,
          size: 'large',
          onClick: () => onCloseModal(),
        },
        {
          name: 'send',
          content: <Translate name="addProduct.submit" />,
          variant: 'primary',
          loading: apiLoading,
          type: 'submit',
          size: 'large',
        },
      ];
    }
  }, [isInfoView, isExitingView, apiLoading]);

  const onSubmit = useCallback(
    (fieldsValues: StateValues<AddProductFields>, dateAndTime?: Date) => {
      withApi(async () => {
        const params: AdminProductCreationRequest = {
          ...fieldsValues,
          issuanceDocumentId: '',
          lockInTime: undefined,
          maxIssuableAmount: Number(fieldsValues.maxIssuableAmount),
          stepSize: Number(fieldsValues.stepSize),
          paymentDeadline: Number(fieldsValues.paymentDeadline),
          cancellationDeadline: Number(fieldsValues.cancellationDeadline),
          calculationFactor: Number(fieldsValues.calculationFactor),
          nominalAmount:
            typeof fieldsValues.nominalAmount === 'number' || typeof fieldsValues.nominalAmount === 'string'
              ? toMoney(Number(fieldsValues.nominalAmount))
              : fieldsValues.nominalAmount,
          ewpg: fieldsValues.ewpg === 'true',
        };

        if (dateAndTime) {
          params.lockInTime = dateAndTime;
        }
        if (fieldsValues.isin.length === 0) {
          params.isin = null;
        }
        if (fieldsValues.ewpg === 'true') {
          delete params.backupWallet;
        }
        if (fieldsValues.issuanceDocument) {
          const docResponse = await documentsApi.adminDocumentsCreate({
            file: fieldsValues.issuanceDocument,
          });
          params.issuanceDocumentId = docResponse.id;
        }

        await api.adminTokensCreate({ adminProductCreationRequest: params });
        onAddNewProduct();
      });
    },
    [withApi, api],
  );

  const onStart = useCallback(() => {
    setInfoView(false);
  }, []);

  return (
    <AddProductModal
      isInfoView={isInfoView}
      isExitingView={isExitingView}
      onSubmit={onSubmit}
      onClose={onCloseModal}
      modalHeader={modalHeader}
      actionButtons={actionButtons}
      error={error}
    />
  );
};

export default AddProduct;

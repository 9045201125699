import React, { useCallback, useEffect, useMemo, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import Translate from 'ui/atoms/translate';
import { useApiCall } from 'hooks/use-api-call';
import { AdminApi, AdminProductDetail, AdminProductDistributionPlatformListing, AdminTokenOverview } from 'api';
import LoadingRing from 'ui/atoms/loading-ring';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Section from 'ui/atoms/section';
import ProductOverview from 'apps/issuer/shared/product-overview';
import ProductCampaign from 'apps/issuer/pages/product-details/campaigns';
import ProductInvitation from 'apps/issuer/pages/product-details/invitations';
import Link from 'core/routing/link';
import ProductListings from 'apps/issuer/pages/product-details/listings';
import { currentIssuerHasEditIssuerPermission } from 'apps/issuer/helpers/issuerPermissions';
import { ProductStatusEnum } from 'api/models/ProductStatusEnum';
import SendForValidation from 'apps/issuer/pages/product-details/send-for-validation';
import Hint from 'ui/atoms/hint';
import ActionButtons from 'ui/molecules/action-buttons';
import Spacer from 'ui/atoms/spacer';
import {
  currentUserHasEditIssuanceDocumentPermission,
  currentUserHasValidateCryptoSecuritiesPermission,
} from 'apps/issuer/helpers/userPermissions';
import { useCurrentUserSelector } from 'core/auth/hooks';
import RejectCryptoSecurities from 'apps/issuer/pages/product-details/reject';
import ValidateCryptoSecurities from 'apps/issuer/pages/product-details/validate';
import * as Styled from './styled';

const ProductDetailsPage = (props: { productId: string }) => {
  const { productId } = props;

  const { withApi, makeAuthenticatedApi, loading } = useApiCall(true);

  const [productOverview, setProductOverview] = useState<AdminTokenOverview>();
  const [token, setToken] = useState<AdminProductDetail>();
  const [hasListings, setHasListings] = useState(false);
  const [listings, setListings] = useState<AdminProductDistributionPlatformListing[]>([]);
  const [refreshData, setRefreshData] = useState(false);

  const hasEditPermission = useMemo(
    () => currentIssuerHasEditIssuerPermission(token?.issuer.currentUserPermissions || []),
    [token?.issuer.currentUserPermissions],
  );

  const { currentUser } = useCurrentUserSelector();
  const hasValidatePermission = currentUserHasValidateCryptoSecuritiesPermission(currentUser?.permissions || []);
  const hasEditIssuanceDocumentsPermission = currentUserHasEditIssuanceDocumentPermission(
    currentUser?.permissions || [],
  );

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const getListings = useCallback(() => {
    withApi(async () => {
      const listings = await api.adminListingsList({
        productId,
      });

      setListings(listings.results);
      setHasListings(listings.results.length > 0);
    });
  }, [withApi, api, productId]);

  const loadData = useCallback(() => {
    withApi(async () => {
      if (!productId) {
        return;
      }
      withApi(async () => {
        const productOverview = await api.adminTokensOverviewRetrieve({
          id: productId,
        });

        setProductOverview(productOverview);

        const token = await api.adminTokensRetrieve({
          id: productId,
        });

        setToken(token);

        getListings();
      });
    });
  }, [withApi, api, productId]);

  useEffect(() => {
    loadData();
  }, [loadData, refreshData]);

  const refreshProductData = useCallback(() => {
    setRefreshData(true);
    setRefreshData(false);
  }, []);

  if (loading || !productOverview || !token) {
    return <LoadingRing />;
  }
  return (
    <>
      <Section spacing="large">
        <WideContent
          title={
            <Styled.TitleContainer>
              <Translate name="issuerProductDetails.title" args={[productOverview.productName]} />
              {token.ewpg && token.publicationValid === false && (
                <>
                  <Spacer x={4} />
                  <Styled.PublicationStatusTag variant="danger" truncate={true}>
                    <Translate name="issuerProductDetails.publicationInvalid" />
                  </Styled.PublicationStatusTag>
                </>
              )}
            </Styled.TitleContainer>
          }
        >
          {hasEditPermission && token.status === ProductStatusEnum.CREATED && (
            <>
              <ActionButtons>
                <SendForValidation
                  productId={token.id}
                  onUpdateProductStatus={(token) => setToken(token)}
                ></SendForValidation>
              </ActionButtons>
              <Spacer y={4} />
            </>
          )}
          {hasValidatePermission && token.status === ProductStatusEnum.VALIDATION_PENDING && (
            <>
              <ActionButtons align="end">
                <RejectCryptoSecurities productId={token.id} onUpdateProductStatus={(token) => setToken(token)} />
                <ValidateCryptoSecurities productId={token.id} onUpdateProductStatus={(token) => setToken(token)} />
              </ActionButtons>
              <Spacer y={4} />
            </>
          )}
          {token.status === ProductStatusEnum.VALIDATION_PENDING && (
            <Hint variant="warning">
              <Translate name="issuerProductDetails.cryptoSecuritiesValidation.pending" />
            </Hint>
          )}
          {token.status === ProductStatusEnum.CREATED &&
            token.validationRejectionReason &&
            token.validationRejectionReason.length > 0 && (
              <Hint variant="warning">
                <Translate
                  name="issuerProductDetails.cryptoSecuritiesValidation.rejected"
                  args={[token.validationRejectionReason]}
                />
              </Hint>
            )}
          <ProductOverview
            data={{ ...productOverview, ...token }}
            issuer={token.issuer}
            refreshProductData={refreshProductData}
            hasEditIssuanceDocumentsPermission={hasEditIssuanceDocumentsPermission}
          />
        </WideContent>
      </Section>
      <Section spacing="large">
        <ProductListings product={token} listings={listings} onPlatformAdded={getListings} />
      </Section>
      <Section spacing="large">
        <ProductCampaign productId={productId} product={token} hasListings={hasListings} />
      </Section>
      <Section spacing="medium">
        <ProductInvitation productId={productId} product={token} hasListings={hasListings} />
      </Section>
      <Section spacing="medium">
        <WideContent>
          <Link icon="arrow-left" to="/dashboard/products">
            <Translate as="span" name="issuerProductDetails.backToProducts" />
          </Link>
        </WideContent>
      </Section>
    </>
  );
};

export default WithAuthenticatedPage(ProductDetailsPage, AppType.ISSUER);

import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import * as Styled from './styled';

export enum TooltipPosition {
  RIGHT = 'right',
  BOTTOM = 'bottom',
}

export interface TooltipProps {
  content: ReactNode;
  maxWidth?: number;
  position?: TooltipPosition;
}

export const Tooltip: FunctionComponent<TooltipProps> = (props) => {
  const { content, maxWidth, position = TooltipPosition.BOTTOM, children } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const updatePosition = () => {
    if (!triggerRef.current) return;

    const rect = triggerRef.current.getBoundingClientRect();

    if (position === TooltipPosition.RIGHT) {
      setTooltipPosition({
        top: rect.top + rect.height / 2,
        left: rect.right + 8,
      });
    } else {
      setTooltipPosition({
        top: rect.bottom + 8,
        left: rect.left + rect.width / 2,
      });
    }
  };

  useEffect(() => {
    if (showTooltip) {
      updatePosition();
      window.addEventListener('scroll', updatePosition);
      window.addEventListener('resize', updatePosition);
    }

    return () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, [showTooltip, position]);

  return (
    <Styled.Tooltip
      ref={triggerRef}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip &&
        createPortal(
          <Styled.Content
            $maxWidth={maxWidth}
            $position={position}
            style={{
              top: `${tooltipPosition.top}px`,
              left: `${tooltipPosition.left}px`,
            }}
          >
            {content}
          </Styled.Content>,
          document.body,
        )}
    </Styled.Tooltip>
  );
};

export default Tooltip;

import styled from 'styled-components';
import { TooltipPosition } from '.';

export interface ContentProps {
  $maxWidth?: number;
  $position?: TooltipPosition;
}

export const Content = styled.div<ContentProps>`
  position: fixed;
  transform: ${({ $position }) => ($position === TooltipPosition.RIGHT ? 'translate(0, -50%)' : 'translate(-50%, 0)')};
  color: ${({ theme }) => theme.colors.grayLight};
  background-color: ${({ theme }) => theme.colors.black};
  width: max-content;
  max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : '400px')};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  z-index: 10000;
  font-size: 14px;

  &::before {
    content: '';
    position: absolute;
    z-index: 10000;
    ${({ $position, theme }) =>
      $position === TooltipPosition.RIGHT
        ? `
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      border-color: transparent ${theme.colors.black} transparent transparent;
    `
        : `
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-color: transparent transparent ${theme.colors.black} transparent;
    `}
    border-width: 5px;
    border-style: solid;
  }
`;

export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
`;

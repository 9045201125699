import { HorizontalAlign, VerticalAlign } from 'src/ui/types/align';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import Size from 'src/ui/types/size';
import { query } from 'ui/styles/queries';
import { resolveColor } from 'ui/styles/utils/colors';

interface TableProps {
  $borderless?: boolean;
  $size?: Size;
  $compact?: boolean;
}

interface TableHeadCellProps {
  $alignContent?: HorizontalAlign;
  $pointer?: boolean;
}

interface TablePartProps {
  $alignContent?: HorizontalAlign;
  $borderless?: boolean;
}

interface TableRowProps {
  $clickable: boolean;
  $borderless: boolean;
  $disabled: boolean;
}

interface TableCellProps {
  $alignContent?: HorizontalAlign;
  $verticalAlignContent?: VerticalAlign;
  $compact?: boolean;
}

function getVerticalAlignContent({ $verticalAlignContent }: ThemedStyledProps<TableCellProps, DefaultTheme>) {
  return css<TableCellProps>`
    vertical-align: ${$verticalAlignContent ? `${$verticalAlignContent}` : 'middle'};
  `;
}

function getAlignContent({ $alignContent }: ThemedStyledProps<TableCellProps, DefaultTheme>) {
  if ($alignContent === 'right') {
    return css`
      text-align: right;
      justify-content: flex-end;
    `;
  }

  if ($alignContent === 'center') {
    return css`
      text-align: center;
      justify-content: center;
    `;
  }
}

export const TableHead = styled.thead`
  border: ${({ theme }) => theme.tables.tableBorder};
  color: ${({ theme }) => theme.tables.headTextColor};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75rem;
`;

export const TableHeadCell = styled.th<TableHeadCellProps>`
  ${getAlignContent};
  height: 3rem;
  padding: 0rem 0.5rem;
  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.tables.borderRadius};
  }
  &:last-child {
    border-top-right-radius: ${({ theme }) => theme.tables.borderRadius};
  }
  ${({ $pointer }) => ($pointer ? 'cursor: pointer;' : '')};
`;

export const TableHeadCellContent = styled.tbody<TablePartProps>`
  display: flex;
  align-items: center;

  ${query.phone`
    font-size: 11px;
  `};

  ${getAlignContent}
`;

export const TableRow = styled.tr<TableRowProps>`
  ${({ $clickable }) => ($clickable ? 'cursor: pointer;' : '')};
  ${({ $disabled, theme }) => ($disabled ? `background-color: ${resolveColor(theme, 'grayLight')}` : '')};
  ${({ $borderless }) =>
    $borderless &&
    css`
      &:last-child > ${TableCell} {
        padding-bottom: 0rem;
      }
    `};
`;

export const TableCell = styled.td<TableCellProps>`
  ${getAlignContent}
  ${getVerticalAlignContent}
  color: ${({ theme }) => theme.colors.black};
  border-top: ${({ theme }) => theme.tables.tableBorder};
  height: 3.8rem;
  padding: 0rem 0.5rem;

  ${({ $compact }) =>
    $compact &&
    css`
      padding: ${({ theme }) => theme.tables.cellPadding};
    `};
`;

export const TableFoot = styled.tfoot<TablePartProps>`
  ${({ $borderless }) =>
    $borderless &&
    css`
      && {
        border-top: 1px solid #e3dede;
        font-weight: bolder;

        ${TableCell} {
          padding-top: 0rem;
        }
      }
    `};
`;

export const Table = styled.table<TableProps>`
  width: calc(100% - 0.4rem);
  margin-bottom: 1rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06),
    0px 4px 5px 0px rgba(0, 0, 0, 0.07);
  border: ${({ theme }) => theme.tables.tableBorder};
  border-radius: ${({ theme }) => theme.tables.borderRadius};
  font-size: 0.875rem;
  border-collapse: separate;
  border-spacing: 0px;

  ${query.phone`
    font-size: 0.75rem;
  `};

  ${({ $borderless }) =>
    $borderless &&
    css`
      &,
      & * {
        border-collapse: collapse;
        border: none;
        box-shadow: none;
      }
    `};

  ${({ $size }) => {
    if ($size === 'small')
      return css`
        font-size: 0.875rem;
      `;
    if ($size === 'large')
      return css`
        font-size: 1.25rem;
      `;
  }};
`;

import { AdminApi } from 'api';
import useApiCall from 'hooks/use-api-call';
import React, { FunctionComponent, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import { Modal, makeModalForm, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons } from 'ui/molecules/modal';
import { StateValues } from 'react-use-form-state';

export interface EditIssuerConfigurationFields {
  id: string;
  canCreateCryptoSecurities: string;
  automaticValidationEnabled: string;
}

export interface EditConfigurationModalProps {
  onHideEditConfiguration: () => void;
  fetchIssuerDetails: () => void;
  showSuccessModal: () => void;
  values: EditIssuerConfigurationFields;
}

const EditIssuerConfigurationForm = makeModalForm<EditIssuerConfigurationFields>();

const EditConfigurationModal: FunctionComponent<EditConfigurationModalProps> = (props) => {
  const { fetchIssuerDetails, onHideEditConfiguration, showSuccessModal = () => {}, values } = props;

  const [issuerData, setIssuerData] = useState<EditIssuerConfigurationFields>(values);

  const { makeAuthenticatedApi, withApi, loading } = useApiCall();

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const editConfigurationData = (fieldsValues: StateValues<EditIssuerConfigurationFields>) => {
    withApi(async () => {
      const patchBody = {
        id: values.id,
        patchedAdminIssuerUpdateRequest: {
          canCreateCryptoSecurities: fieldsValues.canCreateCryptoSecurities === 'true',
          autoValidation:
            fieldsValues.canCreateCryptoSecurities === 'false'
              ? false
              : fieldsValues.automaticValidationEnabled === 'true',
        },
      };

      await adminApi.adminIssuersPartialUpdate(patchBody);

      showSuccessModal();
      fetchIssuerDetails();
      onHideEditConfiguration();
    });
  };

  return (
    <>
      <Modal onClose={onHideEditConfiguration}>
        <EditIssuerConfigurationForm
          initial={{
            canCreateCryptoSecurities: issuerData.canCreateCryptoSecurities,
            automaticValidationEnabled: issuerData.automaticValidationEnabled,
          }}
          onChange={(values: StateValues<EditIssuerConfigurationFields>) => {
            setIssuerData(values);
            console.info(values);
          }}
          onSubmit={(values: StateValues<EditIssuerConfigurationFields>) => {
            return editConfigurationData(values);
          }}
          i18nKey="dashboardIssuerInvestorDetails.createConfigurationForm"
        >
          <ModalHeader>
            <Translate name="dashboardIssuerInvestorDetails.createConfigurationForm.title" />
          </ModalHeader>
          <ModalContent>
            <EditIssuerConfigurationForm.ValueProvider>
              {(values) => {
                return (
                  <>
                    <EditIssuerConfigurationForm.Group name="canCreateCryptoSecurities" required={true}>
                      <EditIssuerConfigurationForm.GroupToggle>
                        {['true', 'false'].map((option) => (
                          <EditIssuerConfigurationForm.Radio value={option}>
                            <Translate name={`commonOptions.${option}`} />
                          </EditIssuerConfigurationForm.Radio>
                        ))}
                      </EditIssuerConfigurationForm.GroupToggle>
                    </EditIssuerConfigurationForm.Group>
                    <EditIssuerConfigurationForm.Group name="automaticValidationEnabled" required={true}>
                      <EditIssuerConfigurationForm.GroupToggle>
                        {['true', 'false'].map((option) => (
                          <EditIssuerConfigurationForm.Radio
                            value={option}
                            disabled={values.canCreateCryptoSecurities === 'false'}
                          >
                            <Translate name={`commonOptions.${option}`} />
                          </EditIssuerConfigurationForm.Radio>
                        ))}
                      </EditIssuerConfigurationForm.GroupToggle>
                    </EditIssuerConfigurationForm.Group>
                  </>
                );
              }}
            </EditIssuerConfigurationForm.ValueProvider>
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              actionButtons={[
                {
                  name: 'cancel',
                  content: <Translate name="common.close" />,
                  size: 'large',
                  onClick: onHideEditConfiguration,
                },
                {
                  name: 'send',
                  content: <Translate name="common.submit" />,
                  variant: 'primary',
                  size: 'large',
                  type: 'submit',
                  loading,
                },
              ]}
            />
          </ModalFooter>
        </EditIssuerConfigurationForm>
      </Modal>
    </>
  );
};

export default EditConfigurationModal;

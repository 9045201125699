import React, { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import LoadingRing from 'ui/atoms/loading-ring';
import useApiCall from 'hooks/use-api-call';
import { InvitationsApi } from 'api/apis';
import { PersonType } from 'ui/types/person';
import useInvestorMe from 'hooks/use-investor-me';
import WizardContext from 'libraries/wizard/wizard-context';
import { InvitationToNonPreviewInvitation, PersonToLegalPerson, PersonToNaturalPerson } from 'helper/cast';
import InvestmentCommitment from 'subapps/investment/pages/investment/wizard-steps/offer/investment-commitment';
import { convertProductTypeFromApi } from 'core/api/conversions';
import useInterval from 'ui/hooks/use-interval';
import { removeInvitationIdLocal } from 'subapps/investment/helpers';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';
import Translate from 'ui/atoms/translate';

const OfferStep: FunctionComponent<{}> = () => {
  const {
    resourceId: invitationId,
    invitation,
    issuer,
    token,
    loading,
    finalize,
    reload,
    error,
  } = useContext(WizardContext);

  const { investor: me, error: meError } = useInvestorMe();

  const { error: apiError, loading: apiLoading, makeAuthenticatedApi, withApi } = useApiCall();

  // TODO(geforcefan): casting to investment invitation, we should be generic here, not supporting others for now
  const investmentInvitation = InvitationToNonPreviewInvitation(invitation);

  // reload data in interval when costs checkbox should be shown but document is not generated yet
  useInterval({
    onInterval: reload,
    shouldStart: !!(investmentInvitation?.showCostsCheckbox && !investmentInvitation?.costsDocument),
    shouldClear: !!(investmentInvitation?.showCostsCheckbox && investmentInvitation?.costsDocument),
  });

  const invitationsApi: InvitationsApi = useMemo(() => makeAuthenticatedApi(InvitationsApi), [makeAuthenticatedApi]);

  const onSubmit = useCallback(() => {
    // some sanity checks
    withApi(async () => {
      await invitationsApi.invitationsOfferCreate({
        id: invitationId,
      });
      // after having completed a commitment, investors should not be redirected into that process
      // when clickinga again on "invest now" for that campaign
      // instead, they should start a new process (CP-836)
      removeInvitationIdLocal(invitationId);
      finalize();
    });
  }, [invitationsApi, invitationId, withApi, finalize]);

  const personType =
    (PersonToNaturalPerson(me?.person) && PersonType.Natural) ||
    (PersonToLegalPerson(me?.person) && PersonType.Legal) ||
    undefined;

  // todo(geforcefan): we should think about error handling
  //  for our whole investment process, ux team will think about a solution
  if (error || meError || !personType) return null;

  if (
    !investmentInvitation ||
    !issuer ||
    !token ||
    // TODO(mara-cashlink): add error handling
    !investmentInvitation.disagio ||
    !investmentInvitation.investmentTotal ||
    !investmentInvitation.paymentTotal
  )
    return <LoadingRing />;

  // TODO(geforcefan): why is shareCalculation?.exitProceedsPercent still a string?
  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        <Translate name="investmentCommitment.title" />
      </Header>
      <InvestmentCommitment
        personType={personType}
        onSubmit={onSubmit}
        tokenPrice={investmentInvitation.pricePerToken}
        customCheckboxes={investmentInvitation.customCheckboxes}
        loading={loading || apiLoading}
        error={apiError}
        useUnits={token.useUnits}
        showAccruedInterest={token.calculateAccruedInterest}
        costs={{
          show: investmentInvitation?.showCostsCheckbox,
          document: investmentInvitation?.costsDocument,
        }}
        accruedInterest={investmentInvitation.accruedInterest}
        productName={investmentInvitation.productName}
        productType={convertProductTypeFromApi(token.productType)}
        disagio={investmentInvitation.disagio}
        agio={investmentInvitation.agio}
        investmentTotal={investmentInvitation.investmentTotal || undefined}
        paymentTotal={investmentInvitation.paymentTotal || undefined}
        isEcsp={investmentInvitation.isEcsp}
      />
    </>
  );
};

export default OfferStep;

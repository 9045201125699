import React, { FunctionComponent, useContext } from 'react';
import Button from 'ui/atoms/button';
import { convertWalletTypeFromApi } from 'core/api/conversions';
import { WalletType } from 'ui/types/wallet';
import { getWalletAddressForNetwork } from 'helper/network';
import Translate from 'ui/atoms/translate';
import ActionButtons from 'ui/molecules/action-buttons';
import LoadingRing from 'ui/atoms/loading-ring';
import WalletStepSuccess from 'subapps/investment/pages/investment/wizard-steps/wallet/wallet-step-done/wallet-step-success';
import WizardContext from 'libraries/wizard/wizard-context';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';

const WalletStepDone: FunctionComponent<{}> = () => {
  const { token, investment, loading, finalize } = useContext(WizardContext);

  const title = <Translate name="investmentDepot.title" />;

  const walletType: WalletType | undefined = investment?.wallet
    ? convertWalletTypeFromApi(investment.wallet.type)
    : undefined;

  if (!investment || loading || !walletType || !token) {
    return <LoadingRing />;
  }

  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        {title}
      </Header>
      <WalletStepSuccess
        walletType={walletType}
        walletAddress={
          investment.wallet &&
          token.tokenizationInfo &&
          getWalletAddressForNetwork(token.tokenizationInfo.networkType, investment.wallet)
        }
      />
      <ActionButtons>
        <Button
          fluid={true}
          size="large"
          variant="primary"
          onClick={() => {
            finalize();
          }}
        >
          <Translate name="common.continue" />
        </Button>
      </ActionButtons>
    </>
  );
};

export default WalletStepDone;

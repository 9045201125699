import { useContext, useMemo } from 'react';
import WizardContext from 'libraries/wizard/wizard-context';

const useProcessOverview = (): {
  overviewSteps: string[];
} => {
  //contexts
  const { token } = useContext(WizardContext);

  //constants
  const wizardOverviewSteps = {
    accountData: 'accountData',
    investmentAmount: 'investmentAmount',
    investorExperience: 'investorExperience',
    commitment: 'commitment',
    identityCheck: 'identityCheck',
    payment: 'payment',
    saveDepositAccountConnection: 'saveDepositAccountConnection',
    finishInvestmentBankRequired: 'finishInvestmentBankRequired',
    finishInvestmentBankAndTaxRequired: 'finishInvestmentBankAndTaxRequired',
  };

  const steps = useMemo(() => {
    return Object.values(wizardOverviewSteps).filter(
      (x) =>
        (!!token?.tokenizationInfo || x !== wizardOverviewSteps.saveDepositAccountConnection) &&
        ((!!token?.bankAccountRequired && !token?.taxInformationRequired) ||
          x !== wizardOverviewSteps.finishInvestmentBankRequired) &&
        ((!!token?.bankAccountRequired && !!token?.taxInformationRequired) ||
          x !== wizardOverviewSteps.finishInvestmentBankAndTaxRequired),
    );
  }, [JSON.stringify(token?.tokenizationInfo)]);

  return { overviewSteps: steps };
};

export default useProcessOverview;

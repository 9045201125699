import React, { FunctionComponent, ReactNode } from 'react';
import DataRecordTable from 'src/libraries/data-record-table';
import Translate from 'ui/atoms/translate';
import { AdminApi, AdminProductDistributionPlatformListing } from 'api';
import WithDataRecord from 'src/hoc/WithDataRecord';
import Section from 'src/ui/atoms/section';
import { PaginationProps } from 'ui/molecules/pagination';

export interface ListingsTableProps {
  productId: string;
  title?: ReactNode;
  emptyView?: ReactNode;
  loading?: boolean;
  paginationProps?: PaginationProps;
  defaultLimit?: number;
  forceDataLoad?: boolean;
}

const ListingsTable: FunctionComponent<ListingsTableProps> = WithDataRecord<
  AdminApi,
  AdminProductDistributionPlatformListing
>(AdminApi)(
  ({ data, title, emptyView, loading, paginationProps }) => {
    return (
      <>
        <Section spacing="medium">{!data?.length && <Translate name="listings.noPlatforms" />}</Section>
        <DataRecordTable
          data={data as AdminProductDistributionPlatformListing[]}
          title={title}
          loading={loading}
          paginationProps={paginationProps}
          emptyView={emptyView}
          cells={[
            {
              title: <Translate name={`listings.distributionPlatform`} />,
              render: (platform) => platform.distributionPlatformPlatformName,
            },
          ]}
        />
      </>
    );
  },
  (api, props, offset, limit) => {
    return api.adminListingsList({
      offset,
      limit,
      ...props,
    });
  },
);

export default ListingsTable;

import { useMemo } from 'react';
import useTranslate from 'src/ui/hooks/use-translate';
import { CurrencyEnum } from 'src/api';

const useCurrencies = (currencies: CurrencyEnum[] | string[] = Object.values(CurrencyEnum)) => {
  const translate = useTranslate();

  const sorted = useMemo(() => {
    return currencies
      .filter((currency) => {
        return !!translate(`currencies.${currency}`)?.length;
      })
      .map((currency) => ({
        value: currency,
        label: translate(`currencies.${currency}`),
      }));
  }, [translate, JSON.stringify(currencies)]);

  return sorted;
};

export default useCurrencies;

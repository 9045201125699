import { AdminIssuerDetailPermissionsEnum } from 'api';

export const currentIssuerHasPermission = (
  permissions: AdminIssuerDetailPermissionsEnum[],
  permission: AdminIssuerDetailPermissionsEnum,
) => !!permissions.find((perm) => perm === permission);

export const currentIssuerHasEditIssuerPermission = (permissions: AdminIssuerDetailPermissionsEnum[]) =>
  currentIssuerHasPermission(permissions, AdminIssuerDetailPermissionsEnum.MANAGE_ISSUER_CASHLINK_STUDIO);

export const currentIssuerHasManageListingsPermission = (permissions: AdminIssuerDetailPermissionsEnum[]) =>
  currentIssuerHasPermission(permissions, AdminIssuerDetailPermissionsEnum.MANAGE_LISTINGS);

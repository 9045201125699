import useRoutesResolver from 'core/routing/hooks/use-routes-resolver';
import { ISSUER_ROUTES } from './routes.config';
import type { DistributionPlatform, Feature, Product, UserPermissionsEnum } from 'api';

export function useIssuerNavigationItems({
  tokens,
  hasIssuersToView,
  configFeatures,
  distributionPlatforms,
  userPermissions,
  canCreateProducts,
}: {
  tokens: Product[];
  hasIssuersToView: boolean;
  configFeatures: Feature;
  distributionPlatforms: DistributionPlatform[];
  userPermissions: UserPermissionsEnum[];
  canCreateProducts: boolean;
}) {
  return useRoutesResolver(ISSUER_ROUTES, {
    tokens,
    hasIssuersToView,
    displayAllRoutes: true,
    configFeatures,
    distributionPlatforms,
    userPermissions,
    canCreateProducts,
  });
}

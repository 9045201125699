import React, { FunctionComponent } from 'react';
import Spacer from 'src/ui/atoms/spacer';
import Translate from 'src/ui/atoms/translate';
import Text from 'src/ui/polymorphs/text';
import Segment from 'src/ui/atoms/segment';
import * as Styled from './styled';

const AddProductInfo: FunctionComponent = () => {
  return (
    <>
      <Translate name="addProduct.info.subtitle" />
      <Spacer y={6} />
      <Styled.ProductInfo>
        <div key={1}>
          <strong>1</strong>
          <Spacer x={4} inline />
          <strong>
            <Translate name={`addProduct.info.steps.name.title`} />{' '}
          </strong>
          <Translate name={`addProduct.info.steps.name.description`} />
          <Spacer y={2} />
        </div>
        <div key={2}>
          <strong>2</strong>
          <Spacer x={4} inline />
          <strong>
            <Translate name={`addProduct.info.steps.isin.title`} />{' '}
          </strong>
          <Translate name={`addProduct.info.steps.isin.description`} />
          <Spacer y={2} />
        </div>
        <div key={3}>
          <strong>3</strong>
          <Spacer x={4} inline />
          <strong>
            <Translate name={`addProduct.info.steps.asset.title`} />{' '}
          </strong>
          <Spacer y={2} />
        </div>
        <div key={4}>
          <strong>4</strong>
          <Spacer x={4} inline />
          <Translate name={`addProduct.info.steps.investment.title`} />{' '}
          <strong>
            <Translate name={`addProduct.info.steps.investment.description`} />
          </strong>
          <Spacer y={2} />
        </div>
        <div key={5}>
          <strong>5</strong>
          <Spacer x={4} inline />
          <strong>
            <Translate name={`addProduct.info.steps.terms.title`} />{' '}
          </strong>
          <Spacer y={2} />
        </div>
      </Styled.ProductInfo>
      <Segment spacing="none" padded="none" basic={true} textAlign="center">
        <Text fontSize={14}>
          <Translate name="addProduct.info.interrupt" />
        </Text>
      </Segment>
    </>
  );
};

export default AddProductInfo;

import type { IssuerAppState } from 'store/types';
import { rootStateSelector } from 'store/selectors';

export function issuerAppRootSelector(state: IssuerAppState) {
  return rootStateSelector(state).issuer;
}

export function issuerSelector(state: IssuerAppState) {
  const { tokens, hasIssuersToView, loading, error, isInitial } = issuerAppRootSelector(state);

  return { tokens, hasIssuersToView, loading, error, isInitial };
}

import React, { FunctionComponent, useCallback, useEffect, useState, useMemo, ReactNode } from 'react';
import { StateValues } from 'react-use-form-state';
import {
  AdminApi,
  AdminIssuerList,
  AdminProductDetail,
  AdminTokenOverview,
  Money,
  NetworkNameTechnicalEnum,
  ProductTypeEnum,
} from 'api';
import useApiCall from 'hooks/use-api-call';
import Translate from 'ui/atoms/translate';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons, makeModalForm } from 'ui/molecules/modal';
import * as Styled from './styled';
import Spacer from 'src/ui/atoms/spacer';
import Icon from 'src/ui/atoms/icon';
import Tooltip, { TooltipPosition } from 'src/ui/atoms/tooltip';
import DatePicker from 'src/ui/atoms/date-picker';
import SelectAtom from 'src/ui/atoms/select';
import Grid, { Col } from 'src/ui/atoms/grid';
import useTranslate, { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import { ActionButton } from 'src/ui/molecules/action-buttons';
import { handleError } from 'src/ui/helper/error-handling';
import AddProductInfo from './add-product-info';
import AddProductExit from './add-product-exit';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';
import ServerError from 'ui/types/server-error';
import { toNumber } from 'src/ui/helper/money';

const isStellarNetwork = (network?: NetworkNameTechnicalEnum): boolean => {
  if (!network) return false;
  return network.includes('stellar');
};

interface AddProductModalProps {
  onSubmit: (fieldsValues: StateValues<AddProductFields>, dateAndTime?: Date) => void;
  onClose: () => void;
  isInfoView: boolean;
  isExitingView: boolean;
  modalHeader: ReactNode;
  actionButtons: ActionButton[];
  error?: ServerError;
  data?: AdminTokenOverview & AdminProductDetail;
}

export interface AddProductFields {
  issuerId: string;
  name: string;
  productType: ProductTypeEnum;
  ewpg: string;
  isin: string;
  network: NetworkNameTechnicalEnum;
  code: string;
  calculationFactor: string | null;
  backupWallet: string;
  nominalAmount: number | Money;
  agioPercent: string;
  stepSize: number;
  maxIssuableAmount: number;
  paymentDeadline: number;
  cancellationDeadline: number;
  issuanceDocument: Blob | null;
  lockInTime?: Date | null;
}

export const Form = makeModalForm<AddProductFields>();

const AddProductModal: FunctionComponent<AddProductModalProps> = (props) => {
  const { isExitingView, isInfoView, onClose, onSubmit, modalHeader, actionButtons, error, data } = props;
  const [issuers, setIssuers] = useState<AdminIssuerList[]>([]);
  const [time] = useState(['now', 'midnight', 'morning', 'noon', 'evening']);
  const [dateValue, setDateValue] = useState<string>('');
  const [timeValue, setTimeValue] = useState<string>('');
  const [dateAndTime, setDateAndTime] = useState<Date | undefined>(undefined);
  const [isEwpg, setIsEwpg] = useState(data?.ewpg);
  const [selectedNetwork, setSelectedNetwork] = useState<NetworkNameTechnicalEnum | undefined>(
    data?.network || undefined
  );

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const translate = useTranslateWithStringArgs();
  const isPhone = useIsMedia(DEVICES.phone);

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const loadIssuers = useCallback(() => {
    withApi(async () => {
      const issuerList = await api.adminIssuersList({ canCreateProduct: true, limit: 100 });
      setIssuers(issuerList.results);
    });
  }, [withApi, api, setIssuers]);

  useEffect(() => {
    loadIssuers();
  }, [loadIssuers]);

  useEffect(() => {
    if (dateValue.length === 0) return;

    let newDate = new Date(dateValue);
    const currentTime = new Date();

    switch (timeValue) {
      case 'now':
        newDate.setHours(currentTime.getHours(), 0, 0, 0);
        break;
      case 'midnight':
        newDate.setHours(0, 0, 0, 0);
        break;
      case 'morning':
        newDate.setHours(6, 0, 0, 0);
        break;
      case 'noon':
        newDate.setHours(12, 0, 0, 0);
        break;
      case 'evening':
        newDate.setHours(18, 0, 0, 0);
        break;
      default:
        newDate.setHours(currentTime.getHours(), 0, 0, 0);
    }

    setDateAndTime(newDate);
  }, [dateValue, timeValue]);

  const productTypeOptions = useMemo(
    () =>
      Object.values(ProductTypeEnum).map((value) => ({
        value,
        label: <Translate name={`productType.${snakeToCamelCaseWithNumbers(value.toLowerCase())}`} />,
      })),
    [],
  );

  const networkTypeOptions = useMemo(
    () =>
      Object.entries(NetworkNameTechnicalEnum).map(([key, value]) => ({
        value,
        label: <Translate name={`addProduct.networkType.${key}`} />,
      })),
    [],
  );

  const timeOptions = time.map((value) => ({
    value,
    label: <Translate name={`addProduct.time.${value}`} />,
  }));

  const { getErrorForField: getNetworkError } = handleError({
    error,
    translate: useTranslate(),
    specialCodes: {
      invalid: {
        field: 'network',
        translateKey: 'addProduct.fields.network.error',
      },
    },
  });

  const { getErrorForField: getIssuerError } = handleError({
    error,
    translate: useTranslate(),
    specialCodes: {
      invalid: {
        field: 'issuer_id',
        translateKey: 'addProduct.fields.issuerId.error',
      },
    },
  });

  const { getErrorForField: getDateError } = handleError({
    error,
    translate: useTranslate(),
    specialCodes: {
      invalid: {
        field: 'lock_in_time',
        translateKey: 'addProduct.fields.lockInTime.error',
      },
    },
  });

  const isEditView = !!data;

  return (
    <Modal onClose={onClose}>
      <Form
        i18nKey="addProduct"
        onSubmit={(values: StateValues<AddProductFields>) => {
          onSubmit(values, dateAndTime);
        }}
        onChange={(values: StateValues<AddProductFields>) => {
          setIsEwpg(values.ewpg === 'true');
          setSelectedNetwork(values.network);
        }}
        error={error}
        initial={
          data
            ? {
                issuerId: data.issuer?.id,
                name: data.name,
                productType: data.productType,
                ewpg: data.ewpg?.toString(),
                isin: data.isin || undefined,
                network: data.network || NetworkNameTechnicalEnum.ETH_DEV,
                code: data.code,
                calculationFactor: data.calculationFactor,
                backupWallet: data.backupWallet,
                nominalAmount: toNumber(data.nominalAmount),
                agioPercent: data.agioPercent,
                stepSize: data.stepSize,
                maxIssuableAmount: data.maxIssuableAmount,
                paymentDeadline: data.paymentDeadline,
                cancellationDeadline: data.cancellationDeadline,
                lockInTime: data.lockInTime,
              }
            : {
                ewpg: 'false',
              }
        }
      >
        <ModalHeader>{modalHeader}</ModalHeader>
        <ModalContent>
          {isInfoView && <AddProductInfo />}
          {isExitingView && <AddProductExit />}
          {!isInfoView && !isExitingView && (
            <Form.ValueProvider>
              {(values) => (
                <>
                  <h4>
                    <Translate name="addProduct.issuer" />
                  </h4>
                  <Spacer y={2} />
                  <Form.Group name="issuerId" fieldError={getIssuerError('issuer_id')} required={true}>
                    <Styled.SameRowNoTooltip>
                      <Form.Select
                        options={issuers.map(({ id, companyName }) => ({
                          value: id,
                          label: companyName,
                        }))}
                        disabled={isEditView}
                        portalTarget={document.body}
                      ></Form.Select>
                    </Styled.SameRowNoTooltip>
                  </Form.Group>
                  <Spacer y={2} />
                  <h4>
                    <Translate name="addProduct.nameAndType" />
                  </h4>
                  <Spacer y={2} />
                  <Form.Group name="name" required={true}>
                    <Styled.SameRowWithTooltip>
                      <Form.Input />
                      <Tooltip
                        content={<Translate name="addProduct.fields.name.tooltip" />}
                        position={isPhone ? TooltipPosition.BOTTOM : TooltipPosition.RIGHT}
                        maxWidth={200}
                      >
                        <Icon name="question" circular={true} size="large" color="info" />
                      </Tooltip>
                    </Styled.SameRowWithTooltip>
                  </Form.Group>
                  <Form.Group name="productType" required={true}>
                    <Styled.SameRowWithTooltip>
                      <Form.Select options={productTypeOptions} portalTarget={document.body}></Form.Select>
                      <Tooltip
                        content={<Translate name="addProduct.fields.productType.tooltip" />}
                        position={isPhone ? TooltipPosition.BOTTOM : TooltipPosition.RIGHT}
                        maxWidth={200}
                      >
                        <Icon name="question" circular={true} size="large" color="info" />
                      </Tooltip>
                    </Styled.SameRowWithTooltip>
                  </Form.Group>
                  <Form.Group name="ewpg" spacing="none" required={true}>
                    <Form.GroupToggle>
                      <Form.Radio value="true" key="true" checked={false} disabled={isEditView}>
                        <Translate name="commonOptions.true" />
                      </Form.Radio>
                      <Form.Radio value="false" key="false" checked={true} disabled={isEditView}>
                        <Translate name="commonOptions.false" />
                      </Form.Radio>
                    </Form.GroupToggle>
                  </Form.Group>
                  <Form.Group name="isin" required={isEwpg}>
                    <Styled.SameRowWithTooltip>
                      <Form.Input />
                      <Tooltip
                        content={<Translate name="addProduct.fields.isin.tooltip" />}
                        position={isPhone ? TooltipPosition.BOTTOM : TooltipPosition.RIGHT}
                        maxWidth={200}
                      >
                        <Icon name="question" circular={true} size="large" color="info" />
                      </Tooltip>
                    </Styled.SameRowWithTooltip>
                  </Form.Group>
                  <Spacer y={6} />
                  <h4>
                    <Translate name="addProduct.tokenizationInformation" />
                  </h4>
                  <Spacer y={2} />
                  <Form.Group name="network" required={true} fieldError={getNetworkError('network')}>
                    <Styled.SameRowNoTooltip>
                      <Form.Select options={networkTypeOptions} portalTarget={document.body}></Form.Select>
                    </Styled.SameRowNoTooltip>
                  </Form.Group>
                  <Form.Group name="code" required={true}>
                    <Styled.SameRowWithTooltip>
                      <Form.Input maxLength={10} />
                      <Tooltip
                        content={<Translate name="addProduct.fields.code.tooltip" />}
                        position={isPhone ? TooltipPosition.BOTTOM : TooltipPosition.RIGHT}
                        maxWidth={200}
                      >
                        <Icon name="question" circular={true} size="large" color="info" />
                      </Tooltip>
                    </Styled.SameRowWithTooltip>
                  </Form.Group>
                  <Form.Group name="calculationFactor" required={true}>
                    <Styled.SameRowWithTooltip>
                      <Form.Input type="number" />
                      <Tooltip
                        content={<Translate name="addProduct.fields.calculationFactor.tooltip" />}
                        position={isPhone ? TooltipPosition.BOTTOM : TooltipPosition.RIGHT}
                        maxWidth={200}
                      >
                        <Icon name="question" circular={true} size="large" color="info" />
                      </Tooltip>
                    </Styled.SameRowWithTooltip>
                  </Form.Group>
                  <Form.Group name="backupWallet" required={!isEwpg}>
                    {!isEwpg && selectedNetwork && (
                      <>
                        {isStellarNetwork(selectedNetwork)
                          ? Form.Validators.StellarAddress()
                          : (
                            <>
                              {Form.Validators.EthereumAddress()}
                              {Form.Validators.TokenAddressChecksum()}
                            </>
                          )
                        }
                      </>
                    )}
                    <Styled.SameRowWithTooltip>
                      <Form.Input disabled={isEwpg} />
                      <Tooltip
                        content={<Translate name="addProduct.fields.backupWallet.tooltip" />}
                        position={isPhone ? TooltipPosition.BOTTOM : TooltipPosition.RIGHT}
                        maxWidth={200}
                      >
                        <Icon name="question" circular={true} size="large" color="info" />
                      </Tooltip>
                    </Styled.SameRowWithTooltip>
                  </Form.Group>
                  <div>
                    <Form.Group name="lockInTime" fieldError={getDateError('lock_in_time')}>
                      <Styled.SameRowWithTooltip>
                        <Grid>
                          <Col width={6}>
                            <DatePicker
                              placeholder={translate('addProduct.fields.date.label')}
                              value={dateValue}
                              minDate={new Date()}
                              onChange={(value: any) => {
                                setDateValue(value);
                              }}
                            />
                          </Col>
                          <Col width={6}>
                            <SelectAtom
                              placeholder={translate('addProduct.fields.time.label')}
                              options={timeOptions}
                              portalTarget={document.body}
                              value={timeValue}
                              onChange={(value: string) => {
                                setTimeValue(value);
                              }}
                            ></SelectAtom>
                          </Col>
                        </Grid>
                        <Tooltip
                          content={<Translate name="addProduct.fields.lockInTime.tooltip" />}
                          position={isPhone ? TooltipPosition.BOTTOM : TooltipPosition.RIGHT}
                          maxWidth={200}
                        >
                          <Icon name="question" circular={true} size="large" color="info" />
                        </Tooltip>
                      </Styled.SameRowWithTooltip>
                    </Form.Group>
                  </div>
                  <Spacer y={6} />
                  <h4>
                    <Translate name="addProduct.investmentInformation" />
                  </h4>
                  <Spacer y={2} />
                  <Form.Group name="nominalAmount" required={true}>
                    <Styled.SameRowWithTooltip>
                      <Form.Input type="number" min={0} isCurrency />
                      <Tooltip
                        content={<Translate name="addProduct.fields.nominalAmount.tooltip" />}
                        position={isPhone ? TooltipPosition.BOTTOM : TooltipPosition.RIGHT}
                        maxWidth={200}
                      >
                        <Icon name="question" circular={true} size="large" color="info" />
                      </Tooltip>
                    </Styled.SameRowWithTooltip>
                  </Form.Group>
                  <Form.Group name="agioPercent" required={true}>
                    <Styled.SameRowWithTooltip>
                      <Form.Input type="number" min={0} max={100} isPercentage />
                      <Tooltip
                        content={<Translate name="addProduct.fields.agioPercent.tooltip" />}
                        position={isPhone ? TooltipPosition.BOTTOM : TooltipPosition.RIGHT}
                        maxWidth={200}
                      >
                        <Icon name="question" circular={true} size="large" color="info" />
                      </Tooltip>
                    </Styled.SameRowWithTooltip>
                  </Form.Group>
                  <Form.Group name="stepSize" required={true}>
                    <Styled.SameRowWithTooltip>
                      <Form.Input type="number" min={0} isCurrency />
                      <Tooltip
                        content={<Translate name="addProduct.fields.stepSize.tooltip" />}
                        position={isPhone ? TooltipPosition.BOTTOM : TooltipPosition.RIGHT}
                        maxWidth={200}
                      >
                        <Icon name="question" circular={true} size="large" color="info" />
                      </Tooltip>
                    </Styled.SameRowWithTooltip>
                  </Form.Group>
                  <Form.Group name="maxIssuableAmount" required={true}>
                    <Styled.SameRowWithTooltip>
                      <Form.Input type="number" min={0} />
                      <Tooltip
                        content={<Translate name="addProduct.fields.maxIssuableAmount.tooltip" />}
                        position={isPhone ? TooltipPosition.BOTTOM : TooltipPosition.RIGHT}
                        maxWidth={200}
                      >
                        <Icon name="question" circular={true} size="large" color="info" />
                      </Tooltip>
                    </Styled.SameRowWithTooltip>
                  </Form.Group>
                  <Form.Group name="paymentDeadline" required={true}>
                    <Styled.SameRowWithTooltip>
                      <Form.Input type="number" min={0} />
                      <Tooltip
                        content={<Translate name="addProduct.fields.paymentDeadline.tooltip" />}
                        position={isPhone ? TooltipPosition.BOTTOM : TooltipPosition.RIGHT}
                        maxWidth={200}
                      >
                        <Icon name="question" circular={true} size="large" color="info" />
                      </Tooltip>
                    </Styled.SameRowWithTooltip>
                  </Form.Group>
                  <Form.Group name="cancellationDeadline" required={true}>
                    <Styled.SameRowWithTooltip>
                      <Form.Input type="number" min={0} />
                      <Tooltip
                        content={<Translate name="addProduct.fields.cancellationDeadline.tooltip" />}
                        position={isPhone ? TooltipPosition.BOTTOM : TooltipPosition.RIGHT}
                        maxWidth={200}
                      >
                        <Icon name="question" circular={true} size="large" color="info" />
                      </Tooltip>
                    </Styled.SameRowWithTooltip>
                  </Form.Group>

                  <Spacer y={6} />
                  <Styled.SameRowWithTooltip>
                    <Styled.LabelTooltip>
                      <Translate name="addProduct.fields.issuanceDocument.label" />
                    </Styled.LabelTooltip>
                    <Tooltip
                      content={<Translate name="addProduct.fields.issuanceDocument.tooltip" />}
                      position={isPhone ? TooltipPosition.BOTTOM : TooltipPosition.RIGHT}
                      maxWidth={200}
                    >
                      <Icon name="question" circular={true} size="large" color="info" />
                    </Tooltip>
                  </Styled.SameRowWithTooltip>
                  <Spacer y={2} />
                  <Form.Group name="issuanceDocument" required={!data?.issuanceDocument} label={null}>
                    <Form.FileUpload />
                    {Form.Validators.FileSize(5000000)}
                  </Form.Group>
                </>
              )}
            </Form.ValueProvider>
          )}
        </ModalContent>
        <ModalFooter>
          <ModalFooterButtons actionButtons={actionButtons} />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddProductModal;

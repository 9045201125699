import React, { FunctionComponent } from 'react';
import Hint from 'src/ui/atoms/hint';
import Spacer from 'src/ui/atoms/spacer';
import Translate from 'src/ui/atoms/translate';

const AddProductExit: FunctionComponent = () => {
  return (
    <>
      <Translate name="addProduct.exit.subtitle" />
      <Spacer y={6} />
      <Hint variant="warning">
        <Translate name="addProduct.exit.info" />
      </Hint>
    </>
  );
};

export default AddProductExit;

import { Cmd, loop, Loop, LoopReducerWithDefinedState } from 'redux-loop';
import { mergeAll } from 'lodash/fp';
import {
  IssuerActionTypes,
  issuersFetch,
  IssuersFetch,
  issuersFetchFailure,
  IssuersFetchFailure,
  issuersFetchSuccess,
  IssuersFetchSuccess,
  LoadIssuerApp,
  TokensFetch,
  TokensFetchFailure,
  TokensFetchSuccess,
} from './actions';
import { IssuerActions, tokensFetch, tokensFetchFailure, tokensFetchSuccess } from './actions';
import { getTokensEffect, getIssuersEffect } from './effects';
import type { AdminProductDetail } from 'api';
import { RootActions, WithRootActionTypes } from 'store/actions';

export type IssuerAppShape = {
  tokens: AdminProductDetail[] | null;
  hasIssuersToView: boolean;
  loading: boolean;
  error: Error | null;
  isInitial: boolean;
};

export const initialState: IssuerAppShape = {
  tokens: null,
  hasIssuersToView: false,
  loading: false,
  error: null,
  isInitial: true,
};

type IssuerLoop<A> = (state: IssuerAppShape, action: A) => IssuerAppShape | Loop<IssuerAppShape>;

const handleLoadIssuerApp: IssuerLoop<LoadIssuerApp> = (state) => {
  return loop(state, Cmd.list([Cmd.action(tokensFetch()), Cmd.action(issuersFetch())]));
};

const handleTokensFetch: IssuerLoop<TokensFetch> = (state) => {
  return loop(
    mergeAll([state, { loading: true, error: false }]),
    Cmd.run(getTokensEffect, {
      args: [Cmd.getState],
      successActionCreator: tokensFetchSuccess,
      failActionCreator: tokensFetchFailure,
    }),
  );
};

const handleTokensFetchSuccess: IssuerLoop<TokensFetchSuccess> = (state, { data }) => {
  return mergeAll([
    state,
    {
      tokens: data.results,
      loading: false,
      error: null,
      isInitial: false,
    },
  ]);
};

const handleTokensFetchFailure: IssuerLoop<TokensFetchFailure> = (state, { error }) => {
  return mergeAll([
    state,
    {
      tokens: null,
      loading: false,
      error: error,
    },
  ]);
};

const handleIssuersFetch: IssuerLoop<IssuersFetch> = (state) => {
  return loop(
    mergeAll([state, { loading: true, error: false }]),
    Cmd.run(getIssuersEffect, {
      args: [Cmd.getState],
      successActionCreator: issuersFetchSuccess,
      failActionCreator: issuersFetchFailure,
    }),
  );
};

const handleIssuersFetchSuccess: IssuerLoop<IssuersFetchSuccess> = (state, { data }) => {
  return mergeAll([
    state,
    {
      hasIssuersToView: !!data.results.length,
      loading: false,
      error: null,
      isInitial: false,
    },
  ]);
};

const handleIssuersFetchFailure: IssuerLoop<IssuersFetchFailure> = (state, { error }) => {
  return mergeAll([
    state,
    {
      hasIssuersToView: false,
    },
  ]);
};

const reducer: LoopReducerWithDefinedState<IssuerAppShape, WithRootActionTypes<IssuerActionTypes>> = (
  state = initialState,
  action,
): IssuerAppShape | Loop<IssuerAppShape> => {
  switch (action.type) {
    case RootActions.RESET_STATE:
      return initialState;
    case IssuerActions.LOAD_APP:
      return handleLoadIssuerApp(state, action);
    case IssuerActions.TOKENS_FETCH:
      return handleTokensFetch(state, action);
    case IssuerActions.TOKENS_FETCH_SUCCESS:
      return handleTokensFetchSuccess(state, action);
    case IssuerActions.TOKENS_FETCH_FAILURE:
      return handleTokensFetchFailure(state, action);
    case IssuerActions.ISSUERS_FETCH:
      return handleIssuersFetch(state, action);
    case IssuerActions.ISSUERS_FETCH_SUCCESS:
      return handleIssuersFetchSuccess(state, action);
    case IssuerActions.ISSUERS_FETCH_FAILURE:
      return handleIssuersFetchFailure(state, action);
    default:
      return state;
  }
};

export default reducer;

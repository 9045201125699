import { AdminIssuerDetail, CountryEnum, CurrencyEnum } from 'api';
import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import EditBankAccountModal from './edit-bank-account-modal';
import EditCompanyDataModal from './edit-company-data-modal';
import EditCeoModal from './edit-ceo-modal';
import SuccessMessageModal from './success-message-modal';
import EditConfigurationModal from './edit-configuration-modal';

export interface IssuerEditModalsProps {
  fetchIssuerDetails: () => void;
  isEditCompanyDataOpen: boolean;
  isEditBankAccountOpen: boolean;
  isEditCeoOpen: boolean;
  isEditConfigurationOpen: boolean;
  isSuccessEditOpen: boolean;
  setEditCompanyDataModal: (val: boolean) => void;
  setEditBankAccountModal: (val: boolean) => void;
  setEditCeoModal: (val: boolean) => void;
  setEditConfigurationModal: (val: boolean) => void;
  setSuccessModal: (val: boolean) => void;
  issuer: AdminIssuerDetail;
}

const IssuerEditModals: FunctionComponent<IssuerEditModalsProps> = (props) => {
  const {
    fetchIssuerDetails,
    isEditCompanyDataOpen,
    isEditBankAccountOpen,
    isEditCeoOpen,
    isEditConfigurationOpen,
    isSuccessEditOpen,
    setEditCompanyDataModal,
    setEditBankAccountModal,
    setEditCeoModal,
    setEditConfigurationModal,
    setSuccessModal,
    issuer: { id, legalPerson, naturalPerson, bankAccount, canCreateCryptoSecurities, autoValidation },
  } = props;

  if (!id) return null;

  return (
    <>
      {isEditCompanyDataOpen && (
        <EditCompanyDataModal
          onHideEditCompanyData={() => setEditCompanyDataModal(false)}
          fetchIssuerDetails={fetchIssuerDetails}
          showSuccessModal={() => setSuccessModal(true)}
          values={{
            id,
            companyName: legalPerson.companyName || '',
            street: legalPerson.street || '',
            zip: legalPerson.zip || '',
            city: legalPerson.city || '',
            country: legalPerson.country || CountryEnum.DEU,
            commercialRegister: legalPerson.commercialRegister || '',
            commercialRegisterNumber: legalPerson.commercialRegisterNumber || '',
            commercialRegisterStatement: null,
            listOfShareholders: null,
            commercialRegisterStatementId: legalPerson.commercialRegisterStatement?.id,
            listOfShareholdersId: legalPerson.listOfShareholders?.id,
          }}
        />
      )}
      {isEditBankAccountOpen && (
        <EditBankAccountModal
          onHideEditBankAccount={() => setEditBankAccountModal(false)}
          fetchIssuerDetails={fetchIssuerDetails}
          showSuccessModal={() => setSuccessModal(true)}
          values={{
            id: id,
            iban: bankAccount.iban || '',
            currency: bankAccount.currency || CurrencyEnum.EUR,
            accountHolder: bankAccount.accountHolder || '',
            bank: bankAccount.bank || '',
            bic: bankAccount.bic || '',
            country: bankAccount.country || '',
          }}
        />
      )}
      {isEditCeoOpen && (
        <EditCeoModal
          onHideEditCeo={() => setEditCeoModal(false)}
          fetchIssuerDetails={fetchIssuerDetails}
          showSuccessModal={() => setSuccessModal(true)}
          values={{
            id: id,
            salutation: naturalPerson.salutation,
            forename: naturalPerson.forename,
            surname: naturalPerson.surname,
            email: naturalPerson.email,
          }}
        />
      )}
      {isEditConfigurationOpen && (
        <EditConfigurationModal
          onHideEditConfiguration={() => setEditConfigurationModal(false)}
          fetchIssuerDetails={fetchIssuerDetails}
          showSuccessModal={() => setSuccessModal(true)}
          values={{
            id: id,
            canCreateCryptoSecurities: String(canCreateCryptoSecurities),
            automaticValidationEnabled: String(autoValidation),
          }}
        />
      )}
      {isSuccessEditOpen && (
        <SuccessMessageModal
          title={<Translate name="dashboardIssuerInvestorDetails.editSuccessModal.title" />}
          onHideSuccessEdit={() => setSuccessModal(false)}
        >
          <Translate name="dashboardIssuerInvestorDetails.editSuccessModal.message" />
        </SuccessMessageModal>
      )}
    </>
  );
};

export default IssuerEditModals;

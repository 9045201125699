import { Cmd } from 'redux-loop';
import { AdminApi, PaginatedAdminIssuerListList, PaginatedAdminProductDetailList } from 'api';
import { makeAuthenticatedApi } from 'core/api';

export async function getTokensEffect(getState: Cmd.GetState): Promise<PaginatedAdminProductDetailList> {
  const adminApi = makeAuthenticatedApi(AdminApi, getState);

  return await adminApi.adminTokensList({ limit: 100 });
}

export async function getIssuersEffect(getState: Cmd.GetState): Promise<PaginatedAdminIssuerListList> {
  const adminApi = makeAuthenticatedApi(AdminApi, getState);

  return await adminApi.adminIssuersList({ limit: 1 });
}

import React, { createContext } from 'react';
import {
  StepEnum,
  InvestorStepEnum,
  Invitation,
  InvestmentDetails,
  InvestmentTokenForInvestor,
  InvestmentCalculation,
  Issuer,
} from 'api';
import { AnyServerError } from 'hooks/use-api-error';

export interface WizardContextProps {
  changeStep: (step: StepEnum | StepEnum[] | InvestorStepEnum | InvestorStepEnum[] | undefined) => void;
  finalize: (access?: string) => void;
  nextStep: () => void;
  reload: () => void;
  lastRevisitableStep: () => StepEnum | InvestorStepEnum | undefined;
  DefaultPageTitle: () => JSX.Element;
  isTransferInvitation: boolean;
  loading: boolean;
  step: StepEnum | InvestorStepEnum | undefined;
  isStepDone: boolean;
  isStepRevisitable: boolean;
  resourceId: string;
  investmentId: string;
  isRegisterOnlyFlow: boolean;
  onCloseProcessOverviewOverlay: (invitationId: string) => void;
  showProcessOverview: boolean;
  error?: AnyServerError;
  invitation?: Invitation;
  investment?: InvestmentDetails | null;
  token?: InvestmentTokenForInvestor;
  calculation?: InvestmentCalculation;
  issuer?: Issuer;
}

const WizardContext = createContext<WizardContextProps>({
  changeStep: (step: StepEnum | StepEnum[] | InvestorStepEnum | InvestorStepEnum[] | undefined) => {},
  finalize: (access?: string) => {},
  nextStep: () => {},
  lastRevisitableStep: () => undefined,
  DefaultPageTitle: () => <p></p>,
  isTransferInvitation: false,
  loading: false,
  step: undefined,
  isStepDone: false,
  isStepRevisitable: false,
  resourceId: '',
  investmentId: '',
  isRegisterOnlyFlow: false,
  onCloseProcessOverviewOverlay: (invitationId: string) => {},
  showProcessOverview: false,
  error: undefined,
  reload: () => {},
  invitation: undefined,
  investment: null,
  token: undefined,
  calculation: undefined,
  issuer: undefined,
});

export default WizardContext;

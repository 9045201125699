import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import Section from 'ui/atoms/section';
import { UserDataSet } from 'ui/types/user-data-set';
import Grid, { Col } from 'ui/atoms/grid';

export interface IssuerInformationProps {
  userDataSet: UserDataSet;
}

const IssuerInformation: FunctionComponent<IssuerInformationProps> = (props) => {
  const { userDataSet } = props;
  const endOfBlock = ['ibanBank'];

  return (
    <div className={cx('user-information')}>
      <Section>
        {Object.entries(userDataSet.fields).map((value: any, key: number) => {
          return (
            <Grid spacing={endOfBlock.includes(value[0]) ? 'large' : 'medium'} key={key}>
              <Col spacing={endOfBlock.includes(value[0]) ? 'medium' : undefined}>
                <Translate name={`dashboardIssuerIssuerDetails.${userDataSet.key}.${value[0]}`} />
              </Col>
              <Col>
                <PlaceholderFallback>{value[1]}</PlaceholderFallback>
              </Col>
            </Grid>
          );
        })}
      </Section>
    </div>
  );
};

export default IssuerInformation;

import React, { FunctionComponent, useCallback, useState } from 'react';
import Button from 'ui/atoms/button';
import Icon from 'ui/atoms/icon';
import List from 'ui/atoms/list';
import Translate from 'ui/atoms/translate';
import useTranslate from 'ui/hooks/use-translate';
import Link from 'ui/atoms/link';
import useApiCall from 'hooks/use-api-call';
import { WalletsApi } from 'api/apis';
import { AnyServerError } from 'hooks/use-api-error';
import { useServerConfigSelector } from 'core/config/hooks';
import ActionButtons from 'ui/molecules/action-buttons';
import Spacer from 'ui/atoms/spacer';
import Checkbox from 'ui/atoms/checkbox';

interface CreateWalletPageProps {
  onWalletCreated?: (walletId: string) => void;
  onCancel?: () => void;
  loading?: boolean;
  error?: AnyServerError;
}

const CreateDepot: FunctionComponent<CreateWalletPageProps> = ({
  onWalletCreated = () => {},
  onCancel = () => {},
  loading,
  error,
}) => {
  const { error: apiError, loading: loadingApi, makeAuthenticatedApi, withApi } = useApiCall();

  const [isChecked, setChecked] = useState(false);

  const walletsApi: WalletsApi = makeAuthenticatedApi(WalletsApi);

  const translate = useTranslate();

  const { config } = useServerConfigSelector();

  const onCreateDepot = useCallback(async () => {
    await withApi(async () => {
      const { id } = await walletsApi.walletsTanganyWalletCreate();

      if (!id) return;
      onWalletCreated(id);
    });
  }, [withApi, walletsApi, onWalletCreated]);

  return (
    <>
      <p>
        <Translate name="wallet.createTangany.description" />
      </p>
      <p>
        <Translate name="wallet.createTangany.benefitDescription" />
      </p>
      <div style={{ fontSize: '0.9rem', lineHeight: '2rem' }}>
        <List>
          <li>
            <Icon name="check" color="grayDark" />
            &nbsp;&nbsp;
            <Translate name="wallet.createTangany.benefit1" />
          </li>
          <li>
            <Icon name="check" color="grayDark" />
            &nbsp;&nbsp;
            <Translate name="wallet.createTangany.benefit2" />
          </li>
          <li>
            <Icon name="check" color="grayDark" />
            &nbsp;&nbsp;
            <Translate name="wallet.createTangany.benefit3" />
          </li>
        </List>
      </div>
      <Checkbox checked={isChecked} onChange={() => setChecked(!isChecked)}>
        <span style={{ fontSize: '0.9rem' }}>
          <Translate
            name="wallet.createTangany.tosCheckbox"
            args={[
              (txt, key) => (
                <Link href={config.tanganyTosUrl} external inline={true}>
                  {txt}
                </Link>
              ),
            ]}
          />
        </span>
      </Checkbox>
      <Spacer y={2} />
      <Button variant="link" onClick={onCancel}>
        <Translate name="wallet.otherOptions" />
      </Button>
      <ActionButtons>
        <Button
          loading={loading || loadingApi}
          fluid={true}
          variant="primary"
          onClick={onCreateDepot}
          size="large"
          disabled={!isChecked}
        >
          <Translate name="wallet.createTangany.createButton" />
        </Button>
      </ActionButtons>
      {error && <p className="error-text">{error.getMessage(translate)}</p>}
      {apiError && <p className="error-text">{apiError.getMessage(translate)}</p>}
    </>
  );
};

export default CreateDepot;

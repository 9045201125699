import React, { FunctionComponent, useMemo } from 'react';
import RoutesRenderer from 'core/routing/routes-renderer';
import DashboardLayout from 'core/layout/dashboard';
import { COMPONENT_ROUTING, ISSUER_NAVIGATION, ROUTES } from './routes.config';
import { useIssuerNavigationItems } from './hooks';
import type { Product } from 'api';
import { useServerConfigSelector } from 'core/config/hooks';
import { hasManageTriggerSolutionPermission } from '../helpers/tokenPermissions';
import { useCurrentUserSelector } from 'core/auth/hooks';
import {
  hasDistributionManageVouchersPermission,
  hasDistributionViewFriendReferralPermission,
} from '../helpers/distributionPlatformPermissions';
import { currentUserHasCreateIssuerPermission } from 'apps/issuer/helpers/userPermissions';

export interface IssuerPagesProps {
  tokens: Product[];
  hasIssuersToView: boolean;
}

const IssuerPages: FunctionComponent<IssuerPagesProps> = ({ tokens, hasIssuersToView }) => {
  const { config } = useServerConfigSelector();
  const { currentUser } = useCurrentUserSelector();

  const routes = useIssuerNavigationItems({
    tokens,
    hasIssuersToView,
    configFeatures: config.features,
    distributionPlatforms: currentUser?.associatedDistributionPlatforms || [],
    userPermissions: currentUser?.permissions || [],
    canCreateProducts: currentUser?.canCreateProducts || false,
  });

  let filteredRoutes = ISSUER_NAVIGATION;

  if (!hasManageTriggerSolutionPermission(tokens)) {
    filteredRoutes = filteredRoutes.filter((nav) => nav.key !== ROUTES.triggerSolutionsSettlements);
  }

  if (!currentUserHasCreateIssuerPermission(currentUser?.permissions || []) && !hasIssuersToView) {
    filteredRoutes = filteredRoutes.filter((nav) => nav.key !== ROUTES.issuers);
  }

  if (!hasDistributionManageVouchersPermission(currentUser?.associatedDistributionPlatforms || [])) {
    filteredRoutes = filteredRoutes.filter((nav) => nav.key !== ROUTES.vouchers);
  }

  if (!hasDistributionViewFriendReferralPermission(currentUser?.associatedDistributionPlatforms || [])) {
    filteredRoutes = filteredRoutes.filter((nav) => nav.key !== ROUTES.friendReferrals);
  }

  const navItems = useMemo(
    () => (routes ? filteredRoutes.filter((nav) => !!routes[nav.key]) : []),
    [JSON.stringify(routes)],
  );

  return (
    <DashboardLayout navigationItems={navItems}>
      <RoutesRenderer routes={routes} componentRouting={COMPONENT_ROUTING} />
    </DashboardLayout>
  );
};

export default IssuerPages;

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import WizardContext from 'libraries/wizard/wizard-context';
import {
  GroupScore,
  Investor,
  InvestorsApi,
  InvitationsApi,
  NetworthAnswerRequest,
  QuestionnaireQuestion,
  SurveyWaiverTypeEnum,
} from 'api';
import {
  EducationOccupationFields,
  QuestionnaireAnswers,
} from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import {
  convertEducationalQualificationFromApi,
  convertEducationalQualificationToApi,
  convertOccupationFromApi,
  extractNaturalAndLegalFromPerson,
} from 'core/api/conversions';
import useApiCall from 'hooks/use-api-call';
import {
  QUESTIONNAIRE_PAGES_SEGMENTS,
  QUESTIONNAIRE_PAGES_SEGMENTS_ECSP,
} from 'libraries/wizard/components/questionnaire/questionnaire-form/common';
import { useCurrentUserSelector } from 'core/auth/hooks';
import { Money } from 'api';
import { useIsNetWorthSuitable } from 'libraries/wizard/components/questionnaire/shared/risk-factors';
import { LosabilityQuestions } from 'libraries/wizard/components/loss-ability-calculator/hooks';
import { toMoney } from 'ui/helper/money';

export function useQuestionnaire() {
  const [editQuestions, setEditQuestions] = useState(false);
  const [prefilledQuestions, setPrefilledQuestions] = useState<QuestionnaireQuestion[] | null>(null);
  const [surveyAnswers, setSurveyAnswers] = useState<{ [key: string]: Object }>();
  const [initialEducationOccupation, setInitialEducationOccupation] = useState<EducationOccupationFields>();
  const [showLossabilityCalculator, setShowLossabilityCalculator] = useState(false);
  const [savedNetWorth, setSavedNetWorth] = useState<Money | null>(null);
  const [lossability, setLossability] = useState<Money | null>(null);
  const [isSavingDisabled, setSavingDisabled] = useState(false);
  const [lossabilityAnswers, setLosabilityAnswers] = useState<NetworthAnswerRequest[]>([]);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [investor, setInvestor] = useState<Investor | null>();
  const [scoreList, setScoreList] = useState<GroupScore[]>();
  const [surveyValid, setSurveyValid] = useState<boolean>(false);
  const [surveyWaiverType, setSurveyWaiverType] = useState<SurveyWaiverTypeEnum>();
  const [showWaiver, setShowWaiver] = useState(false);

  const { resourceId: invitationId, nextStep, invitation, loading: invitationLoading } = useContext(WizardContext);
  const { loading: apiLoading, error, withApi, makeAuthenticatedApi } = useApiCall();
  const invitationsApi: InvitationsApi = useMemo(() => makeAuthenticatedApi(InvitationsApi), [makeAuthenticatedApi]);
  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  const [subStep, setSubStep] = useState(parseInt(localStorage.getItem(`questionnairePage.${invitationId}`) || '1'));

  const { currentUser } = useCurrentUserSelector();
  const isNetWorthSuitable = useIsNetWorthSuitable(lossability, invitation?.investmentTotal) && savedNetWorth !== null;
  const { naturalPerson } = extractNaturalAndLegalFromPerson(investor?.person);
  const investorId = currentUser?.investor;
  const noAnswers = surveyWaiverType === SurveyWaiverTypeEnum.NO_ANSWERS;

  const isEcsp = useMemo(() => {
    return invitation?.isEcsp || false;
  }, [invitation?.isEcsp]);

  const questionnairePagesSegments = useMemo(() => {
    if (isEcsp) return QUESTIONNAIRE_PAGES_SEGMENTS_ECSP;
    return QUESTIONNAIRE_PAGES_SEGMENTS;
  }, [invitation?.isEcsp]);

  useEffect(() => {
    fetchInvestor();
  }, [investorId]);

  useEffect(() => {
    fetchPrefilledQuestionnaire();
  }, [investor?.id, invitationId, JSON.stringify(investor?.surveyAnswers)]);

  const fetchPrefilledQuestionnaire = useCallback(async () => {
    if (!invitationId) return;

    await withApi(async () => {
      const questions = await invitationsApi.invitationsPrefilledQuestionnaireList({
        id: invitationId,
      });

      setPrefilledQuestions(questions);
    });
  }, [invitationId]);

  const fetchInvestor = useCallback(() => {
    if (!investorId) return;

    withApi(async () => {
      const investor = await investorsApi.investorsRetrieve({ id: investorId });
      setInvestor(investor);

      const { naturalPerson } = extractNaturalAndLegalFromPerson(investor?.person);
      if (naturalPerson) {
        setInitialEducationOccupation({
          educationalQualification:
            naturalPerson.educationalQualification &&
            convertEducationalQualificationFromApi(naturalPerson.educationalQualification),
          occupation: naturalPerson.occupation ? convertOccupationFromApi(naturalPerson.occupation) : undefined,
        });
        setSurveyAnswers(investor?.surveyAnswers);
      }
    });
  }, [investor?.id]);

  const setQuestionnaireSubStep = (pageNumber: number, invitation: string = invitationId) => {
    setSubStep(pageNumber);
    localStorage.setItem(`questionnairePage.${invitation}`, pageNumber.toString());
    if (pageNumber <= questionnairePagesSegments.length) {
      setShowLossabilityCalculator(false);
      setShowSummary(false);
      setShowWaiver(false);
    }
  };

  const onGoBackQuestionnaire = () => {
    setQuestionnaireSubStep(subStep - 1);
    setEditQuestions(true);
    //TO DO: discard values on the page which was not saved when clicking on the back button
  };

  const onQuestionnaireSaved = useCallback(async () => {
    if (isEcsp && investor?.id) {
      try {
        const answers = await investorsApi.investorsNetworthAnswersRetrieve({ id: investor.id });
        setShowLossabilityCalculator(true);
        setSavedNetWorth(answers.networth);
        setLossability(answers.lossability);
        setEditQuestions(false);
      } catch (e) {
        setShowLossabilityCalculator(true);
        setSavedNetWorth(null);
        setLossability(null);
        console.error(e);
      }
    } else {
      setEditQuestions(false);
      onFinalize();
    }
  }, [invitation?.isEcsp, investor?.id]);

  const onSubmitAnswers = useCallback(
    async (
      values: { answers: QuestionnaireAnswers[]; educationOccupation: EducationOccupationFields },
      shouldFinalize: boolean = false,
    ) => {
      const answers =
        values.answers.length === 0
          ? []
          : values.answers.map((answer) => {
              return {
                id: answer.id,
                selectedOption: answer.selectedOption,
              };
            });

      await withApi(async () => {
        if (!investor?.id) return;
        await investorsApi.investorsPartialUpdate({
          id: investor?.id,
          patchedInvestorUpdateRequest: {
            naturalPerson: {
              educationalQualification:
                values.educationOccupation.educationalQualification &&
                convertEducationalQualificationToApi(values.educationOccupation.educationalQualification),
              occupation: values.educationOccupation.occupation,
            },
          },
        });

        const { surveyValid, surveyWaiverType } = await invitationsApi.invitationsQuestionnaireCreate({
          id: invitationId,
          invitationSetQuestionnaireRequest: { answers: answers },
        });

        fetchInvestor();
        setEditQuestions(true);

        if (shouldFinalize) {
          if (isEcsp) {
            setSurveyValid(surveyValid);
            setSurveyWaiverType(surveyWaiverType);
            setQuestionnaireSubStep(questionnairePagesSegments.length + 1);
            await onQuestionnaireSaved();
          } else if (!surveyValid) {
            setSurveyWaiverType(surveyWaiverType);
            setShowWaiver(true);
          } else {
            onQuestionnaireSaved();
          }
        }
      });
    },
    [invitationsApi, invitationId, withApi, onQuestionnaireSaved, investorsApi, investor, isEcsp],
  );

  const onEditQuestions = () => {
    setEditQuestions(true);
    setShowWaiver(false);
    setSubStep(1);
  };

  const onFinalize = useCallback(() => {
    // jump to first possible step
    nextStep();
  }, [nextStep]);

  //networth calculator functions
  const onSaveLossabilityEntries = useCallback(async () => {
    if (!investor?.id) return;

    setSavingDisabled(true);

    try {
      const answers = await investorsApi.investorsNetworthAnswersCreate({
        id: investor.id,
        investorNetworthRequest: {
          networthAnswers: lossabilityAnswers,
        },
      });
      setSavedNetWorth(answers.networth);
      setLossability(answers.lossability);
      setSavingDisabled(false);
      showSummaryPage();
      setShowLossabilityCalculator(false);
    } catch (e) {
      console.error(e);
      setSavingDisabled(false);
    }
  }, [lossabilityAnswers, investor?.id]);

  const onContinueWithoutSavingNetworth = () => {
    setConfirmModalOpen(false);
    setShowLossabilityCalculator(false);
    setSavedNetWorth(null);
    showSummaryPage();
  };

  const onSkipNetworth = () => {
    setShowLossabilityCalculator(false);
    showSummaryPage();
  };

  const restartNetworthCalculation = useCallback(async () => {
    if (!investor?.id) return;

    const answers: NetworthAnswerRequest[] = Object.values(LosabilityQuestions).map((question) => ({
      questionId: question,
      answer: toMoney(0),
    }));

    try {
      await investorsApi.investorsNetworthAnswersCreate({
        id: investor.id,
        investorNetworthRequest: {
          networthAnswers: answers,
        },
      });
    } catch (e) {
      console.error(e);
    }

    setSavedNetWorth(null);
  }, [investorsApi, investor?.id]);

  // summary functions
  const showSummaryPage = useCallback(async () => {
    setShowSummary(true);
    const scoreList = await invitationsApi.invitationsSurveyScoreList({ id: invitationId });
    setScoreList(scoreList);
  }, [invitationsApi, invitationId]);

  const onContinueSummary = () => {
    setShowSummary(false);
    if (!surveyValid && surveyWaiverType) {
      setShowWaiver(true);
    } else if (!isNetWorthSuitable) {
      setShowWaiver(true);
    } else {
      onFinalize();
    }
  };

  const onGoBackSummary = () => {
    setShowSummary(false);
    setShowWaiver(false);
    setShowLossabilityCalculator(true);
  };

  //waivers
  const onContinueWaiver = useCallback(async () => {
    await withApi(async () => {
      await invitationsApi.invitationsWaiversCreate({
        id: invitationId,
        waiverRequest: {
          surveyWaiverType: surveyWaiverType,
          lossabilityWaiverSigned: true,
        },
      });
    });
    onFinalize();
  }, [invitationId, withApi, invitationsApi, surveyWaiverType]);

  return {
    loading: apiLoading || invitationLoading,
    apiLoading,
    onContinueWaiver,
    onEditQuestions,
    surveyAnswers,
    prefilledQuestions,
    naturalPerson,
    investor,
    error,
    onSubmitAnswers,
    onFinalize,
    initialEducationOccupation,
    editQuestions,
    setEditQuestions,
    questionnairePagesSegments,
    onSaveLossabilityEntries,
    showLossabilityCalculator,
    setShowLossabilityCalculator,
    savedNetWorth,
    setSavedNetWorth,
    lossability,
    isSavingDisabled,
    setLosabilityAnswers,
    questionnairePage: subStep,
    setQuestionnairePage: setQuestionnaireSubStep,
    isEcsp,
    onGoBackQuestionnaire,
    onGoBackSummary,
    onContinueWithoutSavingNetworth,
    onSkipNetworth,
    isConfirmModalOpen,
    setConfirmModalOpen,
    showSummary,
    scoreList,
    investmentTotal: invitation?.investmentTotal,
    onContinueSummary,
    noAnswers,
    surveyWaiverType,
    isNetWorthSuitable,
    restartNetworthCalculation,
    showWaiver,
    setSubStep,
  };
}

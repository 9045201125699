import { AdminApi, CountryEnum, CurrencyEnum } from 'api';
import useApiCall from 'hooks/use-api-call';
import React, { FunctionComponent, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import { Modal, makeModalForm, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import { StateValues } from 'react-use-form-state';
import useCountries from 'hooks/use-countries';
import useCurrencies from 'hooks/use-currencies';

export interface EditBankAccountFields {
  id: string;
  iban: string;
  currency: CurrencyEnum;
  accountHolder: string;
  bank: string;
  bic: string;
  country: CountryEnum;
}

export interface EditBankAccountModalProps {
  onHideEditBankAccount: () => void;
  fetchIssuerDetails: () => void;
  showSuccessModal: () => void;
  className?: string;
  values: EditBankAccountFields;
}

const EditBankAccountForm = makeModalForm<EditBankAccountFields>();

const EditBankAccountModal: FunctionComponent<EditBankAccountModalProps> = (props) => {
  const { onHideEditBankAccount, fetchIssuerDetails, showSuccessModal = () => {}, values } = props;

  const [issuerData, setIssuerData] = useState<EditBankAccountFields>(values || {});

  const { makeAuthenticatedApi, withApi, loading } = useApiCall();

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const editBankAccount = (fieldsValues: StateValues<EditBankAccountFields>) => {
    withApi(async () => {
      const patchBody = {
        id: values.id,
        patchedAdminIssuerUpdateRequest: {
          bankAccount: {
            ...fieldsValues,
          },
        },
      };
      await adminApi.adminIssuersPartialUpdate(patchBody);
      showSuccessModal();
      fetchIssuerDetails();
      onHideEditBankAccount();
    });
  };

  return (
    <>
      <Modal onClose={onHideEditBankAccount}>
        <EditBankAccountForm
          initial={{
            iban: issuerData.iban || '',
            currency: issuerData.currency || CurrencyEnum.EUR,
            accountHolder: issuerData.accountHolder || '',
            bank: issuerData.bank || '',
            bic: issuerData.bic || '',
            country: issuerData.country || '',
          }}
          onChange={(values: StateValues<EditBankAccountFields>) => {
            setIssuerData(values);
          }}
          onSubmit={(values: StateValues<EditBankAccountFields>) => {
            editBankAccount(values);
          }}
          i18nKey="dashboardIssuerInvestorDetails.editBankAccountForm"
        >
          <ModalHeader>
            <Translate name="dashboardIssuerInvestorDetails.editBankAccountForm.title" />
          </ModalHeader>
          <ModalContent>
            <EditBankAccountForm.Group name="iban" required={false}>
              <EditBankAccountForm.Input placeholder="-" />
              {EditBankAccountForm.Validators.IBAN()}
            </EditBankAccountForm.Group>
            <EditBankAccountForm.Group name="currency" required={true}>
              <EditBankAccountForm.Select options={useCurrencies()} value={values.currency} />
            </EditBankAccountForm.Group>
            <EditBankAccountForm.Group name="accountHolder" required={false}>
              <EditBankAccountForm.Input placeholder="-" />
            </EditBankAccountForm.Group>
            <EditBankAccountForm.Group name="bank" required={false}>
              <EditBankAccountForm.Input placeholder="-" />
            </EditBankAccountForm.Group>
            <EditBankAccountForm.Group name="bic" required={false}>
              <EditBankAccountForm.Input placeholder="-" />
            </EditBankAccountForm.Group>
            <EditBankAccountForm.Group name="country" required={!!values.country}>
              <EditBankAccountForm.Select
                options={useCountries()}
                value={issuerData.country}
                onChange={(country: CountryEnum) => {
                  setIssuerData({ ...issuerData, country });
                }}
              />
            </EditBankAccountForm.Group>
            <EditBankAccountForm.GenericErrorMessages />
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              actionButtons={[
                {
                  name: 'cancel',
                  content: <Translate name="common.close" />,
                  size: 'large',
                  onClick: onHideEditBankAccount,
                },
                {
                  name: 'send',
                  content: <Translate name="dashboardIssuerInvestorDetails.editBankAccountForm.button" />,
                  variant: 'primary',
                  size: 'large',
                  type: 'submit',
                  loading,
                },
              ]}
            />
          </ModalFooter>
        </EditBankAccountForm>
      </Modal>
    </>
  );
};

export default EditBankAccountModal;

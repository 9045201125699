import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import LoadingRing from 'ui/atoms/loading-ring';
import {
  CampaignInvitation,
  InvestmentCalculation,
  InvestmentInvitation,
  SelfDisclosureCalculation,
  Voucher,
} from 'api/models';
import useApiCall from 'hooks/use-api-call';
import { CampaignsApi, InvitationsApi } from 'api/apis';
import { nullMoney, toNumber } from 'ui/helper/money';
import { PersonType } from 'ui/types/person';
import useInvestorMe from 'hooks/use-investor-me';
import WizardContext from 'libraries/wizard/wizard-context';
import useThrottle from 'hooks/use-throttle';
import { InvitationToNonPreviewInvitation, PersonToLegalPerson, PersonToNaturalPerson } from 'helper/cast';
import BigNumber from 'bignumber.js';
import { MoneyValue } from 'ui/types/money-value';
import { makeBigInt } from 'ui/helper/big-number';
import PersonName from 'ui/atoms/person-name';
import InvestmentAmountForm, {
  InvestmentAmountFields,
} from 'subapps/investment/pages/investment/wizard-steps/investment-amount/investment-amount-form';
import { InvestmentSummaryContext } from 'subapps/investment/pages/investment/side-content';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';
import Translate from 'ui/atoms/translate';

const InvestmentAmountStep: FunctionComponent<{}> = () => {
  const {
    resourceId: invitationId,
    invitation,
    loading,
    finalize,
    calculation,
    token,
    issuer,
    error,
  } = useContext(WizardContext);
  const { setInvestmentSummaryNumbers } = useContext(InvestmentSummaryContext);

  const { investor: me, error: meError } = useInvestorMe();

  const [investmentAmountValues, setInvestmentAmountValues] = useState<InvestmentAmountFields>();
  const [shareCalculation, setShareCalculation] = useState<InvestmentCalculation>();
  const [selfDisclosureCalculation, setSelfDisclosureCalculation] = useState<SelfDisclosureCalculation>();
  const [selfDisclosureInvestmentAmountExceeds25k, setSelfDisclosureInvestmentAmountExceeds25k] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState<number | undefined>();

  const [voucher, setVoucher] = useState<Voucher>();

  const { error: apiError, loading: apiLoading, makeAuthenticatedApi, withApi } = useApiCall();

  const {
    error: selfDisclosureApiError,
    makeAuthenticatedApi: makeSelfDisclosureAuthenticatedApi,
    withApi: withSelfDisclosureApi,
  } = useApiCall();

  const {
    error: voucherApiError,
    makeAuthenticatedApi: makeVoucherAuthenticatedApi,
    withApi: withVoucherApi,
  } = useApiCall();

  const selfDisclosureInvitationsApi: InvitationsApi = useMemo(
    () => makeSelfDisclosureAuthenticatedApi(InvitationsApi),
    [makeSelfDisclosureAuthenticatedApi],
  );

  const voucherInvitationsApi: InvitationsApi = useMemo(
    () => makeVoucherAuthenticatedApi(InvitationsApi),
    [makeSelfDisclosureAuthenticatedApi],
  );

  // self disclosure should never be shown if invitation is ecsp
  const selfDisclosureRequired = invitation?.isEcsp ? false : token?.selfDisclosureRequired;

  // TODO(geforcefan): casting to investment invitation, we should be generic here, not supporting others for now
  const investmentInvitation = InvitationToNonPreviewInvitation(invitation);

  const initialNumberOfTokens: BigNumber | undefined = investmentInvitation?.numberOfTokens
    ? makeBigInt(investmentInvitation.numberOfTokens)
    : undefined;

  const offerValuesNumberOfTokens: BigNumber | undefined = useThrottle(
    investmentAmountValues?.tokenAmountInput?.nrOfTokens !== undefined
      ? investmentAmountValues?.tokenAmountInput?.nrOfTokens
      : initialNumberOfTokens,
  );

  const offerValuesVoucherCode = useThrottle(investmentAmountValues?.voucherCode);

  useEffect(() => {
    if (!selfDisclosureApiError) return;
    setSelfDisclosureCalculation(undefined);
    if (selfDisclosureApiError.errorCodes.includes('self_disclosure_investment_amount_exceeds_25k')) {
      setSelfDisclosureInvestmentAmountExceeds25k(true);
    }
  }, [selfDisclosureApiError]);

  useEffect(() => {
    setInvestmentSummaryNumbers({
      investmentTotal: shareCalculation?.investmentTotal || null,
      paymentTotal: shareCalculation?.paymentTotal || null,
      disagio: shareCalculation?.disagio || null,
      agio: shareCalculation?.agio || null,
      accruedInterest: shareCalculation?.accruedInterest || nullMoney,
      voucherName: voucher?.name || '',
    });
  }, [shareCalculation]);

  useEffect(() => {
    const invitationsApi: InvitationsApi = makeAuthenticatedApi(InvitationsApi);

    (async () => {
      if (!offerValuesNumberOfTokens) {
        setShareCalculation(undefined);
        setSelfDisclosureCalculation(undefined);
        return;
      }
      try {
        const calculation = await invitationsApi.invitationsCalculationsRetrieve({
          id: invitationId,
          //  TODO(mara-cashlink): replace parseFloat of Big
          // @ts-ignore
          requestedTokens: parseFloat(offerValuesNumberOfTokens),
          code: offerValuesVoucherCode,
        });
        setShareCalculation(calculation);
      } catch (e) {
        setShareCalculation(undefined);
      }
      if (selfDisclosureRequired) {
        withSelfDisclosureApi(async () => {
          const selfDisclosureCalculation = await selfDisclosureInvitationsApi.invitationsSelfDisclosureRetrieve({
            id: invitationId,
            //  TODO(mara-cashlink): replace parseFloat of Big
            // @ts-ignore
            requestedTokens: parseFloat(offerValuesNumberOfTokens),
          });
          setSelfDisclosureCalculation(selfDisclosureCalculation);
          setSelfDisclosureInvestmentAmountExceeds25k(false);
        });
      }
    })();
  }, [
    offerValuesNumberOfTokens,
    makeAuthenticatedApi,
    selfDisclosureInvitationsApi,
    withSelfDisclosureApi,
    invitationId,
    selfDisclosureRequired,
    offerValuesVoucherCode,
  ]);

  const invitationsApi: InvitationsApi = useMemo(() => makeAuthenticatedApi(InvitationsApi), [makeAuthenticatedApi]);
  const campaignsApi: CampaignsApi = useMemo(() => makeAuthenticatedApi(CampaignsApi), [makeAuthenticatedApi]);

  useEffect(() => {
    withApi(async () => {
      let remainingAmount = 0;
      if ((invitation as CampaignInvitation)?.campaignId) {
        const campaign = await campaignsApi.campaignsRetrieve({
          id: (invitation as CampaignInvitation)?.campaignId || '',
        });
        remainingAmount = campaign.allocationAvailable;
      } else {
        remainingAmount = (invitation as InvestmentInvitation).maxNumberOfTokens;
      }
      setRemainingAmount(remainingAmount);
    });
  }, [withApi, campaignsApi, (invitation as CampaignInvitation)?.id]);

  useEffect(() => {
    withVoucherApi(async ({ takeLatest }) => {
      setVoucher(undefined);
      if (!offerValuesVoucherCode || !offerValuesNumberOfTokens) {
        return;
      }
      const newVoucher = await voucherInvitationsApi.invitationsVoucherRetrieve({
        id: invitationId,
        code: offerValuesVoucherCode,
        //  TODO(mara-cashlink): replace parseFloat of Big
        // @ts-ignore
        requestedTokens: parseFloat(offerValuesNumberOfTokens),
      });
      takeLatest(() => {
        setVoucher(newVoucher);
      });
    });
  }, [offerValuesVoucherCode, offerValuesNumberOfTokens, voucherInvitationsApi, invitationId, withVoucherApi]);

  const onSubmit = useCallback(
    (values: InvestmentAmountFields) => {
      const nrOfTokens = values.tokenAmountInput?.nrOfTokens;
      // some sanity checks
      if (nrOfTokens === undefined) return;
      withApi(async () => {
        await invitationsApi.invitationsOfferConfigurationCreate({
          id: invitationId,
          invitationOfferConfigurationRequest: {
            //  TODO(mara-cashlink): replace parseFloat of Big
            // @ts-ignore
            numberOfTokens: parseFloat(nrOfTokens),
            ...(selfDisclosureRequired
              ? {
                  selfDisclosure: {
                    doubleIncome: values?.selfDisclosure?.doubleIncome,
                    liquidAssets: values?.selfDisclosure?.liquidAssets,
                    confirmedProfessionalInvestor: values?.selfDisclosure?.confirmedProfessionalInvestor,
                  },
                }
              : {}),
            voucher: voucher && toNumber(voucher.disagio) > 0 ? voucher.id : undefined,
          },
        });

        finalize();
      });
    },
    [invitationsApi, invitationId, withApi, selfDisclosureRequired, voucher, finalize],
  );

  const personType =
    (PersonToNaturalPerson(me?.person) && PersonType.Natural) ||
    (PersonToLegalPerson(me?.person) && PersonType.Legal) ||
    undefined;

  const investmentTotal = shareCalculation?.investmentTotal && MoneyValue.fromMoney(shareCalculation.investmentTotal);

  // todo(geforcefan): we should think about error handling
  //  for our whole investment process, ux team will think about a solution
  if (error || meError || !personType) return null;

  if (!investmentInvitation || !issuer || !calculation || remainingAmount === undefined || !token)
    return <LoadingRing />;

  // TODO(geforcefan): why is shareCalculation?.exitProceedsPercent still a string?
  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        <Translate name="investmentAmount.title" />
      </Header>
      <InvestmentAmountForm
        personType={personType}
        onSubmit={onSubmit}
        onChange={setInvestmentAmountValues}
        shareNumbers={{
          minNumberOfTokens: makeBigInt(calculation.minimumTokens),
          pricePerToken: MoneyValue.fromMoney(investmentInvitation.pricePerToken),
          maxNumberOfTokens: BigNumber.minimum(
            makeBigInt(investmentInvitation.maxNumberOfTokens),
            makeBigInt(remainingAmount),
          ),
          exitProceedsPercent: toNumber(shareCalculation?.exitProceedsPercent || '0'),
          investmentTotal: investmentTotal,
          stepSize: makeBigInt(investmentInvitation.stepSize),
        }}
        selfDisclosureRequirements={{
          minimumLiquidAssets: selfDisclosureCalculation?.minimumLiquidAssets,
          minimumDoubleIncome: selfDisclosureCalculation?.minimumDoubleIncome,
          investmentTotal: selfDisclosureCalculation?.investmentTotal,
          confirmationProfessionalRequired: selfDisclosureCalculation?.confirmationProfessionalRequired,
        }}
        loading={loading || apiLoading}
        error={apiError}
        voucherError={voucherApiError}
        selfDisclosureInvestmentAmountExceeds25k={selfDisclosureInvestmentAmountExceeds25k}
        customCommitmentText={token?.customCommitmentText}
        customCommitmentDocument={token?.customCommitmentDoc}
        useUnits={token.useUnits}
        unitSteps={token.unitSteps.split(',').map((unitStep: string) => makeBigInt(unitStep))}
        companyName={
          <PersonName
            person={{
              companyName: issuer?.person.companyName,
            }}
          />
        }
        voucher={voucher}
        showShareInfo={token.showShareInfo}
        initial={{
          numberOfTokens: initialNumberOfTokens,
          voucherCode: investmentInvitation.voucherCode || undefined,
          selfDisclosure: investmentInvitation.selfDisclosure || undefined,
        }}
      />
    </>
  );
};

export default InvestmentAmountStep;

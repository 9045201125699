import { Configuration } from 'api';
import { accessMiddleware, authMiddleware, errorMiddleware } from './middleware';
import type { BaseAPI } from 'api/runtime';
import type { RootAppState } from 'store/types';
import { applicationConfig } from 'core/config/local';

const baseConfig = {
  basePath: applicationConfig.apiBasePath || ' ',
};

export const makeApiUrl = (path: string): string => {
  return baseConfig.basePath + path;
};

export function makeApi<T extends BaseAPI>(Api: new (config: Configuration) => T, access?: string): T {
  const middlewares = [];

  if (access) middlewares.push(accessMiddleware(access));
  middlewares.push(errorMiddleware());

  const config = new Configuration({
    ...baseConfig,
    middleware: middlewares,
  });
  return new Api(config);
}

export function makeAuthenticatedApi<T extends BaseAPI, S extends RootAppState = RootAppState>(
  Api: new (config: Configuration) => T,
  getState: () => S,
): T {
  const config = new Configuration({
    ...baseConfig,
    middleware: [authMiddleware<S>(getState), errorMiddleware()],
  });
  return new Api(config);
}

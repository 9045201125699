// @ts-ignore
import { getAddress } from '@ethersproject/address';
import moment from 'moment';
import { all } from 'iso-3166-1';

export const validatePassword = (value: string) =>
  !!(value.length && value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z0-9\S]{8,}$/gi));

export const validateAlphanumericZIP = (value: string) => /^[a-z\d][a-z\d -]{1,99}$/i.test(value);

export const validateEthAddress = (value: string) => {
  return /^0x[0-9a-f]{40}$/i.test(value);
};

export const validateStellarAddress = (value: string) => {
  return ['G', 'g'].includes(value[0]) && value.length === 56;
};

export const validateEthChecksum = (value: string) => {
  if (!validateEthAddress(value)) return false;
  try {
    getAddress(value);
    return true;
  } catch (e) {
    return false;
  }
};

export const validatePhoneNumber = (value: string) => {
  return /^(00|\+)[0-9]+/i.test(value);
};

export const validateBic = (value: string) => {
  const bic = value.toUpperCase();
  if (bic.length !== 8 && bic.length !== 11) {
    return false;
  }
  if (!/^([A-Z]{4}[A-Z]{2}[A-Z0-9]{2}[A-Z0-9]{0,3})$/.test(bic)) return false;

  const country = all().find((countryObj) => countryObj['alpha2'] === value.slice(4, 6).toUpperCase());
  return !!country;
};

export const validateTaxId = (value: string) => {
  return value.trim().length === 11;
};

export const validateEmailAddress = (value: string) => {
  return !!/^[^@]+@[^@]+\..{2,}$/i.test(value);
};

export const validateEmailAddressLoosely = (value: string) => {
  return !!/^[^@]+[@|.|_|-]+.*$/i.test(value);
};

export const validateDate = (value: string, format: string) => {
  return moment(value, format, true).isValid();
};

export const validatePastDate = (date: Date, yearsInPast = 0) => {
  const dateInThePast = new Date();
  dateInThePast.setFullYear(dateInThePast.getFullYear() - yearsInPast);
  return date <= dateInThePast;
};

export const validateCurrentDate = (value: string, format: string) => {
  return moment(value, format, true).format(format) === moment().format(format);
};

export const isValidDependingOnTouched = (isValid: boolean, touched?: boolean) =>
  isValid || (isValid === false && touched ? false : undefined);

export const hasErrorDependingOnTouched = (isValid: boolean, touched?: boolean) =>
  isValidDependingOnTouched(isValid, touched) === false;

export default {
  validatePassword,
  validateAlphanumericZIP,
  validateEthAddress,
};

import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { StateValues } from 'react-use-form-state';
import { AdminApi, AdminProductDetail, AdminTokenOverview, DocumentsApi, PatchedAdminProductDetailRequest } from 'api';
import useApiCall from 'hooks/use-api-call';
import Translate from 'ui/atoms/translate';
import { ActionButton } from 'ui/molecules/action-buttons';
import { toMoney } from 'ui/helper/money';
import AddProductModal, { AddProductFields } from 'apps/issuer/pages/products/add-product-modal';

interface AddProductProps {
  onClose: () => void;
  data: AdminTokenOverview & AdminProductDetail;
  onEditProductSuccess: () => void;
}

const EditProduct: FunctionComponent<AddProductProps> = ({ onClose, data, onEditProductSuccess }) => {
  const { makeAuthenticatedApi, withApi, loading: apiLoading, error } = useApiCall();

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);
  const documentsApi: DocumentsApi = useMemo(() => makeAuthenticatedApi(DocumentsApi), [makeAuthenticatedApi]);

  const modalHeader = useMemo(() => {
    return <Translate name="addProduct.edit" />;
  }, []);

  const actionButtons = useMemo((): ActionButton[] => {
    return [
      {
        name: 'cancel',
        content: <Translate name="common.close" />,
        size: 'large',
        onClick: () => onClose(),
      },
      {
        name: 'send',
        content: <Translate name="addProduct.submit" />,
        variant: 'primary',
        loading: apiLoading,
        type: 'submit',
        size: 'large',
      },
    ];
  }, [apiLoading]);

  const onSubmit = useCallback(
    (fieldsValues: StateValues<AddProductFields>, dateAndTime?: Date) => {
      withApi(async () => {
        const params: PatchedAdminProductDetailRequest = {
          ...fieldsValues,
          issuanceDocumentId: '',
          maxIssuableAmount: Number(fieldsValues.maxIssuableAmount),
          stepSize: Number(fieldsValues.stepSize),
          paymentDeadline: Number(fieldsValues.paymentDeadline),
          cancellationDeadline: Number(fieldsValues.cancellationDeadline),
          calculationFactor: fieldsValues.calculationFactor && fieldsValues.calculationFactor.toString(),
          nominalAmount:
            typeof fieldsValues.nominalAmount === 'number' || typeof fieldsValues.nominalAmount === 'string'
              ? toMoney(Number(fieldsValues.nominalAmount))
              : fieldsValues.nominalAmount,
          ewpg: fieldsValues.ewpg === 'true',
        };

        if (dateAndTime) {
          params.lockInTime = dateAndTime;
        }
        if (fieldsValues.isin.length === 0) {
          params.isin = null;
        }
        if (fieldsValues.ewpg === 'true') {
          delete params.backupWallet;
        }
        if (fieldsValues.issuanceDocument) {
          const docResponse = await documentsApi.adminDocumentsCreate({
            file: fieldsValues.issuanceDocument,
          });
          params.issuanceDocumentId = docResponse.id;
        }
        if (!fieldsValues.issuanceDocument && data.issuanceDocument?.document) {
          params.issuanceDocumentId = data.issuanceDocument.document.id;
        }

        await api.adminTokensPartialUpdate({ id: data.id, patchedAdminProductDetailRequest: params });
        onEditProductSuccess();
      });
    },
    [withApi, api],
  );

  return (
    <AddProductModal
      isInfoView={false}
      isExitingView={false}
      onSubmit={onSubmit}
      onClose={onClose}
      modalHeader={modalHeader}
      actionButtons={actionButtons}
      error={error}
      data={data}
    />
  );
};

export default EditProduct;
